import * as React from "react";

import {
  StyledEquipmentCard,
  StyledEquipmentCardHeader,
  StyleDatText,
  StyledEquipmentCardContainer,
  StyledSonarFormControl,
  SonarSeperator,
  StyledMenuItem,
} from "../rateAdjustmentStyles";

import { Select } from "@material-ui/core";
import { useRateAdjustmentQueryParams } from "../../../hooks/useQueryParams";

interface Props {
  trailerType: "van" | "reefer";
}

const items = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
];
const historyItems = ["LOWER", "HIGHER"];

const EquipmentHistory: React.FC<React.PropsWithChildren<Props>> = ({ trailerType }) => {
  const { addQueryParams, queryParams } = useRateAdjustmentQueryParams();

  const numberOfLoadsKey = `${trailerType}NumberOfLoads` as
    | "vanNumberOfLoads"
    | "reeferNumberOfLoads";
  const rtsRateKey = `${trailerType}RtsRate` as
    | "reeferNumberOfLoads"
    | "reeferRtsRate";

  const numberOfLoads = queryParams[numberOfLoadsKey]
    ? queryParams[numberOfLoadsKey]
    : "";
  const rtsRate = queryParams[rtsRateKey] ? queryParams[rtsRateKey] : "";

  const getOptions = () => {
    return items.map((e) => <StyledMenuItem value={e}>{e}</StyledMenuItem>);
  };

  const getHistoryOptions = () => {
    return historyItems.map((e) => (
      <StyledMenuItem value={e}>{e}</StyledMenuItem>
    ));
  };

  const onChangeNumberOfLoads = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const param: any = {};
    param[numberOfLoadsKey] = event.target.value as string;
    addQueryParams(param);
  };

  const onChangeRate = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const param: any = {};
    param[rtsRateKey] = event.target.value as string;
    addQueryParams(param);
  };

  return (
    <StyledEquipmentCard width="360px" height="178px">
      <StyledEquipmentCardHeader>
        <StyleDatText marginLeft="16px" fontWeight="400" marginTop="0px">
          RTS HISTORY OVERRIDE
        </StyleDatText>
      </StyledEquipmentCardHeader>
      <StyledEquipmentCardContainer flexDirection="row">
        <StyleDatText marginLeft="16px" fontWeight="400" marginTop="20px">
          Number <br /> of loads
        </StyleDatText>
        <StyledSonarFormControl size="small">
          <Select
            id="sonar-simple-select"
            MenuProps={{ variant: "menu" }}
            onChange={onChangeNumberOfLoads}
            value={numberOfLoads}
            disableUnderline
          >
            {getOptions()}
          </Select>
          <SonarSeperator width="90px" />
        </StyledSonarFormControl>
        <StyleDatText marginLeft="16px" fontWeight="400" marginTop="25px">
          {`& RTS rate`}
        </StyleDatText>
        <StyledSonarFormControl size="small">
          <Select
            id="sonar-simple-select"
            MenuProps={{ variant: "menu" }}
            onChange={onChangeRate}
            value={rtsRate}
            disableUnderline
          >
            {getHistoryOptions()}
          </Select>
          <SonarSeperator width="90px" />
        </StyledSonarFormControl>
      </StyledEquipmentCardContainer>
    </StyledEquipmentCard>
  );
};

export default EquipmentHistory;
