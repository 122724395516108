import * as React from 'react';
import { Flex } from 'components/Flex';
import { styled } from 'shamrock-clover-ui';
import RequestTypeRadioButtons from './RequestTypeRadioButtons';
import CustomerNameInput from './CustomerNameInput';
import CustomerIdInput from './CustomerIdInput';
import GetCreditRequestButton from './GetCreditRequestButton';

const ToolbarContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  min-height: 55px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 16px;
  gap: 16px;
`;

const Divider = styled.div`
  height: 36px;
  background-color: #bbbbbb;
  width: 1px;
  margin-top: 19px;
`;

type Props = {
  onSearch: () => void;
};

const Toolbar = ({ onSearch }: Props) => {
  return (
    <ToolbarContainer flexWrap="wrap">
      <RequestTypeRadioButtons />

      <Divider />
      <CustomerNameInput onSearch={onSearch} />
      <CustomerIdInput onSearch={onSearch} />
      <Flex margin="19px 0px 0px 0px">
        <GetCreditRequestButton onClick={onSearch} />
      </Flex>
    </ToolbarContainer>
  );
};

export default Toolbar;
