// styles
import { styled } from 'shamrock-clover-ui';

interface TableRowContainerProps {
  height?: number;
  onClick?: () => void;
}

interface TableRowCellProps {
  columnIndex?: number;
  width?: number;
  alignment?: 'left' | 'right' | 'center';
  color?: string;
  sortedColumnIndex?: number;
  isSortAnimating?: boolean;
}

export const StyledTableRowContainer = styled.tr<TableRowContainerProps>`
  border: 1px solid ${(props) => props.theme.colors.gray[30]};
  background-color: ${(props) => props.theme.white};
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  ${(props) =>
    props.onClick &&
    `:hover {
      cursor: pointer;
      background-color: ${props.theme.colors.gray[10]};
  }`}
`;

export const StyledTableRowCell = styled.td<TableRowCellProps>`
  text-align: ${(props) => props.alignment || 'left'};
  width: ${(props) => (props.width ? `${props.width}%` : 'auto')};
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  padding: 12px 16px;
  ${(props) => props.alignment === 'right' && `padding-right: 28px;`}
  &:first-child {
    padding-left: 24px;
  }
  ${(props) =>
    props.columnIndex === props.sortedColumnIndex &&
    props.isSortAnimating &&
    `animation: fade 2900ms linear;
  @keyframes fade {
  0%,100% { background-color: 'transparent' }
  5%, 90% { background-color: ${props.theme.colors.blue[10]} }`}
}
`;
