import React from 'react';
import { Flex } from '../../../components/Flex';
import { useCreditRequestQueryParams } from '../../../hooks/useQueryParams';
import { SmrRadioButton, styled } from 'shamrock-clover-ui';

const RadioButtonContainer = styled.div`
  margin-top: 9px;
`;

const RequestTypeRadioButtons = () => {
  const { addQueryParams } = useCreditRequestQueryParams();

  const {
    queryParams: { requestType },
  } = useCreditRequestQueryParams();

  const [checkedType, setCheckedType] = React.useState<
    'new' | 'adjustment' | undefined
  >(requestType ? requestType : undefined);

  const onChange = (value: string | null | undefined) => {
    if (value === 'new' || value === 'adjustment') {
      setCheckedType(value);

      addQueryParams({
        requestType: value,
      });
    }
  };

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <RadioButtonContainer>
        <SmrRadioButton
          elements={[
            {
              label: 'New request',
              value: 'new',
              checked: checkedType === 'new',
            },
            {
              label: 'Adjustment',
              value: 'adjustment',
              checked: checkedType === 'adjustment',
            },
          ]}
          id="smr-radio-button-default"
          onChange={onChange}
        />
      </RadioButtonContainer>
    </Flex>
  );
};

export default RequestTypeRadioButtons;
