// styles
import { styled } from 'shamrock-clover-ui';
import { SortOrder } from './TableTypes';

interface TableHeaderContainerProps {
  isStatic?: boolean;
}

interface TableHeaderColumnProps {
  width?: number;
}

interface TableHeaderColumnLabelProps {
  columnIndex?: number;
  isSortable?: boolean;
  sortedColumnIndex?: number;
  alignment?: 'left' | 'right' | 'center';
  isStatic?: boolean;
}

interface TableHeaderColumnLabelIconProps {
  sortDirection?: SortOrder;
  isActive: boolean;
}

export const StyledTableHeaderContainer = styled.tr<TableHeaderContainerProps>`
  border: 1px solid ${(props) => props.theme.colors.gray[30]};
  background-color: ${(props) =>
    props.isStatic ? props.theme.colors.gray[20] : props.theme.white};
  height: 22px;
`;

export const StyledTableHeaderColumn = styled.th<TableHeaderColumnProps>`
  width: ${(props) => (props.width ? `${props.width}%` : 'auto')};
  padding: 12px 6px;
  &:first-child {
    padding-left: 16px;
    padding-right: 0px;
  }
`;

export const StyledTableHeaderColumnLabel = styled.span<TableHeaderColumnLabelProps>`
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 12px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  float: ${(props) => props.alignment};
  color: ${(props) =>
    props.columnIndex === props.sortedColumnIndex
      ? props.theme.colors.blue[50]
      : props.isStatic
      ? props.theme.colors.gray[70]
      : props.theme.colors.gray[90]};
  background-color: ${(props) =>
    props.columnIndex === props.sortedColumnIndex
      ? props.theme.colors.blue[10]
      : 'transparent'};
  padding: 6px 9px;
  ${(props) =>
    props.alignment === 'right' && !props.isSortable && `padding-right: 22px;`}
  user-select: none;
  width: fit-content;
  transition: 100ms linear;
  & > div {
    opacity: ${(props) =>
      props.columnIndex === props.sortedColumnIndex ? 1 : 0};
  }
  ${(props) => props.isSortable && `cursor: pointer;`}
  ${(props) =>
    props.isSortable &&
    props.columnIndex !== props.sortedColumnIndex &&
    `:hover {
    background-color: ${props.theme.colors.gray[20]};
    & > div {
      opacity: 1;
    }
  }`}
`;

export const StyledTableHeaderColumnLabelIcon = styled.div<TableHeaderColumnLabelIconProps>`
  margin-left: 4px;
  transition: 100ms linear;
  & > div {
    transition: 100ms linear;
    transform: rotate(
      ${(props) =>
        props.sortDirection === 'asc' && props.isActive ? '180' : '0'}deg
    );
    cursor: pointer;
  }
`;
