import { getUserEmail } from './authUtils';
import { FEATURE_SERVICE_URL } from './constants';
import { getToken } from './authUtils';

export interface FreightAdminFeatures {
  isRtsMilesServiceFallbackEnabled: boolean;
  isSellRateEnabled: boolean;
  isRateAdjustmentEnabled: boolean;
  isCapacityEnabled: boolean;
}

export const getFeature = async (feature: string) => {
  const isActive = await fetch(
    `${FEATURE_SERVICE_URL}${feature}/${getUserEmail()}`,
  )
    .then((res) => res.json())
    .then((data) => {
      return data.status === 'always-active';
    });
  return isActive;
};

export const getAllFeatures = async (): Promise<FreightAdminFeatures> => {
  const result = await fetch(
    `${FEATURE_SERVICE_URL}active?email=${getUserEmail()}`,
  );
  const json = await result.json();
  const isRtsMilesServiceFallbackEnabled = json.includes('RTD-3419');
  const isSellRateEnabled = json.includes('RTD-3420');
  const isRateAdjustmentEnabled = json.includes('RTD-3420');
  const isCapacityEnabled = json.includes('RTD-3670');
  return {
    isRtsMilesServiceFallbackEnabled,
    isSellRateEnabled,
    isRateAdjustmentEnabled,
    isCapacityEnabled
  };
};

export const updateFeatureFlag = async (
  featureFlag: string,
  status: string,
) => {
  const feature = await fetch(
    `${FEATURE_SERVICE_URL}${featureFlag}/${getUserEmail()}`,
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  const newFeature = Object.assign({}, feature);

  newFeature.status = status;
  newFeature.editedBy = getUserEmail();

  const response = await fetch(`${FEATURE_SERVICE_URL}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(newFeature),
  });

  return response.json();
};
