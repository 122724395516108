import Icon from 'components/Icon';
import { styled } from 'shamrock-clover-ui';

export const HeaderContainer = styled('div')<{ flag: string }>`
  padding: 5px 8px;
  // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  width: 97.3%;
  position: relative;
  background: #ffffff;
`;

export const CustomPointer = styled.span<{ color?: string; type?: string }>`
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  // background: #0091ea;
  background: ${(props: { color?: string }) =>
    props.color ? props.color : '#0091ea'};
  top: 15px;
  border-radius: 50%;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  left: 10px;
`;

export const AssignedText = styled.span`
  margin-left: 19px;
  font-size: 14px;
  color: #333333;
`;

export const LabelContainer = styled.div`
  padding: 5px 10px;
  background: #eeeeee;
  border-radius: 10px;
  margin-right: 5px;
`;

export const RemoveButtonStyled = styled.span`
  color: #ef3824;
  padding-top: 7px;
  font-size: 16px;
  line-height: 22.49px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
