import { Flex } from 'components/Flex';
import React, { useState, createContext, ReactNode } from 'react';
import { Dialog, Button } from 'shamrock-clover-ui';

interface AccDialogProps {
  children: ReactNode;
}

const AccDialogContext = createContext<any | undefined>(undefined);

export const AccessorialDialogProvider: React.FC<
  React.PropsWithChildren<AccDialogProps>
> = ({ children }) => {
  const [formHasChange, setFormHasChange] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [discardClicked, setDiscardClicked] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [urlDestination, setUrlDestination] = useState('');

  const resetDialogHook = () => {
    setFormHasChange(false);
    setShowDialog(false);
    setDiscardClicked(false);
    setResetForm(false);
    setUrlDestination('');
  };

  const formDialog = () => {
    return (
      <Dialog
        open={showDialog}
        title="There are unsaved changes."
        content={
          <p>
            Are you sure you want to leave this page? <br /> The accessorial
            fees will not be updated.
          </p>
        }
        width="600px"
        closeOnBackdropClick={false}
        actions={
          <Flex justifyContent="space-between" width="100%">
            <Button
              width="175px"
              buttonStyle="outlined"
              onClick={() => setShowDialog(false)}
            >
              Continue editing
            </Button>
            <Button
              data-testid="accept-quote"
              width="175px"
              onClick={() => setDiscardClicked(true)}
            >
              Discard Changes
            </Button>
          </Flex>
        }
      />
    );
  };

  return (
    <AccDialogContext.Provider
      value={{
        formHasChange,
        setFormHasChange,
        showDialog,
        setShowDialog,
        discardClicked,
        setDiscardClicked,
        resetForm,
        setResetForm,
        urlDestination,
        setUrlDestination,
        resetDialogHook,
      }}
    >
      {formDialog()}
      {children}
    </AccDialogContext.Provider>
  );
};

export const useAccDialogContext = () => {
  const context = React.useContext(AccDialogContext);
  if (context === undefined) {
    throw new Error(
      'useAccDialogContext must be used within an AccessorialDialogProvider',
    );
  }
  return context;
};
