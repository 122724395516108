import { FC } from 'react';
import { useLocation } from 'react-router';
import { FlexItem } from 'components/Flex';
import * as DrawerStyles from '../styledComponents/QutotinDrawerStyles';
import {
  QuoteHistoryItem,
  OffersProps,
  HistoryOffers,
  OffersHistory,
} from '../utils/quoteTypes';
import { LocalShipping } from '@material-ui/icons';
import { styled } from 'shamrock-clover-ui';
import {
  currentAndPreviousOffer,
  reverseHistory,
  getDeclineBy,
} from '../utils/quoteUtils';
import { format, parseISO } from 'date-fns';
import useTimer from '../hooks/useTimer';
import { useBookedLoadsAlertFeatureFlag } from 'hooks/useFeatureFlag';

//TODO: clean this component. Place all the functions, style components and types in their respective files.

const OfferAmount = styled('span')<{ previousOffer?: boolean }>`
  text-decoration: ${(props: { previousOffer?: boolean }) =>
    props.previousOffer ? 'line-through' : 'none'};
  font-weight: ${(props: { previousOffer?: boolean }) =>
    props.previousOffer ? 'initial' : 'bold'};
  color: ${(props: { previousOffer?: boolean }) =>
    props.previousOffer ? '#888888' : 'initial'};
`;

const OfferContainer = styled('div')<{ offerType?: string }>`
  display: ${(props: { offerType?: string }) =>
    props.offerType &&
    (props.offerType === 'countered' || props.offerType === 'history')
      ? 'grid'
      : 'unset'};
  grid-template-columns: 35px 1fr;
`;

const HistoryOfferContainer = styled.div<{ order?: number }>`
  margin-bottom: 25px;
  order: ${({ order }) => (order ? order : 'unset')};
`;

const findDeniedHistory = (
  historyReverseOffer: QuoteHistoryItem[],
): QuoteHistoryItem | undefined => {
  // History items are in reverse-chronological order,
  // therefore we want the last history item in the array with a denied status
  let deniedOffer;
  historyReverseOffer.forEach((offer) => {
    if (offer.status === 'denied') {
      deniedOffer = offer;
    }
  });

  return deniedOffer;
};

export const getMargin = (
  total: number,
  currentValue: number,
  history?: OffersHistory,
): string => {
  const currentOffer = history && history.currentOffer;

  const prefixText = currentOffer?.some((x) => x.status === 'countered')
    ? 'Counter,'
    : '';

  const _total = `${prefixText} ${(
    ((total - currentValue) / total) *
    100
  ).toFixed(0)}% Margin`;
  return _total;
};

const HistoryOffersEnhancements: FC<React.PropsWithChildren<HistoryOffers>> = ({
  previousOffer,
  currentOffer,
  isCarrier,
  offerType,
  text,
  createdAt,
}) => {
  return (
    <OfferContainer offerType={offerType}>
      {isCarrier ? <LocalShipping style={{ color: '#0191EA' }} /> : <div></div>}
      <div className="OfferAmounts" style={{}}>
        {previousOffer ? (
          <>
            <OfferAmount previousOffer={true}>
              ${previousOffer.toLocaleString()}
              {text ? ' ' + text : null}
            </OfferAmount>{' '}
            {!text && <> &#x3e; </>}
          </>
        ) : null}
        {currentOffer ? (
          <>
            <OfferAmount>
              ${currentOffer.toLocaleString()} {text ? ' ' + text : null}{' '}
            </OfferAmount>
            {createdAt && ` - ${format(parseISO(createdAt), 'HH:mm')}`}
          </>
        ) : null}
      </div>
    </OfferContainer>
  );
};

const PreviouslyOfferEnhancements: FC<React.PropsWithChildren<OffersProps>> = (
  data: OffersProps,
) => {
  const { history } = data.quoteData;
  const { totalCharge } = data;

  const { isBookedLoadsAlertEnabled } = useBookedLoadsAlertFeatureFlag();

  const { pathname } = useLocation();
  const path = pathname.split('/')[2];
  const type = !path ? 'new' : path;

  const { expired } = useTimer(history, isBookedLoadsAlertEnabled ? 10 : 15);

  const reverse = reverseHistory(history);

  const historyOffer = currentAndPreviousOffer(reverse);

  const deniedCurrentOffer = findDeniedHistory(historyOffer.currentOffer);
  const deniedPreviouslyOffer = findDeniedHistory(historyOffer.previouslyOffer);
  const acceptedQuotes = historyOffer.currentOffer.filter(
    (x) => x.status === 'approved',
  );

  const binOffer = historyOffer.currentOffer.filter(
    (x) => x.status === 'approved' && !x.isInternalUser,
  );

  return (
    <>
      <DrawerStyles.Line />
      <FlexItem flex={2} flexDirection={'column'} alignContent={'flex-start'}>
        <DrawerStyles.QuoteDisplayContainer>
          {historyOffer.previouslyOffer.length !== 0 ? (
            <DrawerStyles.OfferedTitle>
              PREVIOUSLY OFFERED
            </DrawerStyles.OfferedTitle>
          ) : null}
          {historyOffer.currentOffer.some(
            (x: QuoteHistoryItem) => x.status === 'completed',
          ) ||
          historyOffer.currentOffer.some(
            (x: QuoteHistoryItem) => x.status === 'approved',
          ) ? (
            <HistoryOfferContainer order={type !== 'dismissed' ? 1 : 2}>
              <DrawerStyles.OfferedHistoryTitle color="#95CF73">
                Accepted
              </DrawerStyles.OfferedHistoryTitle>
              {historyOffer.currentOffer.map((x: QuoteHistoryItem, i) => (
                <>
                  {x.status === 'completed' ? (
                    <>
                      <HistoryOffersEnhancements
                        currentOffer={x.amount}
                        offerType="accepted"
                        text={getMargin(totalCharge, x.amount, historyOffer)}
                        key={
                          new Date(reverse[i].createdAt).getDate() *
                          Math.random()
                        }
                      />
                      <HistoryOffersEnhancements
                        previousOffer={
                          historyOffer.currentOffer[
                            historyOffer.currentOffer.length - 1
                          ].amount
                        }
                        offerType="accepted"
                        text="Offer"
                        key={
                          new Date(reverse[i].createdAt).getDate() *
                          Math.random()
                        }
                      />
                    </>
                  ) : null}
                </>
              ))}
            </HistoryOfferContainer>
          ) : historyOffer.currentOffer.some(
              (x: QuoteHistoryItem) => x.status === 'approved',
            ) ? (
            <HistoryOfferContainer order={type !== 'dismissed' ? 1 : 2}>
              <DrawerStyles.OfferedHistoryTitle color="#95CF73">
                Accepted
              </DrawerStyles.OfferedHistoryTitle>
              {acceptedQuotes.length > 0 ? (
                <>
                  <HistoryOffersEnhancements
                    currentOffer={acceptedQuotes[0].amount}
                    offerType="accepted"
                    text={getMargin(
                      totalCharge,
                      acceptedQuotes[0].amount,
                      historyOffer,
                    )}
                  />
                  <HistoryOffersEnhancements
                    previousOffer={
                      historyOffer.currentOffer[
                        historyOffer.currentOffer.length - 1
                      ].amount
                    }
                    offerType="accepted"
                    text="Offer"
                  />
                </>
              ) : null}
            </HistoryOfferContainer>
          ) : null}
          {deniedCurrentOffer || deniedPreviouslyOffer ? (
            <HistoryOfferContainer order={1}>
              <DrawerStyles.OfferedHistoryTitle color="#EF3725">
                {deniedCurrentOffer
                  ? getDeclineBy(deniedCurrentOffer)
                  : deniedPreviouslyOffer
                  ? getDeclineBy(deniedPreviouslyOffer)
                  : null}
              </DrawerStyles.OfferedHistoryTitle>
              <HistoryOffersEnhancements
                currentOffer={
                  deniedCurrentOffer?.amount || deniedPreviouslyOffer?.amount
                }
                offerType="dismissed"
              />
            </HistoryOfferContainer>
          ) : null}
          {historyOffer.currentOffer.some(
            (x: QuoteHistoryItem) => x.status === 'countered',
          ) ? (
            <>
              <HistoryOfferContainer
                className="countered"
                order={type === 'negotiating' ? 1 : 3}
              >
                <DrawerStyles.OfferedHistoryTitle>
                  Counteroffer
                </DrawerStyles.OfferedHistoryTitle>
                {historyOffer.currentOffer.map((x: QuoteHistoryItem, i) => (
                  <>
                    {x.status === 'countered' ? (
                      <HistoryOffersEnhancements
                        previousOffer={reverse[i + 1].amount}
                        currentOffer={reverse[i].amount}
                        isCarrier={!x.isInternalUser ? true : false}
                        offerType="countered"
                        createdAt={reverse[i].createdAt}
                        key={
                          new Date(reverse[i].createdAt).getDate() *
                          Math.random()
                        }
                      />
                    ) : null}
                  </>
                ))}
              </HistoryOfferContainer>
            </>
          ) : null}

          {isBookedLoadsAlertEnabled &&
          reverse[0].isInternalUser &&
          reverse[0].status === 'approved' ? (
            <></>
          ) : expired &&
            reverse[0].isInternalUser &&
            reverse[0].status !== 'denied' &&
            reverse[0].status !== 'completed' ? (
            <HistoryOfferContainer order={3}>
              <DrawerStyles.OfferedHistoryTitle color="#EF3725">
                Expired by Carrier
              </DrawerStyles.OfferedHistoryTitle>
            </HistoryOfferContainer>
          ) : null}
          <HistoryOfferContainer order={3}>
            <DrawerStyles.OfferedHistoryTitle>
              New Carrier Offer
            </DrawerStyles.OfferedHistoryTitle>
            <HistoryOffersEnhancements
              currentOffer={
                binOffer.length > 0
                  ? binOffer[0].amount
                  : historyOffer.currentOffer[
                      historyOffer.currentOffer.length - 1
                    ].amount
              }
            />
          </HistoryOfferContainer>
          {historyOffer.previouslyOffer.length !== 0 ? (
            <HistoryOfferContainer order={5}>
              <DrawerStyles.OfferedHistoryTitle>
                History
              </DrawerStyles.OfferedHistoryTitle>
              {historyOffer.previouslyOffer.map((x: QuoteHistoryItem, i) => (
                <>
                  {x.status === 'pending' || x.status === 'approved' ? (
                    <HistoryOffersEnhancements
                      currentOffer={x.amount}
                      offerType="history"
                      text={' - Initial Offer'}
                      createdAt={x.createdAt}
                      key={new Date(x.createdAt).getDate() * Math.random()}
                    />
                  ) : (
                    <HistoryOffersEnhancements
                      previousOffer={historyOffer.previouslyOffer[i + 1].amount}
                      currentOffer={historyOffer.previouslyOffer[i].amount}
                      isCarrier={!x.isInternalUser ? true : false}
                      offerType="history"
                      createdAt={x.createdAt}
                      key={new Date(x.createdAt).getDate() * Math.random()}
                    />
                  )}
                </>
              ))}
            </HistoryOfferContainer>
          ) : null}
        </DrawerStyles.QuoteDisplayContainer>
      </FlexItem>
    </>
  );
};

export default PreviouslyOfferEnhancements;
