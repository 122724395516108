import { Flex } from '../../../components/Flex';
import { styled, FilterInput } from 'shamrock-clover-ui';
import React from 'react';
import { LabelText } from '../rateProStyles';

const InputContainer = styled.div`
  height: 36px;
`;

const StyledInput = styled(FilterInput)`
  border: none;
  font-family: Proxima Nova;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  color: #333333;
  height: 36px;
  -moz-appearance: textfield;

  ::placeholder {
    color: #333333;
  }

  :disabled {
    background: #ffffff;
  }

  :before {
    content: '$';
    padding-right: 10px;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

interface Props {
  type: 'SuggestedBuy' | 'YourCustomer';
  rate: number | null;
  setRate: React.Dispatch<React.SetStateAction<number | null>>;
}

const RateInput = ({ type, rate, setRate }: Props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRate(parseFloat(parseFloat(event.target.value).toFixed(2)));
  };

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <LabelText>
        {type === 'SuggestedBuy' ? 'Suggested Buy Rate' : 'Customer Rate'}
      </LabelText>
      <InputContainer>
        <StyledInput
          placeholder="Enter Price"
          height="30px"
          type="number"
          step="0.01"
          value={rate ? rate.toString() : ''}
          onChange={onChange}
          clearSearch={() => setRate(null)}
        />
      </InputContainer>
    </Flex>
  );
};

export default RateInput;
