import { FC, useContext, useState } from 'react';
import { Button, Dialog, styled } from 'shamrock-clover-ui';
import { Flex } from 'components/Flex';
import { QuoteHistoryItem, ButtonsInputsProps } from '../utils/quoteTypes';
import { getRelevantHistoryItem, getTimerDisplay } from '../utils/quoteUtils';
import * as FirebaseUtils from '../../../utils/firebaseUtils';
import { useQuotingQueryParams } from 'hooks/useQueryParams';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import useCurrentLocation from '../hooks/useCurrentLocation';

export const DialogContainer = styled.div``;

export const isCounteredByAdmin = (history: QuoteHistoryItem[]) => {
  const lastHistoryItem = history[history.length - 1];
  return (
    lastHistoryItem.isInternalUser && lastHistoryItem.status === 'countered'
  );
};

const QuotingAcceptButton: FC<React.PropsWithChildren<ButtonsInputsProps>> = ({
  userId,
  orderId,
  carrierName,
  mcleodId,
  history,
}) => {
  const { verifyQuote, getStatusFromQuoteContext } = useContext(
    QuotesEnhancementsContext,
  );

  const { location } = useCurrentLocation();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { setPathAndQuery } = useQuotingQueryParams();

  const handleOnClick = () => {
    if (history.length > 0) {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.ACCEPT,
        FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
        FirebaseUtils.FirebasePages.DETAILS,
        {
          carrierName,
          mcleodId,
          orderNumber: orderId,
          status: getStatusFromQuoteContext(orderId),
          quoteAmount: getRelevantHistoryItem(history)?.amount,
          timer: getTimerDisplay(history),
          description: 'Accept Quote',
        },
      );
      verifyQuote(orderId, userId);
      setPathAndQuery(location);
    }
    setIsDialogOpen(false);
  };

  return history.length > 0 ? (
    <>
      <Button
        data-testid="open-dialog"
        disabled={isCounteredByAdmin(history)}
        onClick={() => setIsDialogOpen(true)}
      >
        Accept
      </Button>
      {isDialogOpen && (
        <DialogContainer>
          <Dialog
            open={isDialogOpen}
            title="Confirm Offer Acceptance"
            content={`You are accepting $${getRelevantHistoryItem(
              history,
            )?.amount.toLocaleString()} for this load.`}
            onClose={() => setIsDialogOpen(false)}
            width="472px"
            actions={
              <Flex justifyContent="space-between">
                <Button
                  width="175px"
                  buttonStyle="outlined"
                  onClick={() => setIsDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="accept-quote"
                  width="175px"
                  onClick={handleOnClick}
                >
                  Accept
                </Button>
              </Flex>
            }
          />
        </DialogContainer>
      )}
    </>
  ) : null;
};

export default QuotingAcceptButton;
