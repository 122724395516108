import { Button, styled } from 'shamrock-clover-ui';
import { useCreditRequestQueryParams } from '../../../hooks/useQueryParams';

const StyledButton = styled(Button)`
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-family: Proxima Nova;
  height: 36px;
`;

type Props = {
  onClick: () => void;
};

const GetCreditRequestButton = ({ onClick }: Props) => {
  const {
    queryParams: { requestType, customerName, customerId },
  } = useCreditRequestQueryParams();

  return (
    <StyledButton
      buttonStyle="outlined"
      isRounded={true}
      disabled={
        !(
          requestType &&
          (customerName ||
            (customerId &&
              (customerId.length === 7 || customerId.length === 8)))
        )
      }
      onClick={onClick}
    >
      Search
    </StyledButton>
  );
};

export default GetCreditRequestButton;
