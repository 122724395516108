import { FC, useContext } from 'react';
import {
  Button,
  CommentBox,
  Icon,
  MultiSelectInput,
  SelectInput,
} from 'shamrock-clover-ui';
import { ICapacityForm } from '../CapacityTypes';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import { frequencyOptions, rateTypeOptions } from '../utils/capacityUtils';
import {
  AddLaneAndCapacityContent,
  CapacityText,
  InputsGroup,
  InputsTitles,
  OptionalText,
  SelectInputContainer,
  TextInputStyled,
} from './Drawer/DrawerStyles';

export const CapacityForm: FC<ICapacityForm> = ({
  onClick,
  disabled,
  isEdited,
}) => {
  return (
    <div style={{ paddingLeft: '16px' }}>
      <p style={{ fontWeight: 400, fontSize: '16px' }}>
        Add additional details about this carrier’s lane, such as trucks
        quantity, frequency, rate and load details.
      </p>
      <Button
        icon={
          <Icon
            icon="plus"
            color={disabled && !isEdited ? 'gray3' : 'blue'}
            size="16"
          />
        }
        buttonStyle="outlined"
        isRounded={true}
        onClick={onClick}
        disabled={disabled && !isEdited}
      >
        Add Capacity
      </Button>
    </div>
  );
};

export const AddCapacityForm = () => {
  const {
    laneAndCapacityForm,
    isEdited,
    rateAmountRef,
    inputsError,
    handleCapacityInputsOnChange,
    handleCapacityInputsOnBlur,
    handleCapacityOptionsSelected,
    handleAdditionalServices,
    handleRateAmount,
    handleComments,
  } = useContext(CarrierCapacityContext);

  return (
    <div style={{ padding: '12px 16px 16px 16px' }}>
      <AddLaneAndCapacityContent marginTop="0px" separator={true}>
        <InputsTitles>Capacity</InputsTitles>
        <CapacityText>
          How many trucks can the carrier provide for this route?
        </CapacityText>
        <InputsGroup>
          <TextInputStyled
            variant="filled"
            placeHolder="000"
            size={337}
            label="Truck Quantity"
            value={
              laneAndCapacityForm.truckQuantity?.optionSelected?.optionName ||
              ''
            }
            onChange={handleCapacityInputsOnChange}
            onBlur={handleCapacityInputsOnBlur}
            error={
              inputsError.truckQuantity &&
              laneAndCapacityForm.truckQuantity?.optionSelected?.optionName ===
                ''
                ? 'Required'
                : undefined
            }
            name="truckQuantity"
          />
        </InputsGroup>
      </AddLaneAndCapacityContent>
      <AddLaneAndCapacityContent separator={true}>
        <InputsTitles>Frequency</InputsTitles>
        <CapacityText>
          How often would the carrier haul this route?
        </CapacityText>
        <InputsGroup gap={12}>
          <TextInputStyled
            variant="filled"
            placeHolder="000"
            maxLength={3}
            size={115}
            label="Loads per"
            value={
              laneAndCapacityForm.loadsPerTimeFrame?.optionSelected
                ?.optionName || ''
            }
            onChange={handleCapacityInputsOnChange}
            onBlur={handleCapacityInputsOnBlur}
            name="loadsPerTimeFrame"
            error={
              inputsError.loadsPerTimeFrame &&
              laneAndCapacityForm.loadsPerTimeFrame?.optionSelected
                ?.optionName === ''
                ? 'Required'
                : undefined
            }
          />
          <SelectInputContainer
            error={
              inputsError.timeFrame &&
              laneAndCapacityForm.timeFrame?.optionSelected?.optionName === ''
            }
            isEmpty={
              laneAndCapacityForm.timeFrame?.optionSelected?.optionName ===
                '' && true
            }
          >
            <SelectInput
              variant="filled"
              width={208}
              label="Timeframe"
              options={frequencyOptions}
              value={
                laneAndCapacityForm.timeFrame?.optionSelected || {
                  optionName: '',
                }
              }
              onOptionSelected={(option) =>
                handleCapacityOptionsSelected('timeFrame', true, option!)
              }
            />
            {inputsError.timeFrame &&
              laneAndCapacityForm.timeFrame?.optionSelected?.optionName ===
                '' && <span className="select-error">Required</span>}
          </SelectInputContainer>
        </InputsGroup>
      </AddLaneAndCapacityContent>
      <AddLaneAndCapacityContent separator={true}>
        <InputsTitles>Rate</InputsTitles>
        <CapacityText>
          What is the carrier’s preferred compensation per load?{' '}
          <span style={{ fontStyle: 'italic' }}>
            Final pricing will be negotiated with a rep.
          </span>
        </CapacityText>
        <InputsGroup>
          <SelectInputContainer
            error={
              inputsError.trailerType &&
              laneAndCapacityForm.trailerType?.optionSelected?.optionName === ''
            }
          >
            <SelectInput
              variant="filled"
              width={357}
              label="Trailer Type"
              options={
                laneAndCapacityForm.trailers?.multiSelect
                  ?.filter((x) => x.checked)
                  .map((option) => ({ optionName: option.text })) || []
              }
              value={
                laneAndCapacityForm.trailerType?.optionSelected || {
                  optionName: '',
                }
              }
              onOptionSelected={(option) =>
                handleCapacityOptionsSelected('trailerType', true, option!)
              }
            />
            {inputsError.trailerType &&
            laneAndCapacityForm.trailerType?.optionSelected?.optionName ===
              '' ? (
              !isEdited ? (
                <span className="select-error">Required</span>
              ) : (
                <span className="select-error">Update trailer type</span>
              )
            ) : undefined}
          </SelectInputContainer>
        </InputsGroup>
        <InputsGroup gap={12}>
          <TextInputStyled
            variant="filled"
            size={212}
            placeHolder="$000"
            error={
              rateAmountRef.current &&
              laneAndCapacityForm.rateAmount?.optionSelected?.optionName === ''
                ? 'Update rate'
                : undefined
            }
            label="Rate"
            helperText="Optional"
            value={laneAndCapacityForm.rateAmount?.optionSelected?.optionName}
            name="rateAmount"
            onChange={handleRateAmount}
          />
          <SelectInputContainer
            isEmpty={
              laneAndCapacityForm.selectedRateType?.optionSelected
                ?.optionName === '' && true
            }
          >
            <SelectInput
              variant="filled"
              width={113}
              label="Rate Type"
              options={rateTypeOptions}
              value={
                laneAndCapacityForm.selectedRateType?.optionSelected || {
                  optionName: 'Flat rate',
                }
              }
              onOptionSelected={(option) =>
                handleCapacityOptionsSelected(
                  'selectedRateType',
                  false,
                  option!,
                )
              }
            />
          </SelectInputContainer>
        </InputsGroup>
      </AddLaneAndCapacityContent>
      <AddLaneAndCapacityContent
        separator={true}
        style={{ borderBottom: 'none' }}
      >
        <InputsTitles>Load Details</InputsTitles>
        <CapacityText>
          Are there extra equipments or services they can provide?
        </CapacityText>
        <InputsGroup>
          <div>
            <MultiSelectInput
              variant="filled"
              width={357}
              label="Additional Services"
              options={
                laneAndCapacityForm.additionalServices?.multiSelect || []
              }
              onOptionSelected={(optionText, result) =>
                handleAdditionalServices(optionText, result)
              }
            />
            <OptionalText>Optional</OptionalText>
          </div>
        </InputsGroup>
        <div style={{ marginTop: '20px' }}>
          <CommentBox
            placeholder="Tell us about any additional details or commodities they will not haul."
            value={
              laneAndCapacityForm.commentValue?.optionSelected?.optionName || ''
            }
            onChange={(e) => handleComments(e.target.value)}
            label="Optional"
            rows={4}
            characterLimit={300}
            background="gray10"
          />
        </div>
      </AddLaneAndCapacityContent>
    </div>
  );
};
