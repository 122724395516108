import React, { FunctionComponent } from 'react';
import './panelHeaderSection.scss';
import { Icon as CloverIcon } from 'shamrock-clover-ui';

interface Props {
  toggleIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  requestType: 'new' | 'adjustment';
}

const PanelHeaderSection: FunctionComponent<React.PropsWithChildren<Props>> = ({
  toggleIsPanelOpen,
  requestType,
}) => {
  const handleOnCancel = () => {
    toggleIsPanelOpen(false);
  };

  return (
    <div className="creditRequestHeader">
      <div className="creditRequestTitle">
        {requestType === 'new'
          ? 'New Customer Credit Application'
          : 'Credit Adjustment Application'}
      </div>
      <div className="cancelButton">
        <CloverIcon
          icon="close"
          size="20"
          onClick={() => {
            handleOnCancel();
          }}
        ></CloverIcon>
      </div>
    </div>
  );
};

export default React.memo(PanelHeaderSection);
