import { styled } from 'shamrock-clover-ui';
import { Flex } from '../../../components/Flex';
import { FormControl, withStyles } from '@material-ui/core';
import { LocationPicker } from './LocationPicker';
import Icon from '../../../components/Icon';
import { LabelText } from './PickerStyles';
import { RateProForm } from 'features/ratePro/RateProToolbar';

const InputContainer = styled(Flex)`
  width: 427px;
  height: 36px;
  background-color: white;
  border-radius: 20px;
  padding: 0px 15px;
  border-radius: 20px;
`;

const StyledFormControl = withStyles({
  root: {
    width: '200px',
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
  },
})(FormControl);

const StyledIcon = styled(Icon)`
  padding-right: 10px;
`;

export enum PlaceTypes {
  POSTAL_CODE = 'postal_code',
  CITY_STATE = 'locality',
  ALL = 'all',
}

export type LocationPickerData = {
  city: string;
  state: string;
  postal_code: string;
};

type PickupDeliveryPickerProps = {
  placeType: PlaceTypes;
  value: RateProForm;
  updatePickupLocation: (
    city: string,
    state: string,
    postalCode?: string,
  ) => void;
  updateDeliveryLocation: (
    city: string,
    state: string,
    postalCode?: string,
  ) => void;
};

export const NewPickupDeliveryPicker = (props: PickupDeliveryPickerProps) => {
  const { placeType, value, updatePickupLocation, updateDeliveryLocation } =
    props;
  const labelMap = {
    [PlaceTypes.POSTAL_CODE]: 'PICKUP & DELIVERY',
    [PlaceTypes.CITY_STATE]: 'PICKUP & DELIVERY',
    [PlaceTypes.ALL]: 'PICKUP & DELIVERY',
  };

  const label = labelMap[placeType];

  return (
    <Flex flexDirection="column" justifyContent="space-evenly">
      <LabelText>{label}</LabelText>
      <InputContainer>
        <StyledFormControl size="small">
          <LocationPicker
            placeHolderText={'Pickup City, State or Zip'}
            city={value.pickupCity}
            state={value.pickupState}
            postalCode={value.pickupPostalCode}
            onChange={updatePickupLocation}
            placeType={placeType}
          />
        </StyledFormControl>
        <StyledIcon name="PickupToDeliveryArrow" alt="grey right arrow" />
        <StyledFormControl>
          <LocationPicker
            placeHolderText={'Delivery City, State or Zip'}
            city={value.deliveryCity}
            state={value.deliveryState}
            postalCode={value.deliveryPostalCode}
            onChange={updateDeliveryLocation}
            placeType={placeType}
          />
        </StyledFormControl>
      </InputContainer>
    </Flex>
  );
};
