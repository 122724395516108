import * as React from 'react';
import RateChangeLogCard from './RateChangeLogCardV2';
import {
  Container,
  ChangeLogCardContainer,
  RegionTitleText,
  ChangeLogSelectContainer,
  StyledInputLabel,
  StyledMenuItem,
  StyledFormControlChangeLog,
  SonarSeperator,
} from './rateAdjustmentStylesV2';
import { GridContainer, GridArea } from './rateChangeLogStylesV2';
import {
  useGetAccessorialFeesData,
  useGetRateAdjustmentData,
} from './useRateAdminData';
import { regions } from './rateProAdminRegions';
import { Select } from '@material-ui/core';
import Icon from '../../components/Icon';
import AccessorialsChangeLogCard from './AccessorialsChangeLogCard';
import {
  AccessorialChangeLog,
  AccessorialFeesBody,
  RateAdjustmentApiResult,
} from './rateAdminTypes';

const RateChangeLog = () => {
  const { rateAdjustmenResult } = useGetRateAdjustmentData();
  const { accessorialFeesResult } = useGetAccessorialFeesData();
  const [region, setRegion] = React.useState<string>('All');
  const getOptions = () => {
    return [{ name: 'All' }, ...regions]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((e) => <StyledMenuItem value={e.name}>{e.name}</StyledMenuItem>);
  };

  type AccessorialFormKeys = keyof AccessorialFeesBody;
  type AccessorialStopsFormKeys = keyof {
    oneStop: number;
    twoStops: number;
    threeStops: number;
    fourPlusStops: number;
  };

  const getAccessorialChanges = () => {
    let fieldsToCompare: AccessorialFormKeys[] = [
      'driverAssist',
      'hazmat',
      'palletJack',
      'ramps',
      'sameDay',
      'tankerEndorsed',
      'tarps',
      'team',
      'additionalStops',
    ];
    let additionalStopsToCompare: AccessorialStopsFormKeys[] = [
      'oneStop',
      'twoStops',
      'threeStops',
      'fourPlusStops',
    ];
    let changeLog: any = [];

    if (!accessorialFeesResult) return;

    for (let i = 1; i < accessorialFeesResult?.length; i++) {
      let accessorialChanges: {
        accessorial: string;
        previousValue: number | { key: string; value: number }[];
        updatedValue: number | { key: string; value: number }[];
      }[] = [];
      fieldsToCompare.forEach((key: AccessorialFormKeys) => {
        if (key === 'additionalStops') {
          let previousChanges: { key: string; value: number }[] = [];
          let updatedChanges: { key: string; value: number }[] = [];

          additionalStopsToCompare.forEach(
            (stopKey: AccessorialStopsFormKeys) => {
              if (
                accessorialFeesResult[i].additionalStops[stopKey] !==
                accessorialFeesResult[i - 1].additionalStops[stopKey]
              ) {
                previousChanges.push({
                  key: stopKey,
                  value: accessorialFeesResult[i - 1].additionalStops[stopKey],
                });
                updatedChanges.push({
                  key: stopKey,
                  value: accessorialFeesResult[i].additionalStops[stopKey],
                });
              }
            },
          );

          let change = {
            accessorial: 'additionalStops',
            previousValue: previousChanges,
            updatedValue: updatedChanges,
          };

          if (previousChanges.length > 0) accessorialChanges.push(change);
        } else if (
          accessorialFeesResult[i][key] !== accessorialFeesResult[i - 1][key]
        ) {
          let change = {
            accessorial: key,
            previousValue: accessorialFeesResult[i - 1][key],
            updatedValue: accessorialFeesResult[i][key],
          };
          accessorialChanges.push(change);
        }
      });

      let newChangeRecord: AccessorialChangeLog = {
        accessorialChange: true,
        change: accessorialChanges,
        createdAt: accessorialFeesResult[i].createdAt,
        email: accessorialFeesResult[i].createdBy,
      };
      changeLog.push(newChangeRecord);
    }
    return changeLog;
  };

  const getListByRegion = () => {
    if (
      region.toLowerCase() === 'all' &&
      accessorialFeesResult &&
      rateAdjustmenResult
    ) {
      const accessorialsChangeLogArray = getAccessorialChanges();
      let combinedChangeLog:
        | AccessorialChangeLog[]
        | RateAdjustmentApiResult[] =
        accessorialsChangeLogArray?.concat(rateAdjustmenResult);

      combinedChangeLog.sort(
        (
          a: AccessorialChangeLog | RateAdjustmentApiResult,
          b: AccessorialChangeLog | RateAdjustmentApiResult,
        ) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        },
      );
      return combinedChangeLog;
    } else {
      return rateAdjustmenResult?.filter(
        (e) =>
          e.region?.toLowerCase() === region.toLowerCase() ||
          e.region?.toLowerCase() === 'all',
      );
    }
  };

  return (
    <Container>
      <GridContainer>
        <GridArea>
          <ChangeLogCardContainer
            onClick={(e) => e.stopPropagation()}
            flexDirection="column"
          >
            <RegionTitleText
              fontSize="24px"
              lineHeight="29px"
              width="`122px"
              height="29px"
              color="#333333"
            >
              Change log
            </RegionTitleText>
            <ChangeLogSelectContainer>
              <Icon
                margin="41px 22px 41px 27px"
                width={24}
                height={16}
                name="FilterIcon"
                alt="FilterIcon"
              ></Icon>
              <StyledFormControlChangeLog size="small">
                <StyledInputLabel id="region-select-label">
                  Region
                </StyledInputLabel>
                <Select
                  labelId="region-select-label"
                  id="region-simple-select"
                  MenuProps={{ variant: 'menu' }}
                  onChange={(
                    event: React.ChangeEvent<{
                      name?: string;
                      value: unknown;
                    }>,
                  ) => {
                    setRegion(event.target.value as string);
                  }}
                  disableUnderline
                  value={region}
                >
                  {getOptions()}
                </Select>
                <SonarSeperator width="220px" />
              </StyledFormControlChangeLog>
            </ChangeLogSelectContainer>
          </ChangeLogCardContainer>
          {getListByRegion()?.map((e) => {
            if ('accessorialChange' in e)
              return <AccessorialsChangeLogCard accessorialAdjustment={e} />;
            else return <RateChangeLogCard rateAdjustment={e} />;
          })}
        </GridArea>
      </GridContainer>
    </Container>
  );
};

export default RateChangeLog;
