import * as React from 'react';
import {
  CreditRequestApiResult,
  CreditRequestAutoPopulateType,
} from './creditRequestTypes';

import {
  StyledTable,
  TableHead,
  TableBody,
  StyledRow,
  StyledCell,
  TableContainer,
  TableFooter,
  CloverIcon,
  PageNavFlex,
  Link,
} from './creditRequestStyles';

type Props = {
  creditRequestData: CreditRequestApiResult;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  toggleIsPanelOpen: Function;
  setAutoPopulateData: React.Dispatch<
    React.SetStateAction<CreditRequestAutoPopulateType>
  >;
};

const SearchTable = ({
  creditRequestData,
  setPageNumber,
  toggleIsPanelOpen,
  setAutoPopulateData,
}: Props) => {
  const renderTable = () => {
    return creditRequestData ? (
      creditRequestData.data.map((log, index) => {
        return (
          <StyledRow key={index}>
            <StyledCell>
              <Link
                onClick={() => {
                  toggleIsPanelOpen(true);
                  const auto: CreditRequestAutoPopulateType = {
                    customerName: log.name,
                    customerId: log.id,
                    address1: log.address1,
                    address2: log.address2,
                    city: log.city,
                    state: log.state_id,
                    zipCode: log.zip_code,
                    bridge: log.bridge_id,
                    collectorId: log.collections_id,
                    creditRateCode: log.credit_rate,
                    creditLimit: log.credit_limit,
                    currentReceivables: log.balance + log.unbilled,
                  };
                  setAutoPopulateData(auto);
                }}
              >
                {log.id}
              </Link>
            </StyledCell>
            <StyledCell>{log.name}</StyledCell>
            <StyledCell>{log.address1}</StyledCell>
            <StyledCell>{log.city}</StyledCell>
            <StyledCell>{log.state_id}</StyledCell>
            <StyledCell>{log.zip_code}</StyledCell>
            <StyledCell>{log.contact_name}</StyledCell>
            <StyledCell>{log.fax}</StyledCell>
            <StyledCell>{log.email}</StyledCell>
          </StyledRow>
        );
      })
    ) : (
      <></>
    );
  };

  const renderPageNumber = (creditRequestData: CreditRequestApiResult) => {
    return (
      <p>
        {(
          creditRequestData.page * creditRequestData.limit -
          creditRequestData.limit +
          1
        ).toLocaleString()}
        -
        {(
          creditRequestData.page * creditRequestData.limit -
          creditRequestData.limit +
          creditRequestData.data.length
        ).toLocaleString()}
        {' of '}
        {creditRequestData.count.toLocaleString()}
      </p>
    );
  };

  const handlePageClick = async (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  return (
    <TableContainer>
      <StyledTable>
        <TableHead>
          <StyledRow>
            <StyledCell>CUSTOMER ID</StyledCell>
            <StyledCell>NAME</StyledCell>
            <StyledCell>ADDRESS</StyledCell>
            <StyledCell>CITY</StyledCell>
            <StyledCell>STATE</StyledCell>
            <StyledCell>ZIP CODE</StyledCell>
            <StyledCell>CONTACT</StyledCell>
            <StyledCell>FAX NUMBER</StyledCell>
            <StyledCell>EMAIL</StyledCell>
          </StyledRow>
        </TableHead>
        <TableBody>{renderTable()}</TableBody>
      </StyledTable>
      <TableFooter>
        {creditRequestData ? (
          <PageNavFlex>
            {renderPageNumber(creditRequestData)}
            <CloverIcon
              icon="chevronLeft"
              size="12"
              onClick={() => {
                if (creditRequestData.page > 1)
                  handlePageClick(creditRequestData?.page - 1);
              }}
            ></CloverIcon>
            <CloverIcon
              icon="chevronRight"
              size="12"
              onClick={() => {
                if (
                  creditRequestData.page <
                  creditRequestData.count -
                    creditRequestData.limit * creditRequestData.page
                )
                  handlePageClick(creditRequestData?.page + 1);
              }}
            ></CloverIcon>
          </PageNavFlex>
        ) : (
          <></>
        )}
      </TableFooter>
    </TableContainer>
  );
};

export default SearchTable;
