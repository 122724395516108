import { Flex } from 'components/Flex';
import {
  LargeText,
  MediumText,
  SmallText,
  RateType,
  RateTypeDivider,
} from './rateProStyles';
import { Rate } from './ratesTypes';

const RateDisplay = ({
  timeframe,
  originEscalationType,
  destinationEscalationType,
  mileRate,
  mileRateLowHighText,
  tripRate,
  tripRateLowHighText,
  experiencesText,
}: Rate) => {
  return (
    <div>
      <Flex width="100%" justifyContent="space-between">
        <Flex>
          <LargeText>{tripRate}</LargeText>
          <MediumText margin="18px 0px 0px 5px" bold={true}>
            BUY
          </MediumText>
        </Flex>
        <MediumText margin="18px 0px 0px 0px" bold={true}>
          {mileRate}
        </MediumText>
      </Flex>
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        margin="-5px 0px 0px 0px"
      >
        <MediumText bold={false}>{tripRateLowHighText}</MediumText>
        <SmallText>{mileRateLowHighText}</SmallText>
      </Flex>
      <Flex margin="14px 0px 0px 0px">
        <RateType selected={true} alignItems="center" justifyContent="center">
          {timeframe}
        </RateType>
        <RateType alignItems="center" justifyContent="center">
          {originEscalationType} - {destinationEscalationType}
        </RateType>
        {experiencesText && (
          <RateTypeDivider alignItems="center" justifyContent="center">
            |
          </RateTypeDivider>
        )}
        <RateType alignItems="center" justifyContent="center">
          {experiencesText}
        </RateType>
      </Flex>
    </div>
  );
};

export default RateDisplay;
