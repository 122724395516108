import { useEffect, useState } from 'react';
import { QuoteHistoryItem } from '../utils/quoteTypes';
import { getTimePassed } from '../utils/quoteUtils';

const useTimer = (quoteHistory: QuoteHistoryItem[], setTimer: number) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [expired, setExpired] = useState<boolean>(false);

  const interval = 59;

  useEffect(() => {
    if (quoteHistory) {
      const lastHistory = quoteHistory[quoteHistory.length - 1];
      const historyDate = new Date(lastHistory.createdAt);
      const timePassed = getTimePassed(historyDate);
      setSeconds(timePassed.secondsRemaining);
      setMinutes(timePassed.minutesRemaining);
      setHours(timePassed.hoursPassed);

      if (hours > 0 || minutes >= setTimer) {
        setExpired(true);
      } else {
        setExpired(false);
      }

      if (
        !expired ||
        lastHistory.status === 'pending' ||
        lastHistory.status === 'approved' ||
        (lastHistory.status === 'countered' && !lastHistory.isInternalUser)
      ) {
        setTimeout(() => {
          if (seconds >= interval) {
            setSeconds(0);
            if (minutes >= interval) {
              setMinutes(0);
            } else {
              setMinutes(minutes + 1);
            }
          } else {
            setSeconds(seconds + 1);
          }
        }, 1000);
      }
    }
  }, [quoteHistory, seconds, minutes, hours, setTimer, expired]);

  return {
    seconds,
    minutes,
    hours,
    expired,
  };
};

export default useTimer;
