import http from 'utils/httpUtils';
import { REACT_APP_MPACT_API_BASE_URL } from 'utils/constants';
import { MpactApiRequest, MpactApiResult } from './mpactTypes';

export const getMpactAPIData = async (
  requestData: MpactApiRequest,
): Promise<MpactApiResult | undefined> => {
  const urlSearchParams = new URLSearchParams();

  // Loop through the MpactApiRequest object and append each key-value pair to URLSearchParams
  for (const [key, value] of Object.entries(requestData)) {
    if (value !== undefined) {
      urlSearchParams.append(key, encodeURIComponent(value));
    }
  }

  // Create the full URL by appending the query string
  const fullPath = `${REACT_APP_MPACT_API_BASE_URL}/mpact/rates?${urlSearchParams.toString()}`;
  // Sending the most precise location data to the server, and the server can choose to prioritize zip over city/state if it wants
  const result = http
    .get<MpactApiResult>(fullPath)()
    .catch((e) => {
      return Promise.reject(`Error getting MPACT data: ${e}`);
    });

  return result;
};
