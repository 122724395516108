import { styled } from 'shamrock-clover-ui';

export const Container = styled.div`
  height: calc(100vh - 100px);
  background-color: ${({ theme }) => theme.colors.gray[10]};
`;

export const GridContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  padding: 16px 64px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  align-items: center;
`;

export const StyledChangeLogCardHeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 47px;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledChangeLogCardHeader = styled.div<{
  marginLeft: string;
  marginRight: string;
}>`
  align-items: flex-start;
  display: flex;
  width: 48%;
  height: 38px;
  background: #f9f9f9;
  align-items: center;
  justify-content: flex-start;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: 24px;
`;

export const StyleChangeLogTitleText = styled.div<{
  fontSize?: string;
  height?: string;
  lineHeight?: string;
  width?: string;
  color?: string;
  fontWeight?: string;
  textAlign?: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => (color ? color : '#333333')};
  height: ${({ height }) => height};
  line-height: ${({ lineHeight }) => lineHeight};
  width: ${({ width }) => width};
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  padding: 24px;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
`;

export const StyledChangeLogCard = styled.div<{
  height: string;
  width: string;
}>`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: #f9f9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.294007);
  border-radius: 2px;
  margin-left: 24px;
  margin-bottom: 25px;

  @media (min-width: 1900px) {
    width: 540px !important;
  }
`;

export const StyledChangeLogTrailerContainer = styled.div<{
  flexDirection: string;
  justifyContent?: string;
}>`
  display: flex;
  // flex: 1;
  flex-direction: ${({ flexDirection }) => flexDirection};
  // flex: 0 0 100%;
  width: 100%;
  height: auto;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: 14px 0;
  flex-wrap: wrap;
`;
