import * as React from "react";

import {
  StyledEquipmentCard,
  StyledEquipmentCardHeader,
  StyleDatText,
  StyledEquipmentCardContainer,
  StyledHistoryReeferFormControl,
  StyledMenuItem,
  SonarSeperator,
  StyledSonarContainer,
} from "../rateAdjustmentStylesV2";
import { Select } from "@material-ui/core";
import { useRateAdjustmentQueryParams } from "../../../hooks/useQueryParams";

const items = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
];
const historyItems = ["LOWER", "HIGHER"];

const FlatbedHistoryCardV2 = () => {
  const { addQueryParams, queryParams } = useRateAdjustmentQueryParams();

  const numberOfLoads = queryParams.flatbedNumberOfLoads
    ? queryParams.flatbedNumberOfLoads
    : "";
  const rtsRate = queryParams.flatbedRtsRate ? queryParams.flatbedRtsRate : "";

  const getOptions = () => {
    return items.map((e) => <StyledMenuItem value={e}>{e}</StyledMenuItem>);
  };

  const getHistoryOptions = () => {
    return historyItems.map((e) => (
      <StyledMenuItem value={e}>{e}</StyledMenuItem>
    ));
  };

  const onChangeNumberOfLoads = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    addQueryParams({
      flatbedNumberOfLoads: event.target.value as string,
    });
  };

  const onChangeRate = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    addQueryParams({
      flatbedRtsRate: event.target.value as string,
    });
  };

  return (
    <StyledEquipmentCard width="372px" height="156px">
      <StyledEquipmentCardHeader>
        <StyleDatText marginLeft="16px" fontWeight="400" marginTop="0px" padding="12px 0px 12px 0px">
          RTS HISTORY OVERRIDE
        </StyleDatText>
      </StyledEquipmentCardHeader>
      <StyledEquipmentCardContainer flexDirection="column">
        <StyledSonarContainer>
          <StyleDatText marginLeft="16px" fontWeight="400" marginTop="20px">
            Number <br /> of loads
          </StyleDatText>
          <StyledHistoryReeferFormControl size="small">
            <Select
              id="sonar-simple-select"
              MenuProps={{ variant: "menu" }}
              onChange={onChangeNumberOfLoads}
              value={numberOfLoads}
              disableUnderline
            >
              {getOptions()}
            </Select>
            <SonarSeperator width="250px" />
          </StyledHistoryReeferFormControl>
          <StyleDatText marginLeft="16px" fontWeight="400" marginTop="25px">
            {`& RTS rate`}
          </StyleDatText>
          <StyledHistoryReeferFormControl size="small">
            <Select
              id="sonar-simple-select"
              MenuProps={{ variant: "menu" }}
              onChange={onChangeRate}
              value={rtsRate}
              disableUnderline
            >
              {getHistoryOptions()}
            </Select>
            <SonarSeperator width="250px" />
          </StyledHistoryReeferFormControl>
        </StyledSonarContainer>
      </StyledEquipmentCardContainer>
    </StyledEquipmentCard>
  );
};

export default FlatbedHistoryCardV2;
