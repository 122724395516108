import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { styled } from 'shamrock-clover-ui';
import useHover from './useHover';

export type TooltipPlacement = 'top' | 'bottom' | 'right' | 'left';

interface TooltipProps {
  children: React.ReactNode;
  title: string;
  placement?: TooltipPlacement;
  style?: React.CSSProperties;
}

const TooltipContainer = styled.div`
  cursor: pointer;
`;

const TooltipText = styled.span`
  cursor: default;
  background-color: ${(props) => props.theme.uiBlack};
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  color: ${(props) => props.theme.white};
  font-family: ${(props) => props.theme.font.family.proximaNova};
  font-size: 12px;
  line-height: 14px;
  padding: 6px;
  position: absolute;
  max-width: 360px;
  z-index: 999;
  width: max-content;
  text-transform: none;
  display: 'inline-block';
  transition: opacity 0.3s ease;
`;

export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = (
  props: TooltipProps,
) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const currentTarget: HTMLDivElement | null = targetRef?.current;
  const currentTooltip: HTMLDivElement | null = tooltipRef?.current;
  const isTargetHovered = useHover(targetRef);
  const isTooltipHovered = useHover(tooltipRef);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const tooltipMargin = 6;
  const body = document.body;
  const docEl = document.documentElement;

  // Top/Left for relevant elements
  const [tooltipTop, setTooltipTop] = useState(0);
  const [tooltipLeft, setTooltipLeft] = useState(0);
  const targetOffsets = currentTarget?.getBoundingClientRect();
  const targetTop = targetOffsets?.top ?? 0;
  const targetLeft = targetOffsets?.left ?? 0;
  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  // Height/Width for relevant elements
  const clientHeight = docEl.clientHeight || body.clientHeight || 0;
  const clientWidth = docEl.clientWidth || body.clientWidth || 0;
  const tooltipHeight = currentTooltip?.clientHeight ?? 0;
  const tooltipWidth = currentTooltip?.clientWidth ?? 0;
  const targetHeight = currentTarget?.clientHeight ?? 0;
  const targetWidth = currentTarget?.clientWidth ?? 0;

  const centerHorizontally = (positionLeft: number): number =>
    positionLeft - tooltipWidth / 2 + targetWidth / 2;

  const centerVertically = (positionTop: number): number =>
    positionTop - tooltipHeight / 2 + targetHeight / 2;

  const isLessThanPageHeight = (positionTop: number): boolean =>
    positionTop < scrollTop ? true : false;

  const isGreaterThanPageHeight = (positionTop: number): boolean =>
    positionTop + tooltipHeight > clientHeight + scrollTop;

  const isLessThanPageWidth = (positionLeft: number): boolean =>
    positionLeft < scrollLeft ? true : false;

  const isGreaterThanPageWidth = (positionLeft: number): boolean =>
    positionLeft + tooltipWidth > clientWidth + scrollLeft;

  const setInBoundsHorizontally = (positionLeft: number): number =>
    isLessThanPageWidth(positionLeft)
      ? tooltipMargin + scrollLeft
      : isGreaterThanPageWidth(positionLeft)
      ? clientWidth + scrollLeft - tooltipWidth - tooltipMargin
      : positionLeft;

  const setInBoundsVertically = (positionTop: number): number =>
    isLessThanPageHeight(positionTop)
      ? tooltipMargin + scrollTop
      : isGreaterThanPageHeight(positionTop)
      ? clientHeight + scrollTop - tooltipHeight - tooltipMargin
      : positionTop;

  const positionTooltip = () => {
    let top = targetTop + scrollTop;
    let left = targetLeft + scrollLeft;
    switch (props.placement) {
      case 'top':
        top -= tooltipHeight;
        top -= tooltipMargin;
        left = centerHorizontally(left);
        break;
      case 'bottom':
        top += targetHeight;
        top += tooltipMargin;
        left = centerHorizontally(left);
        break;
      case 'right':
        left += targetWidth;
        left += tooltipMargin;
        top = centerVertically(top);
        break;
      case 'left':
        left -= tooltipWidth;
        left -= tooltipMargin;
        top = centerVertically(top);
        break;
    }

    top = setInBoundsVertically(top);
    left = setInBoundsHorizontally(left);
    setTooltipTop(top);
    setTooltipLeft(left);
  };

  useEffect(() => {
    positionTooltip();
    setIsHovered(isTargetHovered || isTooltipHovered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTargetHovered,
    isTooltipHovered,
    targetTop,
    targetLeft,
    scrollTop,
    scrollLeft,
    clientHeight,
    clientWidth,
    targetHeight,
    targetWidth,
  ]);

  const tooltipEl = (
    <TooltipText
      ref={tooltipRef}
      style={{
        ...props.style,
        opacity: isHovered ? 1 : 0,
        visibility: isHovered ? 'visible' : 'hidden',
        top: tooltipTop,
        left: tooltipLeft,
      }}
    >
      {props.title}
    </TooltipText>
  );

  return (
    <TooltipContainer ref={targetRef}>
      {props.children}
      {createPortal(tooltipEl, body)}
    </TooltipContainer>
  );
};

Tooltip.defaultProps = {
  placement: 'bottom',
};
