import { FC } from 'react';
import { styled } from 'shamrock-clover-ui';
import { QuoteTimer, TimerProps } from '../utils/quoteTypes';
import { useBookedLoadsAlertFeatureFlag } from 'hooks/useFeatureFlag';

const ShowTimer = styled.span<TimerProps>`
  color: ${({ hours, minutes, expired }) =>
    (hours && hours > 0) || (minutes && minutes >= expired) ? 'red' : 'black'};
`;

const timeText = (timePortion: number): string => {
  const textOutput = timePortion < 10 ? `0${timePortion}` : `${timePortion}`;
  return textOutput;
};

const Timer: FC<React.PropsWithChildren<QuoteTimer>> = ({
  hours,
  minutes,
  seconds,
  cancelledButton,
}) => {
  const { isBookedLoadsAlertEnabled } = useBookedLoadsAlertFeatureFlag();
  return (
    <>
      {!cancelledButton ? (
        <ShowTimer
          hours={hours}
          minutes={minutes}
          expired={isBookedLoadsAlertEnabled ? 10 : 15}
        >
          {hours > 0
            ? `${timeText(hours)}:${timeText(minutes)}:${timeText(seconds)}`
            : `${timeText(minutes)}:${timeText(seconds)}`}
        </ShowTimer>
      ) : null}
    </>
  );
};

export default Timer;
