import * as React from "react";
import {
  StyledCard,
  CardContainer,
  StyleDatText,
} from "./rateAdjustmentStyles";

import {
  StyledChangeLogCardHeaderContainer,
  StyledChangeLogCardHeader,
  StyleChangeLogTitleText,
  StyledChangeLogTrailerContainer,
} from "./rateChangeLogStyles";
import ChangeLogCardItem from "./components/ChangeLogCardItem";
import ChangeLogFlatbedCardItem from "./components/ChangeLogFlatbedCardItem";
import { RateAdjustmentApiResult } from "./rateAdminTypes";

interface Props {
  rateAdjustment: RateAdjustmentApiResult;
}

const RateChangeLogCard: React.FC<React.PropsWithChildren<Props>> = ({ rateAdjustment }) => {
  const date = new Date(rateAdjustment.createdAt);

  const isReset = rateAdjustment.reason.indexOf("Reset all regions") !== -1;

  const dateString =
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear();

  return (
    <StyledCard height="auto" borderBottom="1px solid #CCCCCC">
      <CardContainer flexDirection="column">
        <StyleChangeLogTitleText
          fontSize="14px"
          lineHeight="29px"
          width="95%"
          height="29px"
          color="#666666"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {rateAdjustment.email} <span>{dateString}</span>
        </StyleChangeLogTitleText>
        <StyledChangeLogCardHeaderContainer>
          <StyledChangeLogCardHeader marginLeft="24px" marginRight="0px">
            <StyleDatText marginLeft="8px" fontWeight="400" marginTop="0px">
              <span style={{ fontWeight: "800" }}>REGION:&nbsp;</span>
              {`${rateAdjustment?.region ? rateAdjustment.region : ""}`}
            </StyleDatText>
          </StyledChangeLogCardHeader>
          <StyledChangeLogCardHeader marginLeft="0px" marginRight="24px">
            <StyleDatText marginLeft="8px" fontWeight="400" marginTop="0px">
              <span style={{ fontWeight: "800" }}>REASON:&nbsp;</span>
              <span style={{ color: isReset ? "red" : "#666666" }}>{`${
                rateAdjustment?.reason ? rateAdjustment.reason : ""
              }`}</span>
            </StyleDatText>
          </StyledChangeLogCardHeader>
        </StyledChangeLogCardHeaderContainer>
        <StyledChangeLogTrailerContainer
          flexDirection="row"
          justifyContent="center"
        >
          <ChangeLogCardItem
            trailerType="VAN"
            lowStart={rateAdjustment.lowVanSonarBeginning}
            lowEnd={rateAdjustment.lowVanSonarEnd}
            averageStart={rateAdjustment.averageVanSonarBeginning}
            averageEnd={rateAdjustment.averageVanSonarEnd}
            highEnd={rateAdjustment.highVanSonarEnd}
            highStart={rateAdjustment.highVanSonarBeginning}
            numberOfLoads={rateAdjustment.vanNumberOfLoads}
            dataHistory={rateAdjustment.vanRtsRate}
          />
          <ChangeLogCardItem
            trailerType="REEFER"
            lowStart={rateAdjustment.lowReeferSonarBeginning}
            lowEnd={rateAdjustment.lowReeferSonarEnd}
            averageStart={rateAdjustment.averageReeferSonarBeginning}
            averageEnd={rateAdjustment.averageReeferSonarEnd}
            highEnd={rateAdjustment.highReeferSonarEnd}
            highStart={rateAdjustment.highReeferSonarBeginning}
            numberOfLoads={rateAdjustment.reeferNumberOfLoads}
            dataHistory={rateAdjustment.reeferRtsRate}
          />
          <ChangeLogFlatbedCardItem
            trailerType="FLATBED"
            flatbedDatRate={rateAdjustment.flatbedDatRate}
            flatbedNumberOfLoads={rateAdjustment.flatbedNumberOfLoads}
            flatbedRtsRate={rateAdjustment.flatbedRtsRate}
          />
        </StyledChangeLogTrailerContainer>
      </CardContainer>
    </StyledCard>
  );
};

export default RateChangeLogCard;
