import { StyledChip } from "../CarrierCapacityStyles";

const CapacityScoreChip = ({ score }: { score: number }) => {
  const backgroundColor = score > 50 ? '#72BF44' : "#999999";
  const scoreString = score.toString();
  return (
    <StyledChip label={`${scoreString}%`} variant="small" state="selected" backgroundColor={backgroundColor} />
  );
};

export default CapacityScoreChip;