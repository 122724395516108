import React, { FC, useEffect, useState } from 'react';
// Import Table Styles
import { StyledTableRowContainer, StyledTableRowCell } from './TableRow.styles';
// Import Table Types
import { Cell, Column, SortOrder } from './TableTypes';
import { Tooltip } from './Tooltip';

interface TableRowProps {
  onClick?: () => void;
  height?: number;
  columns: Column[];
  cells: Cell[];
  sortedColumnIndex?: number;
  sortDirection?: SortOrder;
}

const StyledTableRow: FC<React.PropsWithChildren<TableRowProps>> = ({
  onClick,
  height,
  cells,
  columns,
  sortedColumnIndex,
  sortDirection,
}) => {
  const [sortKey, setSortKey] = useState(
    `${sortedColumnIndex}_${sortDirection}`,
  );
  const [isSortAnimating, setIsSortAnimating] = useState(false);

  useEffect(() => {
    if (`${sortedColumnIndex}_${sortDirection}` !== sortKey) {
      setSortKey(`${sortedColumnIndex}_${sortDirection}`);
      setIsSortAnimating(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumnIndex, sortDirection]);

  return (
    <StyledTableRowContainer onClick={onClick} height={height}>
      {cells.map((cell, index) => {
        const cellAlignment =
          cell.alignment ||
          columns[index].alignment ||
          (typeof cell.content === 'string' && cell.content.startsWith('$')
            ? 'right'
            : 'left');
        return (
          <StyledTableRowCell
            key={`${columns[index].key}-${index}`}
            columnIndex={index}
            width={columns[index].width}
            alignment={cellAlignment}
            sortedColumnIndex={sortedColumnIndex}
            isSortAnimating={isSortAnimating}
            onAnimationEnd={() => setIsSortAnimating(false)}
          >
            {cell.toolTipText ? (
              <div style={{ display: 'inline-block', width: 'auto' }}>
                <Tooltip title={cell.toolTipText} placement={'top'}>
                  {cell.content || '-'}
                </Tooltip>
              </div>
            ) : (
              cell.content || '-'
            )}
          </StyledTableRowCell>
        );
      })}
    </StyledTableRowContainer>
  );
};

export default StyledTableRow;
