import * as React from 'react';
import { FormControl, Select, MenuItem, withStyles } from '@material-ui/core';
import { Flex } from '../../../components/Flex';
import { LabelText } from '../rateProStyles';
import { styled } from 'shamrock-clover-ui';

const reasons = [
  'Easing Market',
  'Weather',
  'Transit Time for Single Driver',
  'Weekend Pick/Delivery',
  'Misc Accessorial',
  'Holiday',
];

const FormControlWithStyles = withStyles({
  root: {
    width: '216px',
    height: '36px',
    background: 'white',
    borderRadius: '20px',
  },
})(FormControl);

const StyledFormControl = styled(FormControlWithStyles)<{
  border: string;
}>`
  border: ${({ border }) => border};
`;

const StyledMenuItem = withStyles({
  root: {
    fontSize: '14px',
  },
})(MenuItem);

const StyledSelect = withStyles({
  root: {
    fontSize: '14px',
  },
  select: {
    fontSize: '14px',
    paddingLeft: '15px',
    paddingTop: '7px',
    fontFamily: 'Proxima Nova',
  },
})(Select);

interface Props {
  selectedReason: string | null;
  setReason: React.Dispatch<React.SetStateAction<string | null>>;
  isDisabled: boolean;
  isFooterDisabled: boolean;
}

const ReasonInput = ({
  selectedReason,
  setReason,
  isDisabled,
  isFooterDisabled,
}: Props) => {
  const onChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setReason(event.target.value as string);
  };

  const border =
    isFooterDisabled === false &&
    isDisabled === false &&
    selectedReason === null
      ? '1px solid red !important'
      : 'inherit';

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <LabelText>Reason for Difference</LabelText>
      <StyledFormControl border={border}>
        <StyledSelect
          MenuProps={{ variant: 'menu' }}
          disableUnderline
          value={selectedReason ? selectedReason : 'placeholder'}
          onChange={onChange}
          disabled={isDisabled}
        >
          <StyledMenuItem disabled value="placeholder">
            Select Reason
          </StyledMenuItem>
          {reasons.map((reason) => (
            <StyledMenuItem key={reason} value={reason}>
              {reason}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </Flex>
  );
};

export default ReasonInput;
