import React, { FunctionComponent, useState } from 'react';
import * as FirebaseUtils from '../../../utils/firebaseUtils';
import { getUserLocalStorage } from 'utils/authUtils';

// components
import PanelHeaderSection from './headerSection/panelHeaderSection';
import PanelFooterSection from './footerSection/panelFooterSection';
import { CustomerInfoForm } from './CustomerInfoForm';
import { NewAdjustmentForm } from './NewAdjustmentForm';
import { AdjustmentForm } from './AdjustmentForm';
import { CreditInfoForm } from './CreditInfoForm';
import { CargoInfoForm } from './CargoInfoForm';

import {
  CustomerInfoFormData,
  CargoInfoFormData,
  CreditRequestAutoPopulateType,
  NewAdjustmentFormData,
  AdjustmentFormData,
  CreditInfoFormData,
  NewCreditRequestEmailData,
  AdjustmentCreditRequestEmailData,
} from '../creditRequestTypes';

import {
  postAdjustmentCreditRequestEmail,
  postNewCreditRequestEmail,
} from '../creditRequestHttp';

// styles
import {
  ContentContainer,
  Content,
  SectionHeader,
  Details,
} from './panelStyles';

interface Props {
  toggleIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleIsLoading: (value: boolean) => void;
  autoPopulateData: CreditRequestAutoPopulateType;
  showSnackbar: Function;
  requestType: 'new' | 'adjustment';
}

export const CreditRequestPanel: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  toggleIsPanelOpen,
  toggleIsLoading,
  autoPopulateData,
  showSnackbar,
  requestType,
}) => {
  const user = getUserLocalStorage();

  const [customerInfoFormData, setCustomerInfoFormData] =
    useState<CustomerInfoFormData>({
      customerId: { errorMessage: '', value: autoPopulateData.customerId },
      customerName: { errorMessage: '', value: autoPopulateData.customerName },
      addressLine1: { errorMessage: '', value: autoPopulateData.address1 },
      addressLine2: { errorMessage: '', value: autoPopulateData.address2 },
      city: { errorMessage: '', value: autoPopulateData.city },
      state: { errorMessage: '', value: autoPopulateData.state },
      zip: { errorMessage: '', value: autoPopulateData.zipCode },
      bridge: { errorMessage: '', value: autoPopulateData.bridge },
      collectorId: { errorMessage: '', value: autoPopulateData.collectorId },
      creditRateCode: {
        errorMessage: '',
        value: autoPopulateData.creditRateCode,
      },
    });

  const [newAdjustmentFormData, setNewAdjustmentFormData] =
    useState<NewAdjustmentFormData>({
      contactName: { errorMessage: '', value: '' },
      jobTitle: { errorMessage: '', value: '' },
      faxNumber: { errorMessage: '', value: '' },
      phoneNumber: { errorMessage: '', value: '' },
      email: { errorMessage: '', value: '' },
      businessObtained: { errorMessage: '', value: '' },
      businessObtainedAdditional: { errorMessage: '', value: '' },
      immediateCreditNeed: { errorMessage: '', value: '' },
      singleLoadAmount: { errorMessage: '', value: '' },
      multipleLoadAmount: { errorMessage: '', value: '' },
      potentialLongtermBusiness: { errorMessage: '', value: '' },
      comments: { errorMessage: '', value: '' },
    });

  const [adjustmentFormData, setAdjustmentFormData] =
    useState<AdjustmentFormData>({
      currentCreditLimit: {
        errorMessage: '',
        value: autoPopulateData.creditLimit,
      },
      currentReceivables: {
        errorMessage: '',
        value: autoPopulateData.currentReceivables,
      },
      numberOfAdditionalLoads: { errorMessage: '', value: '' },
      additionalCreditRequested: { errorMessage: '', value: '' },
      singleLoadAmount: { errorMessage: '', value: '' },
      multipleLoadAmount: { errorMessage: '', value: '' },
    });

  const [creditInfoFormData, setCreditInfoFormData] =
    useState<CreditInfoFormData>({
      creditType: { errorMessage: '', value: 'Credit Increase' },
      reason: { errorMessage: '', value: 'New Business (We Solicited)' },
      creditComments: { errorMessage: '', value: '' },
    });

  const [cargoInfoFormData, setCargoInfoFormData] = useState<CargoInfoFormData>(
    {
      commodities: { errorMessage: '', value: '' },
      averageValue: { errorMessage: '', value: '' },
      maxExpectedValue: { errorMessage: '', value: '' },
      modeOfTransportation: { errorMessage: '', value: '' },
    },
  );

  interface NewAdjustmentErrorFormData {
    contactName?: string;
    jobTitle?: string;
    faxNumber?: string;
    phoneNumber?: string;
    email?: string;
    businessObtained?: string;
    businessObtainedAdditional?: string;
    immediateCreditNeed?: string;
    singleLoadAmount?: string;
    multipleLoadAmount?: string;
    potentialLongtermBusiness?: string;
  }
  interface AdjustmentErrorFormData {
    currentCreditLimit?: string;
    currentReceivables?: string;
    numberOfAdditionalLoads?: string;
    additionalCreditRequested?: string;
    singleLoadAmount?: string;
    multipleLoadAmount?: string;
  }
  interface CreditInfoErrorFormData {
    creditType?: string;
    reason?: string;
    creditComments?: string;
  }
  interface CargoInfoErrorFormData {
    commodities?: string;
    averageValue?: string;
    maxExpectedValue?: string;
    modeOfTransportation?: string;
  }

  type NewAdjustmentFormKeys = keyof NewAdjustmentFormData;
  type NewAdjustmentErrorFormDataKey = keyof NewAdjustmentErrorFormData;
  type AdjustmentFormKeys = keyof AdjustmentFormData;
  type AdjustmentErrorFormDataKey = keyof AdjustmentErrorFormData;
  type CreditInfoFormKeys = keyof CreditInfoFormData;
  type CreditInfoErrorFormDataKey = keyof CreditInfoErrorFormData;
  type CargoInfoFormKeys = keyof CargoInfoFormData;
  type CargoInfoErrorFormDataKey = keyof CargoInfoErrorFormData;

  const checkNewAdjustmentErrors = () => {
    let fields: NewAdjustmentFormKeys[] = [
      'contactName',
      'jobTitle',
      'phoneNumber',
      'email',
      'businessObtained',
      'businessObtainedAdditional',
      'immediateCreditNeed',
      'singleLoadAmount',
      'multipleLoadAmount',
      'potentialLongtermBusiness',
    ];
    let errorMessages: NewAdjustmentErrorFormData = {};

    fields.forEach((key: NewAdjustmentFormKeys) => {
      if (newAdjustmentFormData[key].value === '') {
        errorMessages[key as NewAdjustmentErrorFormDataKey] = 'Required field';
      }
    });

    setNewAdjustmentFormData({
      ...newAdjustmentFormData,
      contactName: {
        value: newAdjustmentFormData.contactName.value,
        errorMessage: errorMessages.contactName,
      },
      jobTitle: {
        value: newAdjustmentFormData.jobTitle.value,
        errorMessage: errorMessages.jobTitle,
      },
      faxNumber: {
        value: newAdjustmentFormData.faxNumber.value,
        errorMessage: errorMessages.faxNumber,
      },
      phoneNumber: {
        value: newAdjustmentFormData.phoneNumber.value,
        errorMessage: errorMessages.phoneNumber,
      },
      email: {
        value: newAdjustmentFormData.email.value,
        errorMessage: errorMessages.email,
      },
      businessObtained: {
        value: newAdjustmentFormData.businessObtained.value,
        errorMessage: errorMessages.businessObtained,
      },
      businessObtainedAdditional: {
        value: newAdjustmentFormData.businessObtainedAdditional.value,
        errorMessage: errorMessages.businessObtainedAdditional,
      },
      immediateCreditNeed: {
        value: newAdjustmentFormData.immediateCreditNeed.value,
        errorMessage: errorMessages.immediateCreditNeed,
      },
      singleLoadAmount: {
        value: newAdjustmentFormData.singleLoadAmount.value,
        errorMessage: errorMessages.singleLoadAmount,
      },
      multipleLoadAmount: {
        value: newAdjustmentFormData.multipleLoadAmount.value,
        errorMessage: errorMessages.multipleLoadAmount,
      },
      potentialLongtermBusiness: {
        value: newAdjustmentFormData.potentialLongtermBusiness.value,
        errorMessage: errorMessages.potentialLongtermBusiness,
      },
    });
  };

  const checkAdjustmentErrors = () => {
    let fields: AdjustmentFormKeys[] = [
      'numberOfAdditionalLoads',
      'additionalCreditRequested',
      'singleLoadAmount',
      'multipleLoadAmount',
    ];
    let errorMessages: AdjustmentErrorFormData = {};

    fields.forEach((key: AdjustmentFormKeys) => {
      if (adjustmentFormData[key].value === '') {
        errorMessages[key as AdjustmentErrorFormDataKey] = 'Required field';
      }
    });

    setAdjustmentFormData({
      ...adjustmentFormData,
      currentCreditLimit: {
        value: adjustmentFormData.currentCreditLimit.value,
        errorMessage: errorMessages.currentCreditLimit,
      },
      currentReceivables: {
        value: adjustmentFormData.currentReceivables.value,
        errorMessage: errorMessages.currentReceivables,
      },
      numberOfAdditionalLoads: {
        value: adjustmentFormData.numberOfAdditionalLoads.value,
        errorMessage: errorMessages.numberOfAdditionalLoads,
      },
      additionalCreditRequested: {
        value: adjustmentFormData.additionalCreditRequested.value,
        errorMessage: errorMessages.additionalCreditRequested,
      },
      singleLoadAmount: {
        value: adjustmentFormData.singleLoadAmount.value,
        errorMessage: errorMessages.singleLoadAmount,
      },
      multipleLoadAmount: {
        value: adjustmentFormData.multipleLoadAmount.value,
        errorMessage: errorMessages.multipleLoadAmount,
      },
    });
  };

  const checkCreditInfoErrors = () => {
    let fields: CreditInfoFormKeys[] = [
      'creditType',
      'reason',
      'creditComments',
    ];
    let errorMessages: CreditInfoErrorFormData = {};

    fields.forEach((key: CreditInfoFormKeys) => {
      if (creditInfoFormData[key].value === '') {
        errorMessages[key as CreditInfoErrorFormDataKey] = 'Required field';
      }
    });

    setCreditInfoFormData({
      ...creditInfoFormData,
      creditType: {
        value: creditInfoFormData.creditType.value,
        errorMessage: errorMessages.creditType,
      },
      reason: {
        value: creditInfoFormData.reason.value,
        errorMessage: errorMessages.reason,
      },
      creditComments: {
        value: creditInfoFormData.creditComments.value,
        errorMessage: errorMessages.creditComments,
      },
    });
  };

  const checkCargoInfoErrors = () => {
    let fields: CargoInfoFormKeys[] = [
      'commodities',
      'averageValue',
      'maxExpectedValue',
      'modeOfTransportation',
    ];
    let errorMessages: CargoInfoErrorFormData = {};

    fields.forEach((key: CargoInfoFormKeys) => {
      if (cargoInfoFormData[key].value === '') {
        errorMessages[key as CargoInfoErrorFormDataKey] = 'Required field';
      }
    });

    setCargoInfoFormData({
      ...cargoInfoFormData,
      commodities: {
        value: cargoInfoFormData.commodities.value,
        errorMessage: errorMessages.commodities,
      },
      averageValue: {
        value: cargoInfoFormData.averageValue.value,
        errorMessage: errorMessages.averageValue,
      },
      maxExpectedValue: {
        value: cargoInfoFormData.maxExpectedValue.value,
        errorMessage: errorMessages.maxExpectedValue,
      },
      modeOfTransportation: {
        value: cargoInfoFormData.modeOfTransportation.value,
        errorMessage: errorMessages.modeOfTransportation,
      },
    });
  };

  const checkNewAdjustmentComplete = () => {
    let fields: NewAdjustmentFormKeys[] = [
      'contactName',
      'jobTitle',
      'phoneNumber',
      'email',
      'businessObtained',
      'businessObtainedAdditional',
      'immediateCreditNeed',
      'singleLoadAmount',
      'multipleLoadAmount',
      'potentialLongtermBusiness',
    ];

    let isComplete: boolean = true;
    fields.forEach((key: NewAdjustmentFormKeys) => {
      if (newAdjustmentFormData[key].value === '') {
        isComplete = false;
      }
    });
    return isComplete;
  };

  const checkAdjustmentComplete = () => {
    let fields: AdjustmentFormKeys[] = [
      'numberOfAdditionalLoads',
      'additionalCreditRequested',
      'singleLoadAmount',
      'multipleLoadAmount',
    ];

    let isComplete: boolean = true;
    fields.forEach((key: AdjustmentFormKeys) => {
      if (adjustmentFormData[key].value === '') {
        isComplete = false;
      }
    });
    return isComplete;
  };

  const checkCreditInfoComplete = () => {
    let fields: CreditInfoFormKeys[] = [
      'creditType',
      'reason',
      'creditComments',
    ];

    let isComplete: boolean = true;
    fields.forEach((key: CreditInfoFormKeys) => {
      if (creditInfoFormData[key].value === '') {
        isComplete = false;
      }
    });
    return isComplete;
  };

  const checkCargoInfoComplete = () => {
    let fields: CargoInfoFormKeys[] = [
      'commodities',
      'averageValue',
      'maxExpectedValue',
      'modeOfTransportation',
    ];

    let isComplete: boolean = true;
    fields.forEach((key: CargoInfoFormKeys) => {
      if (cargoInfoFormData[key].value === '') {
        isComplete = false;
      }
    });
    return isComplete;
  };

  const handleOnCreateRequest = async () => {
    if (requestType === 'new') {
      checkNewAdjustmentErrors();
      checkCargoInfoErrors();

      if (!(checkNewAdjustmentComplete() && checkCargoInfoComplete())) {
        return;
      }

      try {
        toggleIsLoading(true);

        let data: NewCreditRequestEmailData = {
          customerId: customerInfoFormData.customerId.value,
          customerName: customerInfoFormData.customerName.value,
          collectorId: customerInfoFormData.collectorId.value,
          creditRateCode: customerInfoFormData.creditRateCode.value,
          bridge: customerInfoFormData.bridge.value,

          businessObtained: newAdjustmentFormData.businessObtained.value,
          singleLoadAmount: newAdjustmentFormData.singleLoadAmount.value,
          multipleLoadAmount: newAdjustmentFormData.multipleLoadAmount.value,

          contactName: newAdjustmentFormData.contactName.value,
          jobTitle: newAdjustmentFormData.jobTitle.value,
          faxNumber: newAdjustmentFormData.faxNumber.value,
          phoneNumber: newAdjustmentFormData.phoneNumber.value,
          email: newAdjustmentFormData.email.value,
          businessObtainedAdditional:
            newAdjustmentFormData.businessObtainedAdditional.value,
          immediateCreditNeed: newAdjustmentFormData.immediateCreditNeed.value,
          potentialLongtermBusiness:
            newAdjustmentFormData.potentialLongtermBusiness.value,
          comments: newAdjustmentFormData.comments.value,

          commodities: cargoInfoFormData.commodities.value,
          averageValue: cargoInfoFormData.averageValue.value,
          maxExpectedValue: cargoInfoFormData.maxExpectedValue.value,
          modeOfTransportation: cargoInfoFormData.modeOfTransportation.value,
        };

        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.CLICK,
          FirebaseUtils.FirebaseModules.CREDIT_REQUEST,
          FirebaseUtils.FirebasePages.NEW_CREDIT_REQUEST,
          {
            description: 'New Request',
            customerId: customerInfoFormData.customerId.value,
            mcleodId: user?._id,
          },
        );

        postNewCreditRequestEmail(data);
        showSnackbar({
          message: `Your request was submitted`,
          type: 'success',
        });
      } catch (error) {
        console.log(error);
        showSnackbar({
          message: `There was an error. Try again later`,
          type: 'error',
        });
      }
    } else {
      checkAdjustmentErrors();
      checkCreditInfoErrors();
      checkCargoInfoErrors();

      if (
        !(
          checkAdjustmentComplete() &&
          checkCreditInfoComplete() &&
          checkCargoInfoComplete()
        )
      ) {
        return;
      }

      try {
        toggleIsLoading(true);

        let data: AdjustmentCreditRequestEmailData = {
          customerId: customerInfoFormData.customerId.value,
          customerName: customerInfoFormData.customerName.value,
          collectorId: customerInfoFormData.collectorId.value,
          creditRateCode: customerInfoFormData.creditRateCode.value,
          bridge: customerInfoFormData.bridge.value,

          singleLoadAmount: adjustmentFormData.singleLoadAmount.value,
          multipleLoadAmount: adjustmentFormData.multipleLoadAmount.value,
          currentCreditLimit: adjustmentFormData.currentCreditLimit.value,
          currentReceivables: adjustmentFormData.currentReceivables.value,
          numberOfAdditionalLoads:
            adjustmentFormData.numberOfAdditionalLoads.value,
          additionalCreditRequested:
            adjustmentFormData.additionalCreditRequested.value,

          creditType: creditInfoFormData.creditType.value,
          creditReason: creditInfoFormData.reason.value,
          creditComments: creditInfoFormData.creditComments.value,

          commodities: cargoInfoFormData.commodities.value,
          averageValue: cargoInfoFormData.averageValue.value,
          maxExpectedValue: cargoInfoFormData.maxExpectedValue.value,
          modeOfTransportation: cargoInfoFormData.modeOfTransportation.value,
        };

        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.CLICK,
          FirebaseUtils.FirebaseModules.CREDIT_REQUEST,
          FirebaseUtils.FirebasePages.ADJUSTMENT_CREDIT_REQUEST,
          {
            description: 'Adjustment',
            customerId: customerInfoFormData.customerId.value,
            mcleodId: user?._id,
          },
        );

        postAdjustmentCreditRequestEmail(data);

        showSnackbar({
          message: `Your request was submitted`,
          type: 'success',
        });
      } catch (error) {
        console.log(error);
        showSnackbar({
          message: `There was an error. Try again later`,
          type: 'error',
        });
      }
    }
    toggleIsLoading(false);
    toggleIsPanelOpen(false);
  };

  return (
    <>
      <ContentContainer>
        <PanelHeaderSection
          toggleIsPanelOpen={toggleIsPanelOpen}
          requestType={requestType}
        />
        <Content>
          <SectionHeader>CUSTOMER INFORMATION</SectionHeader>
          <Details>
            <CustomerInfoForm
              formData={customerInfoFormData}
              setFormData={setCustomerInfoFormData}
            />
          </Details>
          {requestType === 'new' ? (
            <>
              <SectionHeader>
                PLEASE COMPLETE THE FOLLOWING FIELDS
              </SectionHeader>
              <Details>
                <NewAdjustmentForm
                  formData={newAdjustmentFormData}
                  setFormData={setNewAdjustmentFormData}
                />
              </Details>
            </>
          ) : (
            <>
              <SectionHeader>CHOOSE TYPE OF ADJUSTMENT</SectionHeader>
              <Details>
                <AdjustmentForm
                  formData={adjustmentFormData}
                  setFormData={setAdjustmentFormData}
                />
              </Details>
              <SectionHeader>CREDIT INFORMATION</SectionHeader>
              <Details>
                <CreditInfoForm
                  formData={creditInfoFormData}
                  setFormData={setCreditInfoFormData}
                />
              </Details>
            </>
          )}
          <SectionHeader>CARGO INFORMATION</SectionHeader>
          <Details>
            <CargoInfoForm
              formData={cargoInfoFormData}
              setFormData={setCargoInfoFormData}
            />
          </Details>
        </Content>
        <PanelFooterSection onSubmit={handleOnCreateRequest} />
      </ContentContainer>
    </>
  );
};
