// packages
import React, { useState, useContext } from 'react';
import { styled, AutoCompleteTextField } from 'shamrock-clover-ui';
import { useNavigate } from 'react-router';

// types
import { Quote, TypeStatus } from 'features/quoting/utils/quoteTypes';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import { Suggestions } from 'features/search/searchTypes';
import { getDisplayByStatus } from '../utils/quoteUtils';
import { useQuotingQueryParams } from 'hooks/useQueryParams';

type QuoteFields = {
  statusV2?: TypeStatus;
  orderId: string;
  bookItRequest: boolean;
};
export type QuoteSuggestion = Suggestions & QuoteFields;

// Hack, needs to go at some point.
const whitespace = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';

const StyledAutoCompleteTextField = styled(AutoCompleteTextField)`
  & div div,
  div div:hover {
    border-bottom: unset;
  }

  & div div div div div label {
    color: #999999 !important;
    top: 0px !important;
    font-size: 14px !important;
    padding: 10px 0px 0px 16px;
  }

  & div div div div div input {
    color: #333333 !important;
    padding-left: 16px;
    padding-top: 0px !important;
    height: 36px;
  }

  & div div div div div {
    padding: 0px;
  }
  border-radius: 20px;
  border: 1px solid #cccccc;
  height: 36px;
  width: 205px;
  font-family: proxima-nova;
  z-index: 9999;
  margin-right: 43px;
`;

const QuoteSearchInput = () => {
  const { setPathAndQuery } = useQuotingQueryParams();
  const navigate = useNavigate();
  const [isFocused, setFocus] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<QuoteSuggestion[]>([]);

  const { quotes: unfilteredQuotes, adminQuote } = useContext(
    QuotesEnhancementsContext,
  );

  const extendQuote = (item: Quote, status: TypeStatus): QuoteSuggestion => {
    return {
      ...item,
      name: `${item.orderId} ${whitespace} ${getDisplayByStatus(status)}`,
      statusV2: status,
      bookItRequest: item.quotes[0].bookItRequest,
    };
  };

  const handleOnSelectSuggestion = (suggestion: QuoteSuggestion) => {
    console.log(`handleOnSelectSuggestion: ${suggestion.name}`);
    setSearchValue('');
    setSuggestions([]);

    switch (suggestion.statusV2) {
      case 'new':
        const nextStatus = suggestion.bookItRequest
          ? 'negotiating'
          : 'verifying';
        adminQuote('', suggestion.orderId);
        setPathAndQuery(nextStatus, { orderId: suggestion.orderId });

        navigate(`/quotes/negotiating?orderId=${suggestion.orderId}`);
        break;
      case 'negotiating':
        navigate(`/quotes/negotiating?orderId=${suggestion.orderId}`);
        break;
      case 'verifying':
        navigate(`/quotes/verifying?orderId=${suggestion.orderId}`);
        break;
      case 'booked':
        navigate(`/quotes/booked?orderId=${suggestion.orderId}`);
        break;
      case 'dismissed':
        navigate(`/quotes/dismissed?orderId=${suggestion.orderId}`);
        break;
      default:
        console.log('Got to the default');
    }
  };

  const handleOnChangeSearchInput = ({
    target: { value },
  }: React.ChangeEvent<{ value: string }>) => {
    console.log(`handleOnChangeSearchInput: ${value}`);
    if (value === '') {
      console.log('Searching with empty value');
      setSearchValue('');
      setSuggestions([]);
    }
    setSearchValue(value);

    if (value && value.length >= 3) {
      const newResults: QuoteSuggestion[] = unfilteredQuotes.new
        .filter((x) => {
          return x.orderId.includes(value);
        })
        .map((item) => {
          return extendQuote(item, 'new');
        });

      const negotiatingResults: QuoteSuggestion[] = unfilteredQuotes.negotiating
        .filter((x) => {
          return x.orderId.includes(value);
        })
        .map((item) => {
          return extendQuote(item, 'negotiating');
        });

      const verifyingResults: QuoteSuggestion[] = unfilteredQuotes.verifying
        .filter((x) => {
          return x.orderId.includes(value);
        })
        .map((item) => {
          return extendQuote(item, 'verifying');
        });

      const bookedResults: QuoteSuggestion[] = unfilteredQuotes.booked
        .filter((x) => {
          return x.orderId.includes(value);
        })
        .map((item) => {
          return extendQuote(item, 'booked');
        });

      const dismissedResults: QuoteSuggestion[] = unfilteredQuotes.dismissed
        .filter((x) => {
          return x.orderId.includes(value);
        })
        .map((item) => {
          return extendQuote(item, 'dismissed');
        });

      setSuggestions([
        ...newResults,
        ...negotiatingResults,
        ...verifyingResults,
        ...bookedResults,
        ...dismissedResults,
      ] as QuoteSuggestion[]);
    }
  };
  return (
    <>
      <StyledAutoCompleteTextField
        className="searchBar"
        label={!isFocused && !searchValue ? 'Search by order number' : ''}
        minCharCount={3}
        onBlur={() => setFocus(false)}
        onChange={handleOnChangeSearchInput}
        onFocus={() => setFocus(true)}
        selectSuggestion={(_, { suggestion }) =>
          handleOnSelectSuggestion(suggestion)
        }
        suggestions={suggestions}
        value={searchValue}
      />
    </>
  );
};

export default QuoteSearchInput;
