import { RateAdjustmentApiResult } from 'features/rateProAdmin/rateAdminTypes';
import { TrailerType } from '../ratesTypes';

export enum FlatbedDatRateValues {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum RtsAdjusmentValues {
  LOWER = 'LOWER',
  HIGHER = 'HIGHER',
}

export type RegionAdjustementByEquipmentType = {
  numberOfLoads: number;
  rtsAdjustmentHighLow: RtsAdjusmentValues;
  datAdjustmentHighLow: FlatbedDatRateValues | undefined;
  regionAdjustedLowSonarScoreBeginning: number;
  regionAdjustedLowSonarScoreEnd: number;
  regionAdjustedAverageSonarScoreBeginning: number;
  regionAdjustedAverageSonarScoreEnd: number;
  regionAdjustedHighSonarScoreBeginning: number;
  regionAdjustedHighSonarScoreEnd: number;
};

export const getSonarSuggestedBuyRate = (
  regionAdjustmentByType: RegionAdjustementByEquipmentType,
  sonarScore: number,
  rateViewAverageWithFuel: number,
  rateViewHighWithFuel: number,
  rateViewLowWithFuel: number,
): number => {
  if (
    sonarScore >= regionAdjustmentByType.regionAdjustedLowSonarScoreBeginning &&
    sonarScore <= regionAdjustmentByType.regionAdjustedLowSonarScoreEnd
  ) {
    return rateViewLowWithFuel;
  }

  if (
    sonarScore >=
      regionAdjustmentByType.regionAdjustedAverageSonarScoreBeginning &&
    sonarScore <= regionAdjustmentByType.regionAdjustedAverageSonarScoreEnd
  ) {
    return rateViewAverageWithFuel;
  }

  if (
    sonarScore >=
      regionAdjustmentByType.regionAdjustedHighSonarScoreBeginning &&
    sonarScore <= regionAdjustmentByType.regionAdjustedHighSonarScoreEnd
  ) {
    return rateViewHighWithFuel;
  }

  return rateViewAverageWithFuel;
};

export const adjustRateForRtsHistory = (
  sonarRate: number,
  regionAdjustmentByType: RegionAdjustementByEquipmentType,
  rtsHistoryRateAverage: number,
  rtsHistoryLoads: number,
): number => {
  if (rtsHistoryLoads < regionAdjustmentByType.numberOfLoads) {
    return sonarRate;
  } else {
    const higherValue = Math.max(sonarRate, rtsHistoryRateAverage);
    const lowerValue = Math.min(sonarRate, rtsHistoryRateAverage);

    // TODO: need to convert this to enum so this is a valid if / else
    return regionAdjustmentByType.rtsAdjustmentHighLow === 'HIGHER'
      ? higherValue
      : lowerValue;
  }
};

export const regionAdjustmentByEquipmentType = (
  regionAdjustment: RateAdjustmentApiResult,
  equipmentType: TrailerType,
): RegionAdjustementByEquipmentType => {
  if (equipmentType.toUpperCase() === TrailerType.VAN) {
    const vanValues: RegionAdjustementByEquipmentType = {
      numberOfLoads: parseFloat(regionAdjustment.vanNumberOfLoads),
      rtsAdjustmentHighLow:
        regionAdjustment.vanRtsRate.toUpperCase() as RtsAdjusmentValues,
      datAdjustmentHighLow: undefined,
      regionAdjustedLowSonarScoreBeginning: parseFloat(
        regionAdjustment.lowVanSonarBeginning,
      ),
      regionAdjustedLowSonarScoreEnd: parseFloat(
        regionAdjustment.lowVanSonarEnd,
      ),
      regionAdjustedAverageSonarScoreBeginning: parseFloat(
        regionAdjustment.averageVanSonarBeginning,
      ),
      regionAdjustedAverageSonarScoreEnd: parseFloat(
        regionAdjustment.averageVanSonarEnd,
      ),
      regionAdjustedHighSonarScoreBeginning: parseFloat(
        regionAdjustment.highVanSonarBeginning,
      ),
      regionAdjustedHighSonarScoreEnd: parseFloat(
        regionAdjustment.highVanSonarEnd,
      ),
    };

    return vanValues;
  } else if (equipmentType.toUpperCase() === TrailerType.REEFER) {
    return {
      numberOfLoads: parseFloat(regionAdjustment.reeferNumberOfLoads),
      rtsAdjustmentHighLow:
        regionAdjustment.reeferRtsRate.toUpperCase() as RtsAdjusmentValues,
      datAdjustmentHighLow: undefined,
      regionAdjustedLowSonarScoreBeginning: parseFloat(
        regionAdjustment.lowReeferSonarBeginning,
      ),
      regionAdjustedLowSonarScoreEnd: parseFloat(
        regionAdjustment.lowReeferSonarEnd,
      ),
      regionAdjustedAverageSonarScoreBeginning: parseFloat(
        regionAdjustment.averageReeferSonarBeginning,
      ),
      regionAdjustedAverageSonarScoreEnd: parseFloat(
        regionAdjustment.averageReeferSonarEnd,
      ),
      regionAdjustedHighSonarScoreBeginning: parseFloat(
        regionAdjustment.highReeferSonarBeginning,
      ),
      regionAdjustedHighSonarScoreEnd: parseFloat(
        regionAdjustment.highReeferSonarEnd,
      ),
    };
  } else {
    return {
      numberOfLoads: parseFloat(regionAdjustment.flatbedNumberOfLoads),
      rtsAdjustmentHighLow:
        regionAdjustment.flatbedRtsRate?.toUpperCase() as RtsAdjusmentValues,
      datAdjustmentHighLow:
        regionAdjustment.flatbedDatRate?.toUpperCase() as FlatbedDatRateValues,
      regionAdjustedLowSonarScoreBeginning: 0,
      regionAdjustedLowSonarScoreEnd: 0,
      regionAdjustedAverageSonarScoreBeginning: 0,
      regionAdjustedAverageSonarScoreEnd: 0,
      regionAdjustedHighSonarScoreBeginning: 0,
      regionAdjustedHighSonarScoreEnd: 0,
    };
  }
};
