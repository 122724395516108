import * as React from 'react';
import {
  SingleSelectMenu,
  styled,
  Dialog,
  MultilineTextInput,
} from 'shamrock-clover-ui';
import { Button } from 'shamrock-clover-ui';
import SpinnerWithWait from 'components/Spinner';
import { Flex } from 'components/Flex';
import * as FirebaseUtils from '../../../utils/firebaseUtils';
import { QuoteHistoryItem } from '../utils/quoteTypes';
import { useContext } from 'react';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import { useQuotingQueryParams } from 'hooks/useQueryParams';
import { getRelevantHistoryItem, getTimerDisplay } from '../utils/quoteUtils';
import useCurrentLocation from '../hooks/useCurrentLocation';
import useTimer from '../hooks/useTimer';
import { useBookedLoadsAlertFeatureFlag } from 'hooks/useFeatureFlag';

export const HelperText = styled.div`
  font-family: proxima-nova, sans-serif;
  margin: 5px 0px 0px 10px;
  font-size: 0.75em;
  color: #666666;
  background-color: transparent;
  margin-bottom: 1em;
`;

interface DeclineButtonProps {
  orderId: string;
  buttonText: 'Decline' | 'Mark as Declined' | 'Mark as Dismissed' | 'Remove';
  carrierUnavailable?: boolean;
  carrierName?: string;
  mcleodId: string;
  history: QuoteHistoryItem[];
  userId: string;
}

const QuotingDeclineButton: React.FC<
  React.PropsWithChildren<DeclineButtonProps>
> = ({
  orderId,
  buttonText,
  carrierUnavailable,
  carrierName,
  mcleodId,
  history,
  userId,
}) => {
  const { isBookedLoadsAlertEnabled } = useBookedLoadsAlertFeatureFlag();

  const { expired } = useTimer(history, isBookedLoadsAlertEnabled ? 10 : 15);

  const { declinedQuote, getStatusFromQuoteContext, adminAssing } = useContext(
    QuotesEnhancementsContext,
  );
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [comment, setComment] = React.useState<string>('');
  const [isDeclining, setIsDeclining] = React.useState<boolean>(false);

  const { location } = useCurrentLocation();

  const { setPathAndQuery } = useQuotingQueryParams();

  const onClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const logDeclineEvent = () => {
    if (buttonText === 'Remove') {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.REMOVE,
        FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
        FirebaseUtils.FirebasePages.DETAILS,
        {
          orderNumber: orderId,
          status: getStatusFromQuoteContext(orderId),
          timer: getTimerDisplay(history),
          description: 'Remove Quote',
          adminUser: adminAssing,
          carrierName: carrierName,
          mcleodId: mcleodId,
        },
      );
    } else {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.DECLINE,
        FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
        FirebaseUtils.FirebasePages.DETAILS,
        {
          orderNumber: orderId,
          status: getStatusFromQuoteContext(orderId),
          adminUser: adminAssing,
          quoteAmount: getRelevantHistoryItem(history)?.amount,
          carrierName: carrierName,
          mcleodId: mcleodId,
          description: 'Decline Quote',
          timer: getTimerDisplay(history),
        },
      );
    }
  };

  const onMenuItemClick = async (option?: any) => {
    const { key } = option;
    if (key === 'declined') {
      logDeclineEvent();
      setIsDeclining(true);
      if (carrierUnavailable) {
        declinedQuote({
          userId,
          orderId,
          doNotSendNotification: false,
          comment: expired ? 'Expired by Carrier' : 'Carrier unavailable',
        });
        setIsDeclining(false);
        setPathAndQuery(location);
      } else {
        declinedQuote({
          userId,
          orderId,
          doNotSendNotification: false,
          comment: expired ? 'Expired by Carrier' : '',
        });
        setIsDeclining(false);
        setPathAndQuery(location);
      }
    }
    if (key === 'mark') {
      setShowDialog(true);
    }
  };

  const declineWithComment = async () => {
    setIsDeclining(true);
    logDeclineEvent();
    declinedQuote({ userId, orderId, doNotSendNotification: true, comment });
    setIsDeclining(false);
    setPathAndQuery(location);
  };

  return (
    <>
      <div onClick={onClick}>
        {!isDeclining ? (
          isBookedLoadsAlertEnabled &&
          history[history.length - 1].isInternalUser &&
          history[history.length - 1].status === 'approved' ? (
            <Button
              width="200px"
              color="red"
              buttonStyle="outlined"
              onClick={() =>
                onMenuItemClick({ key: 'declined', optionName: 'Decline' })
              }
            >
              Mark as Declined
            </Button>
          ) : expired && history[history.length - 1].isInternalUser ? (
            <Button
              width="200px"
              color="red"
              buttonStyle="outlined"
              onClick={() =>
                onMenuItemClick({ key: 'declined', optionName: 'Decline' })
              }
            >
              Remove
            </Button>
          ) : buttonText === 'Decline' ? (
            <SingleSelectMenu
              VisualElement={
                <Button width="200px" color="red" buttonStyle="outlined">
                  {buttonText}
                </Button>
              }
              selectedOption={undefined}
              onOptionSelected={(option) => onMenuItemClick(option)}
              menuOptions={[
                { key: 'declined', optionName: 'Decline' },
                { key: 'mark', optionName: 'Decline With Comment' },
              ]}
              menuLocation="below"
              fontSize="14"
            />
          ) : (
            <Button
              width="200px"
              color="red"
              buttonStyle="outlined"
              onClick={() =>
                onMenuItemClick({ key: 'declined', optionName: 'Decline' })
              }
            >
              {buttonText}
            </Button>
          )
        ) : (
          <Button width="200px" color="red" buttonStyle="outlined">
            {buttonText}
            <Flex padding="0px 0px 0px 10px">
              <SpinnerWithWait
                height="unset"
                width="unset"
                size="15"
                useWait={false}
              />
            </Flex>
          </Button>
        )}
      </div>
      {showDialog && (
        <Dialog
          open={showDialog}
          title="Decline Offer"
          content={`The carrier will be able to view these comments once you submit.`}
          onClose={() => setShowDialog(false)}
          width="472px"
          actions={
            <>
              <MultilineTextInput
                label="Comment"
                value={comment}
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {
                  if (value.length < 110) {
                    setComment(value);
                  }
                }}
              />
              <HelperText>110 Character Limit</HelperText>
              <Flex justifyContent="space-between" margin="25px 0px 0px 0px">
                <Button
                  width="175px"
                  buttonStyle="outlined"
                  onClick={() => setShowDialog(false)}
                >
                  Cancel
                </Button>
                <Button width="175px" onClick={declineWithComment}>
                  Submit
                </Button>
              </Flex>
            </>
          }
        />
      )}
    </>
  );
};

export default QuotingDeclineButton;
