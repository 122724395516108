import {
  format,
  isToday,
  isTomorrow,
  isSameDay,
  addMinutes,
  isAfter,
} from 'date-fns';

export const monthDayTimeFormat = (value: string) => {
  const date = dateWithoutTimezoneOffset(value);
  if (isToday(date)) {
    return `Today ${format(date, 'HH:mm')}`;
  } else if (isTomorrow(date)) {
    return `Tomorrow ${format(date, 'HH:mm')}`;
  }
  return format(date, 'MM/dd HH:mm');
};

export const timeFormat = (value: string, firstDate: string) => {
  return isSameDay(new Date(value), new Date(firstDate))
    ? formatWithoutTimezoneOffset(new Date(value), 'HH:mm')
    : monthDayTimeFormat(value);
};

export const formatWithoutTimezoneOffset = (
  date: Date,
  stringFormat: string,
) => {
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const dateWithoutOffset = new Date(date.getTime() + userTimezoneOffset);
  return format(dateWithoutOffset, stringFormat);
};

export const formatWithTimezone = (value: string) => {
  let date = new Date(value);
  if (isToday(date)) {
    return `Today ${format(date, 'HH:mm')}`;
  } else if (isTomorrow(date)) {
    return `Tomorrow ${format(date, 'HH:mm')}`;
  }
  return format(new Date(value), 'MM/dd HH:mm');
};

export const isFifteenMinutesPastTime = (value: string, setTimer?: number) => {
  const date = new Date(value);
  const now = new Date();

  return isAfter(now, addMinutes(date, setTimer || 15));
};

const dateWithoutTimezoneOffset = (value: string) => {
  const date = new Date(value);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const formatDate = (date: string) => {
  const dateFormat = new Date(date);

  const month = dateFormat.getMonth() + 1;
  const day = dateFormat.getDate();
  const year = dateFormat.getFullYear();

  let hours = dateFormat.getHours();
  const minutes = dateFormat.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;

  return `${month}/${day}/${year}, ${hours}:${minutes} ${ampm}`;
};
