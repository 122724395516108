import { Quote, QuoteData, QuoteHistoryItem } from "./quoteTypes";
import http from "utils/httpUtils";
import { API_BASE_URL } from "utils/constants";

export const getQuotes = http.get<Quote[]>(`${API_BASE_URL}/admin/v2/quotes`);

export const cancelQuote = http.post<QuoteHistoryItem, { orderId: string; userId: string; comment?: string }>(`${API_BASE_URL}/admin/cancelQuote`);

export const acceptQuote = http.post<QuoteHistoryItem, { userId: string; orderId: string }>(`${API_BASE_URL}/admin/acceptQuote`);

export const counterQuote = http.post<QuoteHistoryItem, { userId: string; orderId: string; amount: number; comment?: string }>(
  `${API_BASE_URL}/admin/counterQuote`
);

export const declineQuote = http.post<
  QuoteHistoryItem,
  {
    userId: string;
    orderId: string;
    doNotSendNotification: boolean;
    comment?: string;
  }
>(`${API_BASE_URL}/admin/declineQuote`);

export const markAsComplete = http.post<QuoteHistoryItem, { userId: string; orderId: string }>(`${API_BASE_URL}/admin/v2/complete-quote`);

export const discardQuote = http.post<QuoteHistoryItem, { orderId: string }>(`${API_BASE_URL}/discardQuote`);

export const setAdminQuote = http.put<QuoteData[], { orderId: string }>(`${API_BASE_URL}/admin/v2/assing-admin-user`);
