import * as React from 'react';
import { styled } from 'shamrock-clover-ui';

const LinearProgressContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 2px;
  width: 100%;
`;

const LinearProgressBar1 = styled.div`
  background-color: ${(props) => props.theme.blue};
  transition: transform 0.2s linear;
  transform-origin: left;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  width: auto;
  animation: animate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;

  @keyframes animate1 {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }
`;

const LinearProgressBar2 = styled.div`
  background-color: ${(props) => props.theme.blue};
  width: auto;
  transition: transform 0.2s linear;
  transform-origin: left;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  animation: animate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 0.5s;

  @keyframes animate2 {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
`;

export const LinearProgress: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <LinearProgressContainer>
      <LinearProgressBar1 />
      <LinearProgressBar2 />
    </LinearProgressContainer>
  );
};
