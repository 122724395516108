import * as React from 'react';
import { useQuotingQueryParams } from 'hooks/useQueryParams';
import { Icon as CloverIcon } from 'shamrock-clover-ui';
import {
  ExpandableCardWithChildren as ExpandableCard,
  GlobalNotificationWithChildren as GlobalNotification,
} from 'utils/clover';
import { Flex, FlexItem } from 'components/Flex';
import QuotingCounterInput from './QuotingCounterInput';
import QuotingAcceptButton from './QuotingAcceptButton';
import QuotingDeclineButton from './QuotingDeclineButton';
import QuotingAcceptedButton from './QuotingAcceptedButton';
import QuotingMarkAsComplete from './QuotingMarkAsComplete';
import { Quote } from '../utils/quoteTypes';
import Icon from 'components/Icon';
import {
  monthDayTimeFormat,
  timeFormat,
  formatWithTimezone,
} from 'utils/dateUtils';
import {
  isBookItNow,
  isExpired,
  isCarrierDeclined,
  isDeclined,
  isAdminApproved,
  isCarrierApproved,
  isAdminCountered,
} from '../utils/quoteUtils';
import * as DrawerStyles from '../styledComponents/QutotinDrawerStyles';
import PreviouslyOfferEnhancements from './PreviouslyOfferEnhancements';
import { useBookedLoadsAlertFeatureFlag } from 'hooks/useFeatureFlag';
import useSnackbar from 'hooks/useSnackbar';

const brokerageStatusMap = new Map(
  Object.entries({
    AVL: 'Available',
    AVLHOT: 'Available Load - Must Move',
    AVLS: 'Available Subject Load',
    AVLSHOT: 'Available Subject and Hot',
    BKD: 'Booked / Pending Dispatch',
    DEL: 'Delivered',
    DROP: 'Dropped Trailer',
    HOLD: 'Order on Hold',
    LOADED: 'Loaded',
    MISSED: 'Tractor was missed',
    OFFTRKNG: 'Offer Tracking - Projects',
    RC_ACPT: 'Rate Confirmation Accepted',
    RC_DCL: 'Rate Confirmation Declined',
    RC_EXP: 'Rate Confirmation Expired',
    RC_RCVD: 'Rate Confirmation Received',
    RC_SENT: 'Rate Confirmation Sent',
    RESENT: 'Rate Confirmation Resent',
    TOLOAD: 'Dispatched to Load',
  }),
);

const CommentTypeMap = new Map(
  Object.entries({
    DC: 'Dispatch Comment',
    OC: 'Other Comment',
    HC: 'Hot Comment',
    BC: 'Billing Comment',
    IB: 'Internal Billing Comment',
  }),
);

const formatComments = (
  comments: { comment: string; commentType: string }[],
) => {
  if (comments.length === 0) {
    return '';
  }
  return comments.reduce((commentToReturn, { comment, commentType }, i) => {
    // first one just return the correct text
    if (i === 0) {
      return `${CommentTypeMap.get(commentType) || ''}\n${comment}`;
    }

    const firstLetter = comment[0];
    // if first letter is uppercase append new line
    if (firstLetter === firstLetter.toUpperCase()) {
      return `${commentToReturn}\n\n${
        CommentTypeMap.get(commentType) || ''
      }\n${comment}`;
    }

    return `${commentToReturn}${comment}`;
  }, '');
};

type Props = {
  quote?: Quote;
};

const Drawer = ({ quote }: Props) => {
  const stopLength = quote?.stops.length || 1;
  const orderId = quote?.orderId || '';
  const totalCharge = quote?.totalCharge;

  const { addQueryParams } = useQuotingQueryParams();
  const { showSnackbar } = useSnackbar();

  const [expanded, setExpanded] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(1);
  const [activePage, setActivePage] = React.useState(1);
  const [totalPages, setTotalPage] = React.useState(stopLength);
  const [isCopied, setIsCopied] = React.useState(false);

  const { isBookedLoadsAlertEnabled } = useBookedLoadsAlertFeatureFlag();

  const setTimer = isBookedLoadsAlertEnabled ? 10 : 15;

  React.useEffect(() => {
    setTotalPage(stopLength);
  }, [stopLength]);

  React.useEffect(() => {
    setActivePage(1);
  }, [orderId]);

  const closeDrawer = () => {
    addQueryParams({ orderId: undefined });
  };

  const Tabs = [
    { title: 'Load Board', onClick: () => setActiveTab(1) },
    { title: 'Order', onClick: () => setActiveTab(2) },
    { title: 'Stop', onClick: () => setActiveTab(3) },
  ];

  if (!quote) {
    return (
      <>
        <Icon
          alt="right arrow"
          name="RightArrowGrey"
          padding="0px 0px 30px 0px"
          height={110}
        />
        <DrawerStyles.NoQuoteSelectedText>
          No Quote Selected
        </DrawerStyles.NoQuoteSelectedText>
      </>
    );
  }

  // onClick handler function for the copy button
  const handleCopyClick = async (text: string, withSnackbar: boolean) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    }
    if (withSnackbar)
      showSnackbar({
        message: `Copied to Clipboard`,
        type: 'success',
        duration: 1000,
      });
    else {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };

  const hasBookItNowRequest = quote.quotes.some((x) => isBookItNow(x));

  return (
    <DrawerStyles.StyledDrawer
      anchor="right"
      open={quote !== undefined}
      onClose={closeDrawer}
      style={{ position: 'initial', zIndex: 0 }}
      variant="persistent"
    >
      <DrawerStyles.DrawerContainer>
        <DrawerStyles.EquipmentTypeContainer
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <Flex>
            <Flex
              width="100%"
              justifyContent="space-between"
              margin="0px 24px 0px 0px"
            >
              <FlexItem>
                <Flex width="100%" flexDirection="column">
                  <DrawerStyles.HeaderText>
                    ORDER&nbsp;
                    <DrawerStyles.HeaderCopyText
                      onClick={() => {
                        handleCopyClick(quote.orderId, true);
                      }}
                    >
                      {quote.orderId}
                    </DrawerStyles.HeaderCopyText>
                  </DrawerStyles.HeaderText>
                  <DrawerStyles.HeaderDetailText>
                    {quote.customerId} - {quote.customerName}
                  </DrawerStyles.HeaderDetailText>
                </Flex>
              </FlexItem>
              <FlexItem>
                <Flex width="100%" flexDirection="column">
                  <DrawerStyles.HeaderText>
                    {quote.equipmentTypeDescription}
                  </DrawerStyles.HeaderText>
                  <DrawerStyles.HeaderDetailText>
                    Equipment Type
                  </DrawerStyles.HeaderDetailText>
                </Flex>
              </FlexItem>
              <FlexItem>
                <Flex width="100%" flexDirection="column">
                  <DrawerStyles.HeaderText>
                    ${quote.loadboardPrice?.toLocaleString()} Price
                  </DrawerStyles.HeaderText>

                  <DrawerStyles.HeaderDetailText>
                    {quote.maxPayRate
                      ? `$${quote.maxPayRate?.toLocaleString()} Max Pay`
                      : '- Max Pay'}
                  </DrawerStyles.HeaderDetailText>
                  <DrawerStyles.HeaderDetailText>
                    ${quote.totalCharge?.toLocaleString()} Customer Rate
                  </DrawerStyles.HeaderDetailText>
                </Flex>
              </FlexItem>
            </Flex>
            <Icon onClick={closeDrawer} name="XBlack" alt="close icon" />
          </Flex>
          <DrawerStyles.BrokerageStatusText>
            {brokerageStatusMap.get(quote.brokerageStatus || '')}
          </DrawerStyles.BrokerageStatusText>
        </DrawerStyles.EquipmentTypeContainer>
        <DrawerStyles.OrderContainer
          flexDirection="column"
          padding="24px 32px 24px 32px"
        >
          {quote.onHold && (
            <GlobalNotification variant="error" open={true}>
              <DrawerStyles.OnHoldText>
                On Hold: {quote.holdReason}
              </DrawerStyles.OnHoldText>
            </GlobalNotification>
          )}
          <Flex padding="16px 0px">
            <DrawerStyles.RightBorderContainer flexDirection="column" flex={1}>
              {quote.stops.map((x, i) => {
                return (
                  <Flex key={i}>
                    <Icon
                      height={35}
                      width={35}
                      alt="pickup icon"
                      name={x.stopType === 'PU' ? 'PickupGrey' : 'DeliveryGrey'}
                      margin="4px 16px 0px 0px"
                    />
                    <Flex flexDirection="column" margin="0px 0px 8px 0px">
                      <DrawerStyles.StopPrimaryText>
                        {x.city}, {x.state} {x.zip}
                      </DrawerStyles.StopPrimaryText>
                      <DrawerStyles.StopSecondaryText>
                        {monthDayTimeFormat(x.scheduledArriveEarly)}
                        {x.scheduledArriveLate
                          ? ` - ${timeFormat(
                              x.scheduledArriveLate,
                              x.scheduledArriveEarly,
                            )}`
                          : ''}
                        <DrawerStyles.StopAppointmentText>
                          {x.appointmentRequired === 'Y' &&
                            'Appointment Required'}
                        </DrawerStyles.StopAppointmentText>
                      </DrawerStyles.StopSecondaryText>
                    </Flex>
                  </Flex>
                );
              })}
              <Flex flexDirection="column" margin="26px 0px 0px 0px">
                <Flex margin="0px 0px 6px 0px">
                  <DrawerStyles.StopSecondaryText width="140px">
                    Weight
                  </DrawerStyles.StopSecondaryText>
                  <DrawerStyles.StopSecondaryText>
                    {quote.weight ? quote.weight.toLocaleString() : ''}
                  </DrawerStyles.StopSecondaryText>
                </Flex>
                <Flex>
                  <DrawerStyles.StopSecondaryText width="140px">
                    Commodity
                  </DrawerStyles.StopSecondaryText>
                  <DrawerStyles.StopSecondaryText>
                    {quote.commodity}
                  </DrawerStyles.StopSecondaryText>
                </Flex>
              </Flex>
            </DrawerStyles.RightBorderContainer>
            <FlexItem flexDirection="column" flex={1}>
              <FlexItem
                flex={2}
                flexDirection="column"
                padding="0px 0px 16px 16px"
              >
                <DrawerStyles.StopPrimaryText>
                  Planning Comments
                </DrawerStyles.StopPrimaryText>
                <DrawerStyles.StopSecondaryText>
                  {quote.planningComment}
                </DrawerStyles.StopSecondaryText>
              </FlexItem>
              <FlexItem flexDirection="column" padding="0px 0px 16px 16px">
                <DrawerStyles.StopPrimaryText>
                  SONAR
                </DrawerStyles.StopPrimaryText>
                <DrawerStyles.StopSecondaryText>
                  {quote.sonarScore}
                </DrawerStyles.StopSecondaryText>
              </FlexItem>
              <FlexItem flexDirection="column" padding="0px 0px 16px 16px">
                <DrawerStyles.StopPrimaryText>
                  ACCOUNT MANAGER
                </DrawerStyles.StopPrimaryText>
                <DrawerStyles.StopSecondaryText>
                  {quote.accountManagerName}
                </DrawerStyles.StopSecondaryText>
                <DrawerStyles.StopSecondaryText>
                  x {quote.accountManagerExtension}
                </DrawerStyles.StopSecondaryText>
              </FlexItem>
            </FlexItem>
          </Flex>
        </DrawerStyles.OrderContainer>
        <ExpandableCard
          expanded={expanded}
          onClick={() => setExpanded(!expanded)}
          width="100%"
          detailsHeight="fit-content"
        >
          <ExpandableCard.Summary>
            <Flex
              alignItems="center"
              height="100%"
              margin="0px 0px 0px 32px"
              cursor="pointer"
            >
              <DrawerStyles.HeaderText>More Notes</DrawerStyles.HeaderText>
            </Flex>
          </ExpandableCard.Summary>
          <ExpandableCard.Details>
            <DrawerStyles.StyledSecondaryNavigation
              tabs={Tabs}
            ></DrawerStyles.StyledSecondaryNavigation>
            <Flex padding="0px 56px">
              {activeTab === 1 && (
                <Flex flexDirection="column" width="100%">
                  <DrawerStyles.NotesTabBackground>
                    <DrawerStyles.CommentText margin="16px 20px">
                      {quote.loadBoardComment || 'No comments available'}
                    </DrawerStyles.CommentText>
                  </DrawerStyles.NotesTabBackground>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    margin="8px 0px 24px 0px"
                    height="20px"
                  >
                    <Flex
                      marginLeft="auto"
                      width="100px"
                      alignItems="center"
                      justifyContent="space-between"
                      cursor="pointer"
                      onClick={() =>
                        handleCopyClick(
                          quote.loadBoardComment || 'No comments available',
                          false,
                        )
                      }
                    >
                      <CloverIcon
                        icon="clipboard"
                        size="16"
                        onClick={() => {}}
                      ></CloverIcon>
                      <DrawerStyles.CopyNoteText>
                        {isCopied ? 'COPIED!' : 'COPY NOTE'}
                      </DrawerStyles.CopyNoteText>
                    </Flex>
                  </Flex>
                </Flex>
              )}
              {activeTab === 2 && (
                <Flex flexDirection="column" width="100%">
                  <DrawerStyles.NotesTabBackground>
                    <DrawerStyles.CommentText margin="16px 20px">
                      {quote.orderComment || 'No comments available'}
                    </DrawerStyles.CommentText>
                  </DrawerStyles.NotesTabBackground>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    margin="8px 0px 24px 0px"
                    height="20px"
                  >
                    <Flex
                      marginLeft="auto"
                      width="100px"
                      alignItems="center"
                      justifyContent="space-between"
                      cursor="pointer"
                      onClick={() =>
                        handleCopyClick(
                          quote.orderComment || 'No comments available',
                          false,
                        )
                      }
                    >
                      <CloverIcon
                        icon="clipboard"
                        size="16"
                        onClick={() => {}}
                      ></CloverIcon>
                      <DrawerStyles.CopyNoteText>
                        {isCopied ? 'COPIED!' : 'COPY NOTE'}
                      </DrawerStyles.CopyNoteText>
                    </Flex>
                  </Flex>
                </Flex>
              )}
              {activeTab === 3 && (
                <Flex flexDirection="column" width="100%">
                  <DrawerStyles.NotesTabBackground>
                    <Flex margin="0px 20px">
                      <DrawerStyles.StopCommentPrimaryText>
                        {quote.stops[activePage - 1].city},{' '}
                        {quote.stops[activePage - 1].state}{' '}
                        {quote.stops[activePage - 1].zip}{' '}
                      </DrawerStyles.StopCommentPrimaryText>
                      &nbsp;-&nbsp;
                      <DrawerStyles.StopCommentSecondaryText>
                        {monthDayTimeFormat(
                          quote.stops[activePage - 1].scheduledArriveEarly,
                        )}
                        {quote.stops[activePage - 1].scheduledArriveLate
                          ? ` - ${timeFormat(
                              quote.stops[activePage - 1].scheduledArriveLate,
                              quote.stops[activePage - 1].scheduledArriveEarly,
                            )}`
                          : ''}
                      </DrawerStyles.StopCommentSecondaryText>
                    </Flex>
                    <DrawerStyles.CommentText margin="8px 20px 16px 20px">
                      {formatComments(quote.stops[activePage - 1].comments) ||
                        'No comments available'}
                    </DrawerStyles.CommentText>
                  </DrawerStyles.NotesTabBackground>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    margin="8px 0px 24px 0px"
                  >
                    <Flex width="100px"></Flex>
                    <Flex alignItems="center">
                      <Flex margin="0px 24px 0px 0px">
                        {activePage} of {totalPages}
                      </Flex>
                      <Flex width="60px" justifyContent="space-between">
                        <DrawerStyles.StyledIcon
                          icon="chevronLeft"
                          size="14"
                          disabled={activePage === 1}
                          onClick={() => {
                            setActivePage((prevActivePage) => {
                              if (prevActivePage !== 1) {
                                return prevActivePage - 1;
                              }
                              return prevActivePage;
                            });
                          }}
                        ></DrawerStyles.StyledIcon>
                        <DrawerStyles.StyledIcon
                          icon="chevronRight"
                          size="14"
                          disabled={activePage === stopLength}
                          onClick={() => {
                            setActivePage((prevActivePage) => {
                              if (prevActivePage !== totalPages) {
                                return prevActivePage + 1;
                              }
                              return prevActivePage;
                            });
                          }}
                        ></DrawerStyles.StyledIcon>
                      </Flex>
                    </Flex>
                    <Flex
                      width="100px"
                      alignItems="center"
                      justifyContent="space-between"
                      cursor="pointer"
                      onClick={() =>
                        handleCopyClick(
                          formatComments(
                            quote.stops[activePage - 1].comments,
                          ) || 'No comments available',
                          false,
                        )
                      }
                    >
                      <CloverIcon
                        icon="clipboard"
                        size="16"
                        onClick={() => {}}
                      ></CloverIcon>
                      <DrawerStyles.CopyNoteText>
                        {isCopied ? 'COPIED!' : 'COPY NOTE'}
                      </DrawerStyles.CopyNoteText>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </ExpandableCard.Details>
        </ExpandableCard>
        <DrawerStyles.QuotesContainer flexDirection="column">
          {quote.quotes.map((x, i) => {
            const lastHistoryItem = x.history[x.history.length - 1];
            return (
              <DrawerStyles.QuoteContainer
                key={`${quote.orderId}_${x.userId}`}
                padding="16px 19px 24px 32px"
                height="auto"
              >
                <FlexItem flexDirection="column">
                  <Flex flexDirection="column" margin="0px 0px 0px 5px">
                    <div>
                      <Flex margin="0 0 15px 0">
                        <Icon
                          alt="grey delivery truck icon"
                          name="TruckGrey"
                          margin="0px 16px 0px 0px"
                          width={28}
                        ></Icon>
                        <DrawerStyles.StopPrimaryText>
                          {x.companyName}
                        </DrawerStyles.StopPrimaryText>
                      </Flex>
                    </div>
                    <div style={{ paddingLeft: '45px' }}>
                      <Flex margin="1px 0px">
                        <DrawerStyles.QuotePrimaryText>
                          McLeod ID
                        </DrawerStyles.QuotePrimaryText>
                        <DrawerStyles.QuoteSecondaryText>
                          <DrawerStyles.QuoteCopyText
                            onClick={() => {
                              handleCopyClick(x.mcleodId, true);
                            }}
                          >
                            {x.mcleodId}
                          </DrawerStyles.QuoteCopyText>
                        </DrawerStyles.QuoteSecondaryText>
                      </Flex>
                      <Flex margin="1px 0px">
                        <DrawerStyles.QuotePrimaryText>
                          MC
                        </DrawerStyles.QuotePrimaryText>
                        <DrawerStyles.QuoteSecondaryText>
                          <DrawerStyles.QuoteCopyText
                            onClick={() => {
                              handleCopyClick(
                                x.mcNumber && x.mcNumber.length > 0
                                  ? x.mcNumber
                                  : '######',
                                true,
                              );
                            }}
                          >
                            {x.mcNumber && x.mcNumber.length > 0
                              ? x.mcNumber
                              : '######'}
                          </DrawerStyles.QuoteCopyText>
                        </DrawerStyles.QuoteSecondaryText>
                      </Flex>
                      <Flex margin="1px 0px">
                        <DrawerStyles.QuotePrimaryText>
                          DOT
                        </DrawerStyles.QuotePrimaryText>
                        <DrawerStyles.QuoteSecondaryText>
                          <DrawerStyles.QuoteCopyText
                            onClick={() => {
                              handleCopyClick(
                                x.dotNumber && x.dotNumber.length > 0
                                  ? x.dotNumber
                                  : '#######',
                                true,
                              );
                            }}
                          >
                            {x.dotNumber && x.dotNumber.length > 0
                              ? x.dotNumber
                              : '#######'}
                          </DrawerStyles.QuoteCopyText>
                        </DrawerStyles.QuoteSecondaryText>
                      </Flex>
                      <Flex margin="1px 0px">
                        <DrawerStyles.QuotePrimaryText>
                          Carrier Status
                        </DrawerStyles.QuotePrimaryText>
                        <DrawerStyles.QuoteSecondaryText>
                          {x.carrierStatus ? x.carrierStatus : '---'}
                        </DrawerStyles.QuoteSecondaryText>
                      </Flex>
                      <Flex margin="1px 0px">
                        <DrawerStyles.QuotePrimaryText>
                          Contact Name
                        </DrawerStyles.QuotePrimaryText>
                        <DrawerStyles.QuoteSecondaryText>
                          {x.name}
                        </DrawerStyles.QuoteSecondaryText>
                      </Flex>
                      <Flex margin="1px 0px">
                        <DrawerStyles.QuotePrimaryText>
                          Phone Number
                        </DrawerStyles.QuotePrimaryText>
                        <DrawerStyles.QuoteSecondaryText>
                          <DrawerStyles.QuoteCopyText
                            onClick={() => {
                              handleCopyClick(x.phone, true);
                            }}
                          >
                            {x.phone}
                          </DrawerStyles.QuoteCopyText>
                        </DrawerStyles.QuoteSecondaryText>
                      </Flex>
                      <Flex margin="1px 0px">
                        <DrawerStyles.QuotePrimaryText>
                          Email
                        </DrawerStyles.QuotePrimaryText>
                        <DrawerStyles.QuoteSecondaryText>
                          <DrawerStyles.QuoteCopyText
                            onClick={() => {
                              handleCopyClick(x.email, true);
                            }}
                          >
                            {x.email}
                          </DrawerStyles.QuoteCopyText>
                        </DrawerStyles.QuoteSecondaryText>
                      </Flex>
                      <Flex margin="1px 0px">
                        <DrawerStyles.QuotePrimaryText>
                          Last Submitted
                        </DrawerStyles.QuotePrimaryText>
                        <DrawerStyles.QuoteSecondaryText>
                          {formatWithTimezone(lastHistoryItem.createdAt)}
                        </DrawerStyles.QuoteSecondaryText>
                      </Flex>
                    </div>
                  </Flex>
                </FlexItem>
                <PreviouslyOfferEnhancements
                  quoteData={x}
                  totalCharge={totalCharge!}
                />
                {lastHistoryItem.status === 'completed' ? null : (
                  <>
                    <FlexItem
                      flexDirection="column"
                      padding="0px 0px 0px 24px"
                      height="150px"
                      width="200px"
                      justifyContent="space-between"
                    >
                      {hasBookItNowRequest && !isBookItNow(x) ? (
                        <Flex></Flex>
                      ) : isCarrierDeclined(x.history) ? (
                        <DrawerStyles.TwoButtonContainer>
                          <QuotingMarkAsComplete
                            userId={x.userId}
                            orderId={quote.orderId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                            isBookNow={x.bookItRequest}
                          />
                          <QuotingDeclineButton
                            orderId={quote.orderId}
                            userId={x.userId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                            buttonText="Remove"
                          />
                        </DrawerStyles.TwoButtonContainer>
                      ) : isDeclined(x.history) ? (
                        <DrawerStyles.TwoButtonContainer>
                          <DrawerStyles.DeclinedText>
                            {isCarrierDeclined(x.history) && 'Carrier '}Declined
                          </DrawerStyles.DeclinedText>
                          <QuotingMarkAsComplete
                            userId={x.userId}
                            orderId={quote.orderId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                            isBookNow={x.bookItRequest}
                          />
                        </DrawerStyles.TwoButtonContainer>
                      ) : isExpired(x.history, setTimer) ? (
                        isBookedLoadsAlertEnabled &&
                        x.history[x.history.length - 1].status ===
                          'approved' ? (
                          <>
                            <QuotingAcceptedButton quote={quote} />
                            <QuotingMarkAsComplete
                              userId={x.userId}
                              orderId={quote.orderId}
                              history={x.history}
                              carrierName={x.companyName}
                              mcleodId={x.mcleodId}
                              isBookNow={x.bookItRequest}
                            />
                            <QuotingDeclineButton
                              orderId={quote.orderId}
                              userId={x.userId}
                              history={x.history}
                              carrierName={x.companyName}
                              mcleodId={x.mcleodId}
                              buttonText="Mark as Declined"
                            />
                          </>
                        ) : (
                          <DrawerStyles.TwoButtonContainer>
                            <QuotingMarkAsComplete
                              userId={x.userId}
                              orderId={quote.orderId}
                              history={x.history}
                              carrierName={x.companyName}
                              mcleodId={x.mcleodId}
                              isBookNow={x.bookItRequest}
                            />
                            <QuotingDeclineButton
                              orderId={quote.orderId}
                              userId={x.userId}
                              history={x.history}
                              carrierName={x.companyName}
                              mcleodId={x.mcleodId}
                              buttonText="Mark as Declined"
                            />
                          </DrawerStyles.TwoButtonContainer>
                        )
                      ) : isAdminApproved(x.history) ? (
                        isBookedLoadsAlertEnabled ? (
                          <>
                            <QuotingAcceptedButton quote={quote} />
                            <QuotingMarkAsComplete
                              userId={x.userId}
                              orderId={quote.orderId}
                              history={x.history}
                              carrierName={x.companyName}
                              mcleodId={x.mcleodId}
                              isBookNow={x.bookItRequest}
                            />
                            <QuotingDeclineButton
                              orderId={quote.orderId}
                              userId={x.userId}
                              history={x.history}
                              carrierName={x.companyName}
                              mcleodId={x.mcleodId}
                              buttonText="Mark as Declined"
                            />
                          </>
                        ) : (
                          <QuotingAcceptedButton quote={quote} />
                        )
                      ) : isCarrierApproved(x.history) || isBookItNow(x) ? (
                        <DrawerStyles.TwoButtonContainer>
                          <QuotingMarkAsComplete
                            userId={x.userId}
                            orderId={quote.orderId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                            isBookNow={x.bookItRequest}
                          />
                          <QuotingDeclineButton
                            orderId={quote.orderId}
                            userId={x.userId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                            buttonText="Mark as Declined"
                            carrierUnavailable={true}
                          />
                        </DrawerStyles.TwoButtonContainer>
                      ) : isAdminCountered(x.history) ? (
                        <DrawerStyles.TwoButtonContainer>
                          <QuotingAcceptButton
                            userId={x.userId}
                            orderId={quote.orderId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                          />
                          <QuotingDeclineButton
                            orderId={quote.orderId}
                            userId={x.userId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                            buttonText="Decline"
                          />
                        </DrawerStyles.TwoButtonContainer>
                      ) : (
                        <>
                          <QuotingAcceptButton
                            userId={x.userId}
                            orderId={quote.orderId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                          />
                          <QuotingCounterInput
                            userId={x.userId}
                            orderId={quote.orderId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                          />
                          <QuotingDeclineButton
                            orderId={quote.orderId}
                            userId={x.userId}
                            history={x.history}
                            carrierName={x.companyName}
                            mcleodId={x.mcleodId}
                            buttonText="Decline"
                          />
                        </>
                      )}
                    </FlexItem>
                  </>
                )}
              </DrawerStyles.QuoteContainer>
            );
          })}
        </DrawerStyles.QuotesContainer>
      </DrawerStyles.DrawerContainer>
    </DrawerStyles.StyledDrawer>
  );
};

export default Drawer;
