import { CreditInfoFormData } from '../creditRequestTypes';

// styles
import Grid from '@material-ui/core/Grid';
import {
  MultilineTextInputFilled,
  InputLabel,
  CreditInfoContainer,
} from './panelStyles';
import { SmrRadioButton } from 'shamrock-clover-ui';

// props
interface Props {
  formData: CreditInfoFormData;
  setFormData: Function;
}

export const CreditInfoForm = ({ formData, setFormData }: Props) => {
  const handleOnChangeForm = (
    name: string,
    value: string | null | undefined,
  ) => {
    setFormData({ ...formData, [name]: { value: value, errorMessage: '' } });
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setFormData({
        ...formData,
        [name]: { value: '', errorMessage: 'Required field' },
      });
    }
  };

  return (
    <CreditInfoContainer container>
      <Grid item sm={3}>
        <InputLabel>Type</InputLabel>
        <SmrRadioButton
          elements={[
            {
              label: 'Credit Increase',
              value: 'Credit Increase',
              checked: formData.creditType.value === 'Credit Increase',
            },
            {
              label: 'Credit Decrease',
              value: 'Credit Decrease',
              checked: formData.creditType.value === 'Credit Decrease',
            },
          ]}
          onChange={(value) => {
            handleOnChangeForm('creditType', value);
          }}
          marginBottom="15px"
          id="RadioButtonsType"
        />
      </Grid>
      <Grid item sm={4}>
        <InputLabel>Reason</InputLabel>
        <SmrRadioButton
          elements={[
            {
              label: 'New Business (We Solicited)',
              value: 'New Business (We Solicited)',
              checked: formData.reason.value === 'New Business (We Solicited)',
            },
            {
              label: 'New Business (Customer Solicited)',
              value: 'New Business (Customer Solicited)',
              checked:
                formData.reason.value === 'New Business (Customer Solicited)',
            },
            {
              label: 'Billing Issues',
              value: 'Billing Issues',
              checked: formData.reason.value === 'Billing Issues',
            },
            {
              label: 'Seasonal Business',
              value: 'Seasonal Business',
              checked: formData.reason.value === 'Seasonal Business',
            },
          ]}
          onChange={(value) => {
            handleOnChangeForm('reason', value);
          }}
          marginBottom="15px"
          id="RadioButtonsReason"
        />
      </Grid>
      <Grid item sm={5}>
        <InputLabel>Credit Comments</InputLabel>
        <MultilineTextInputFilled
          variant="filled"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('creditComments', value);
          }}
          value={formData.creditComments.value}
          onBlur={() => {
            handleOnBlurForm('creditComments', formData.creditComments.value);
          }}
          error={formData.creditComments.errorMessage}
        />
      </Grid>
    </CreditInfoContainer>
  );
};
