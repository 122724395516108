import { FC } from 'react';
import Carriers from './Carriers';
import SearchCapacity from './SearchCapacity';
import CarrierCapacityWrapper from './context/CarrierCapacityContext';
import { DeleteDialogWrapper } from './hooks/useDeleteDialog';

interface CapacityProps {
  view: 'carrier' | 'searchCapacity';
}

const Capacity: FC<CapacityProps> = ({ view }) => {
  return (
    <CarrierCapacityWrapper>
      <DeleteDialogWrapper>
        {view === 'carrier' ? <Carriers /> : <SearchCapacity />}
      </DeleteDialogWrapper>
    </CarrierCapacityWrapper>
  );
};

export default Capacity;
