import { Fragment } from 'react';
import { Button, styled } from 'shamrock-clover-ui';
import { useToggleBookItNowFeatureFlag } from '../hooks/useFeatureFlag';
import { updateFeatureFlag } from '../utils/featureUtils';

const StyledButton = styled(Button)`
  border-radius: 20px;
  font-family: proxima-nova;
  svg {
    fill: currentColor;
  }
  text-transform: capitalize;
`;

const BookItNowToggleButton = () => {
  const { isBookItNowToggleFeatureEnabled = false } =
    useToggleBookItNowFeatureFlag();

  return (
    <Fragment>
      <StyledButton
        height={'36px'}
        buttonStyle={isBookItNowToggleFeatureEnabled ? 'solid' : 'outlined'}
        onClick={() => {
          let status = isBookItNowToggleFeatureEnabled
            ? 'inactive'
            : 'always-active';
          updateFeatureFlag('RTD-1154-Mobile', status);
        }}
      >
        Book It Now: {isBookItNowToggleFeatureEnabled ? 'On' : 'Off'}
      </StyledButton>
    </Fragment>
  );
};

export default BookItNowToggleButton;
