import * as React from "react";

import {
  StyledEquipmentCard,
  StyledEquipmentCardHeader,
  StyleDatText,
  StyledEquipmentCardContainer,
  StyledSonarReeferFormControl,
  StyledMenuItem,
  SonarSeperator,
} from "../rateAdjustmentStylesV2";
import { useRateAdjustmentQueryParams } from "../../../hooks/useQueryParams";

import { Select } from "@material-ui/core";

const items = ["LOW", "AVERAGE", "HIGH"];

const FlatbedSonarCardV2 = () => {
  const { addQueryParams, queryParams } = useRateAdjustmentQueryParams();

  const sonar = queryParams.flatbedDatRate ? queryParams.flatbedDatRate : "";

  const getOptions = () => {
    return items.map((e) => <StyledMenuItem value={e}>{e}</StyledMenuItem>);
  };

  const onChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    addQueryParams({
      flatbedDatRate: event.target.value as string,
    });
  };

  return (
    <StyledEquipmentCard width="324px" height="156px">
      <StyledEquipmentCardHeader>
        <StyleDatText marginLeft="16px" fontWeight="400" marginTop="0px">
          DAT RATE
        </StyleDatText>
      </StyledEquipmentCardHeader>
      <StyledEquipmentCardContainer flexDirection="column">
        <StyledSonarReeferFormControl size="small">
          <Select
            id="sonar-simple-select"
            MenuProps={{ variant: "menu" }}
            onChange={onChange}
            value={sonar}
            disableUnderline
          >
            {getOptions()}
          </Select>
          <SonarSeperator width="97%" />
        </StyledSonarReeferFormControl>
      </StyledEquipmentCardContainer>
    </StyledEquipmentCard>
  );
};

export default FlatbedSonarCardV2;
