import {
  styled,
  SecondaryNavigation,
  Icon as CloverIcon,
} from 'shamrock-clover-ui';
import MaterialDrawer from '@material-ui/core/Drawer';
import { Flex, FlexItem } from 'components/Flex';

export const StyledDrawer = styled(MaterialDrawer)`
  .MuiBackdrop-root {
    background-color: unset;
  }
  .MuiPaper-root {
    z-index: 12011201;
    width: calc(100% - 633px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-top: 200px;
    overflow-y: auto;
    @media (max-width: 640px) {
      width: 100%;
      min-width: 100%;
      font-size: 12px;
    }
  }
`;

export const DrawerContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const StyledIcon = styled(CloverIcon)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => disabled && '50%'};
`;

export const OrderContainer = styled(Flex)`
  border-bottom: 1px solid #d8d8d8;
`;

export const RightBorderContainer = styled(FlexItem)`
  border-right: 1px solid #cdcdcd;
`;

export const StopPrimaryText = styled.div`
  color: #424242;
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: -0.07px;
  line-height: 20px;
`;

export const StopSecondaryText = styled.div<{ width?: string }>`
  color: #424242;
  font-size: ${({ theme }) => theme.font.size.small};
  letter-spacing: -0.07px;
  line-height: 20px;
  width: ${({ width }) => width && width};
`;

export const AdminUserText = styled(StopSecondaryText)`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const StopAppointmentText = styled.div`
  font-style: italic;
`;

export const QuotesContainer = styled(Flex)`
  overflow-y: auto;
  height: calc(100vh + 100px);
`;

export const QuoteContainer = styled(Flex)`
  border-bottom: 1px solid #d8d8d8;
`;

export const QuotePrimaryText = styled.div`
  width: 140px;
  color: #424242;
  font-size: 16px;
  letter-spacing: -0.07px;
  line-height: 20px;
`;

export const QuoteSecondaryText = styled.div`
  color: #424242;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: -0.07px;
  line-height: 20px;
`;

export const QuoteCopyText = styled.div`
  cursor: pointer;
  &:hover {
    background-color: #eaf7ff;
  }
`;

export const QuoteDisplayContainer = styled(Flex)`
  text-align: left;
  // height: 179px;
  overflow-y: auto;
  align-items: flex-start;
  padding-left: 35px;
  width: 75%;
  // margin: 0 auto;
  // border: 1px solid red;
  flex-direction: column;
`;

export const CounterText = styled(StopPrimaryText)`
  text-decoration: line-through;
`;

export const AdminCounterText = styled(CounterText)`
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const AdminPrimaryText = styled(StopPrimaryText)`
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const DeclinedText = styled.div`
  color: #ff0000;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

export const TwoButtonContainer = styled.div`
  width: 200px;
  display: flex;
  height: 95px;
  flex-direction: column;
  justify-content: space-between;
`;

export const EquipmentTypeContainer = styled(Flex)`
  border-bottom: 1px solid #d8d8d8;
  height: 118px;
  padding: 0px 25px 0px 25px;
`;

export const HeaderText = styled.div`
  font-size: ${({ theme }) => theme.font.size.medium};
  line-height: 22px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  height: 22px;
`;

export const HeaderCopyText = styled.span`
  cursor: pointer;
  &:hover {
    background-color: #eaf7ff;
  }
`;

export const BrokerageStatusText = styled(Flex)`
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 22px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  height: 22px;
`;

export const HeaderDetailText = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

export const NoQuoteSelectedText = styled.div`
  color: ${({ theme }) => theme.colors.gray[60]};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  letter-spacing: 0;
  line-height: 20px;
`;

export const Line = styled.div`
  height: 50px;
  width: 1px;
  background-color: #cccccc;
  position: relative;
  left: 20px;
`;

export const BookedText = styled.div`
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.blue[50]};
`;

export const Separator = styled.span`
  color: ${({ theme }) => theme.colors.gray[30]};
  padding: 0px 10px;
`;

export const OnHoldText = styled.span`
  color: ${({ theme }) => theme.colors.gray[90]};
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const StyledSecondaryNavigation = styled(SecondaryNavigation)`
  border-bottom: 0px;
  padding: 0px 46px;
`;

export const NotesTabBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  min-height: 150px;
  width: 100%;
`;

export const CopyNoteText = styled.div`
  color: ${({ theme }) => theme.colors.blue[50]};
  font-size: ${({ theme }) => theme.font.size.xsmall};
  margin-top: 2.5px;
  text-align: center;
  width: 80px;
`;

export const CommentText = styled(Flex)`
  white-space: pre-line;
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.colors.gray[90]};
`;

export const StopCommentPrimaryText = styled.div`
  color: ${({ theme }) => theme.colors.gray[90]};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: -0.07px;
  line-height: 20px;
`;

export const StopCommentSecondaryText = styled.div<{ width?: string }>`
  color: ${({ theme }) => theme.colors.gray[90]};
  font-size: ${({ theme }) => theme.font.size.small};
  letter-spacing: -0.07px;
  line-height: 20px;
  width: ${({ width }) => width && width};
`;

export const OfferedTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #0091ea;
  margin-bottom: 20px;
`;

export const OfferedHistoryTitle = styled('div')<{ color?: string }>`
  color: ${(props: { color?: string }) =>
    props.color ? props.color : '#888888'};
  font-size: 18px;
  letter-spacing: -0.07px;
  margin: 0;
`;

export const OffersAmounts = styled.div`
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.07px;
  margin: 0;
`;
