import { styled } from 'shamrock-clover-ui';
import { FormControl, withStyles } from '@material-ui/core';
import { Flex } from '../../../components/Flex';

export const LabelText = styled.div`
  padding-left: 16px;
  margin-bottom: 4px;
  font-size: ${({ theme }) => theme.font.size.xxsmall};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.colors.gray[70]};
  text-transform: uppercase;
`;

export const InputContainer = styled(Flex)<{ border?: boolean }>`
  width: 170px;
  height: 36px;
  background-color: white;
  border-radius: 20px;
  padding: 0px 15px;
  border-radius: 20px;
  border: ${({ border }) => (border ? '1px solid red' : 'none')};
`;

export const StyledFormControl = withStyles({
  root: {
    width: '170px',
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
  },
})(FormControl);