import { styled } from 'shamrock-clover-ui';

import {
  FormControl,
  MenuItem,
  withStyles,
  InputLabel,
} from '@material-ui/core';
import { Button } from 'shamrock-clover-ui';

export const Container = styled.div`
  height: calc(100vh - 100px);
  background-color: ${({ theme }) => theme.colors.gray[10]};
`;

export const GridContainer = styled.div<{ minWidth?: string }>`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  padding: 16px 64px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  align-items: center;
  min-width: ${({ minWidth }) => minWidth || '566px'};
`;

export const GridArea = styled.div<{ gridArea: string }>`
  grid-area: ${({ gridArea }) => gridArea};
`;

export const RegionTitleText = styled.div<{
  fontSize: string;
  height?: string;
  lineHeight?: string;
  width?: string;
  color?: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => (color ? color : '#333333')};
  height: ${({ height }) => height};
  line-height: ${({ lineHeight }) => lineHeight};
  width: ${({ width }) => width};
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  padding: 24px;
`;

export const CardContainer = styled.div<{
  flexDirection: string;
  justifyContent?: string;
}>`
  display: flex;
  flex: 1;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const StyledCard = styled.div<{
  height: string;
  topBorder?: string;
  borderBottom?: string;
  background?: string;
  flexDirection?: string;
  justifyContent?: string;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'column'};
  align-items: center;
  padding: 24px 0px 0px;
  gap: 24px;
  width: 100%;
  height: ${({ height }) => height};
  background: ${({ background }) => (background ? background : '#ffffff')};
  border-top: ${({ topBorder }) => topBorder};
  border-bottom: ${({ borderBottom }) => borderBottom};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Proxima Nova',
    fontSize: 16,
    color: '#333333',
  },
})(MenuItem);

export const StyledFormControl = withStyles({
  root: {
    marginTop: '5px',
    minWidth: '90px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    width: '725px',
  },
})(FormControl);

export const StyledInputLabel = styled(InputLabel)`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #333333;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 36px;
  width: 290px;
  flex-direction: column;
`;

export const SelectReasonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 36px;
  width: 725px
  flex-direction: column;
  z-index: 9999999999 !important;
`;

export const Seperator = styled.div`
  width: 725px;
  height: 1px;
  background-color: #cccccc;
  margin-top: 14px;
`;

export const SaveButton = styled(Button)`
  margin-right: 16px;
  border-radius: 20px;
`;

export const DiscardButton = styled(Button)`
  margin-left: 16px;
  border-radius: 20px;
`;

export const EquipmentContainer = styled.div<{
  flexDirection?: string;
  justifyContent?: string;
}>`
  display: flex;
  flex: 1;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: 0 0 100%;
  width: 100%;
  height: 200px;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const StyledEquipmentCard = styled.div<{
  height: string;
  width: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.294007);
  border-radius: 2px;
  margin-left: 24px;
`;

export const StyledEquipmentCardHeader = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 47px;
  background-color: #cccccc;
  background: #eeeeee;
  align-items: center;
  justify-content: flex-start;
`;

export const StyleDatText = styled.div<{
  marginLeft: string;
  fontWeight: string;
  marginTop: string;
}>`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0875px;
  color: #666666;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const StyledEquipmentCardContainer = styled.div<{
  flexDirection: string;
}>`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 109px;
  justify-content: flex-start;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const StyledSonarContainer = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 109px;
  justify-content: flex-start;
  flex-direction: row;
`;

export const StyledSonarFormControl = withStyles({
  root: {
    marginTop: '18px',
    width: '92px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    marginLeft: '15px',
  },
})(FormControl);

export const SonarSeperator = styled.div<{
  width: string;
}>`
  width:: ${({ width }) => width};
  height: 1px;
  background-color: #cccccc;
`;

export const StyledSonarReeferFormControl = withStyles({
  root: {
    marginTop: '18px',
    width: '97%',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    marginLeft: '15px',
  },
})(FormControl);

export const StyledHistoryReeferFormControl = withStyles({
  root: {
    marginTop: '18px',
    width: '250px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    marginLeft: '15px',
  },
})(FormControl);

export const Step1HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 109px;
  justify-content: space-between;
  flex-direction: row;
`;

export const Step1HeaderDefaultButton = styled.div`
  width: 148px;
  height: 17px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #0091ea;
  margin-right: 24px;
  margin-top: 24px;
  cursor: pointer;
`;

export const SelectDefaultContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%
  flex-direction: column;
`;

export const ChangeLogSelectContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 36px;
  width: 95%;
  flex-direction: row;
  background: #f9f9f9;
  border-radius: 4px;
  align-items: center;
  height: 98px;
`;

export const ChangeLogCardContainer = styled.div<{
  flexDirection: string;
  justifyContent?: string;
}>`
  display: flex;
  flex: 1;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
  background: #ffffff;
`;

export const StyledFormControlChangeLog = withStyles({
  root: {
    marginTop: '5px',
    minWidth: '90px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    width: '225px',
  },
})(FormControl);
