import * as React from "react";
import {
  RegionTitleText,
  StyledCard,
  CardContainer,
  EquipmentContainer,
} from "../rateAdjustmentStylesV2";

import FlatbedSonarCardV2 from "./FlatbedSonarCardV2";
import FlatbedHistoryCardV2 from "./FlatbedHistoryCardV2";

const FlatbedRowV2 = () => {
  return (
    <StyledCard height="auto">
      <CardContainer flexDirection="column">
        <RegionTitleText
          fontSize="24px"
          lineHeight="29px"
          width="450px"
          height="29px"
          padding="0px"
        >
          Flatbed loads
        </RegionTitleText>
        <EquipmentContainer flexDirection='row' flexWrap='wrap'>
          <FlatbedSonarCardV2 />
          <FlatbedHistoryCardV2 />
        </EquipmentContainer>
      </CardContainer>
    </StyledCard>
  );
};

export default FlatbedRowV2;
