import {
  RateAdjustmentBody,
  RateAdjustmentApiResult,
  RateAdjustmentReasonBody,
  RateAdjustmentReasonApiResult,
  AccessorialFeesApiResult,
  AccessorialFeesBody,
  AccessorialFeesPostResponse,
} from './rateAdminTypes';
import http from 'utils/httpUtils';
import {
  API_BASE_URL_V2,
  REACT_APP_RATE_SERVICE_BASE_URL,
} from 'utils/constants';

export const getRateAdminAdjustment = async () => {
  return http.get<RateAdjustmentApiResult[]>(
    `${API_BASE_URL_V2}/rate/adjustment`,
  )();
};

export const createRateAdminAdjustment = async (
  body: RateAdjustmentBody | undefined,
) => {
  return body
    ? http.put<RateAdjustmentApiResult, RateAdjustmentBody>(
        `${API_BASE_URL_V2}/rate/adjustment`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};

export const getRateAdminAdjustmentReasons = async () => {
  return http.get<RateAdjustmentReasonApiResult[]>(
    `${API_BASE_URL_V2}/rate/reason`,
  )();
};

export const createRateAdminAdjustmentReason = async (
  body: RateAdjustmentReasonBody | undefined,
) => {
  return body
    ? http.put<RateAdjustmentReasonApiResult, RateAdjustmentReasonBody>(
        `${API_BASE_URL_V2}/rate/reason`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};

export const getAccessorialFees = async () => {
  return http.get<AccessorialFeesApiResult[]>(
    `${REACT_APP_RATE_SERVICE_BASE_URL}/rates/accessorialFees`,
  )();
};

export const createAccessorialFees = async (
  body: AccessorialFeesBody | undefined,
) => {
  return body
    ? http.post<AccessorialFeesPostResponse, AccessorialFeesBody>(
        `${REACT_APP_RATE_SERVICE_BASE_URL}/rates/accessorialFees`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};
