import { FC, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import { InitialState } from '../hooks/useQuoteData';
import { Spinner, Chip, styled } from 'shamrock-clover-ui';

const colorText: {} = {
  new: '#0091EA',
  negotiating: '#DEA200',
  verifying: '#FF9000',
  booked: '#72BF44',
  dismissed: '#EF3824',
};

const color: {} = {
  new: '#EAF7FF',
  negotiating: '#FFF6DE',
  verifying: '#FFF0D8',
  booked: '#EAF6E3',
  dismissed: '#FFF0F0',
};

const MenuFilterStyles = styled.div<{ type?: string | null }>`
  background: ${(props: { type?: string | null }) =>
    props.type && color[props.type as keyof {}]};
  padding: 8px 4px;
  border-radius: 8px;
  cursor: pointer;
`;
interface MenuItemsProps {
  label: string;
  type: string;
}

const AnchorFilterStyle = styled(NavLink)<{ disabled: boolean }>`
  display: flex;
  text-decoration: none;
  color: #666666;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
`;

const LoadingFiltersSpinner = styled.span`
  padding: 5px 5px;
`;

const StyledChip = styled(Chip)<{ type: string }>`
  background-color: transparent;
  border-color: ${(props: { type: string }) =>
    props.type && colorText[props.type as keyof {}]};
  color: ${(props: { type: string }) =>
    props.type && colorText[props.type as keyof {}]};
  font-weight: 900;
  border-width: 2px;
  border-style: solid;
  padding: 3px 16px;
  font-size: 19px;
`;

const MenuFilters: FC<React.PropsWithChildren<MenuItemsProps>> = ({
  ...props
}) => {
  const { pathname } = useLocation();
  const { label, type } = props;
  const path = !pathname.split('/')[2] ? 'new' : pathname.split('/')[2];

  const { quotes, loadingByFilter } = useContext(QuotesEnhancementsContext);

  return (
    <MenuFilterStyles type={type === path ? type : null}>
      <AnchorFilterStyle
        to={`/quotes/${type}`}
        className={({ isActive }) => (isActive ? 'active' : '')}
        disabled={loadingByFilter}
      >
        {loadingByFilter ? (
          <LoadingFiltersSpinner>
            <Spinner size="25" />
          </LoadingFiltersSpinner>
        ) : (
          <StyledChip
            type={type}
            variant="base"
            state="enabled"
            label={`${quotes[type as keyof InitialState].length}`}
          />
        )}

        <span style={{ marginLeft: '5px' }}>{label}</span>
      </AnchorFilterStyle>
    </MenuFilterStyles>
  );
};

export default MenuFilters;
