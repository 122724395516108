import * as React from 'react';
import { Flex } from 'components/Flex';
import { CalculatedText } from './rateProStyles';
import { styled, Dialog, Icon } from 'shamrock-clover-ui';

const Text = styled.div<{
  fontSize: string;
  height?: string;
  lineHeight?: string;
  width?: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ theme }) => theme.colors.gray[90]};
  height: ${({ height }) => height && height};
  line-height: ${({ lineHeight }) => lineHeight && lineHeight};
  width: ${({ width }) => width && width};
`;

interface DialogProps {
  title?: string;
  description?: string;
}

const HowIsThisCalculatedDialog: React.FC<
  React.PropsWithChildren<DialogProps>
> = ({
  title = 'Something went wrong.',
  description = 'An unexpected error has occurred. Please try again later.',
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <CalculatedText onClick={() => setIsOpen(true)}>
        <Flex margin="0px 8px 0px 0px">
          <Icon size="18" icon="stationNote" />
        </Flex>
        <div> How is this calculated?</div>
      </CalculatedText>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        content={
          <div>
            <>
              <Text fontSize="24px" height="50px">
                {title}
              </Text>
              <Text fontSize="14px" height="100px">
                {description}
              </Text>
            </>
          </div>
        }
      />
    </>
  );
};

export default HowIsThisCalculatedDialog;
