import { Flex } from 'components/Flex';
import SpinnerWithWait from 'components/Spinner';
import Card from 'components/Card';

import { LargeText, MediumText } from '../rateProStyles';
import HowIsThisCalculatedDialog from '../HowIsThisCalculatedDialog';
import React from 'react';
import { numberToWholeCurrencyString } from 'utils/numberUtils';

export const getSuggestedBuyRateStatus = (
  sonarScoreIsLoading: boolean,
  buyRate: number | undefined,
) => {
  if (sonarScoreIsLoading) {
    return 'loading';
  } else {
    // Normal logic without caring about sonar score
    if (buyRate) {
      return 'data';
    } else {
      return 'error';
    }
  }
};

export type CardProps = {
  helpDialogTitle: string;
  helpDialogDescription: string;
  buyRate?: number;
  cardState: 'loading' | 'error' | 'data';
};

export const SuggestedbuyRateCard: React.FC<React.PropsWithChildren<CardProps>> = ({
  helpDialogTitle,
  helpDialogDescription,
  buyRate,
  cardState,
}) => {
  return (
    <Card
      contentHeight="74px"
      secondaryTitle="SUGGESTED BUY RATE"
      rightComponent={
        <HowIsThisCalculatedDialog
          title={helpDialogTitle}
          description={helpDialogDescription}
        />
      }
    >
      {cardState === 'loading' ? (
        <SpinnerWithWait />
      ) : (
        buyRate && (
          <Flex alignItems="center" height="100%">
            <LargeText margin="0px 0px 0px 0px">
              {numberToWholeCurrencyString(buyRate)}
            </LargeText>
            <MediumText margin="18px 0px 0px 5px" bold={true}>
              BUY
            </MediumText>
          </Flex>
        )
      )}
    </Card>
  );
};
