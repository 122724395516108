import { createContext, FC, useContext, PropsWithChildren } from 'react';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import DeleteLaneCapacityDialog from '../components/DeleteLaneCapacityDialog';
import { ShowDialogOnDeleteLane } from '../CapacityTypes';

export interface DialogContexType {
  handleShowDialogOnDelete: (dialogType: ShowDialogOnDeleteLane) => void;
  showDialog: boolean;
}

export const DeleteDialogContext = createContext<DialogContexType>(
  {} as DialogContexType,
);

export const DeleteDialogWrapper: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {
    showDialog,
    deletingLaneAndCapacityType,
    handleShowDialogOnDelete,
    handleDeleteLaneAndCapacity,
    handleCloseDialog,
  } = useContext(CarrierCapacityContext);

  let dialogDescription: string = '';
  let title: string = '';

  switch (deletingLaneAndCapacityType) {
    case 'capacity':
      dialogDescription =
        'Your capacity preferences will be removed from this lane.  You can add them again anytime.';
      title = 'Delete capacity?';
      break;
    case 'laneAndCapacity':
      dialogDescription = 'This cannot be recovered.';
      title = 'Delete lane and capacity?';
      break;
    case 'lane':
      dialogDescription = 'This cannot be recovered.';
      title = 'Delete lane?';
  }

  return (
    <DeleteDialogContext.Provider
      value={{ showDialog, handleShowDialogOnDelete }}
    >
      <>
        <DeleteLaneCapacityDialog
          showDialog={showDialog}
          dialogTitle={title}
          dialogDescription={dialogDescription}
          handleCloseDialog={handleCloseDialog}
          handleDelete={handleDeleteLaneAndCapacity}
          deletingCapacity={deletingLaneAndCapacityType}
        />
        {children}
      </>
    </DeleteDialogContext.Provider>
  );
};

export const useDeleteDialogContext = () => {
  const context = useContext(DeleteDialogContext);
  if (context === undefined) {
    throw new Error(
      'useDeleteDialogContext must be used within an DeleteDialogContext.Provider',
    );
  }
  return context;
};
