import * as React from "react";
import {
  SaveButton,
  StyledCard,
  CardContainer,
  DiscardButton,
} from "./rateAdjustmentStyles";

interface Props {
  isDisabled: boolean;
  saveClick: (() => void) | undefined;
  discardClick: (() => void) | undefined;
}

const RateAdjustmentButtons: React.FC<React.PropsWithChildren<Props>> = ({
  isDisabled,
  saveClick,
  discardClick,
}) => {
  return (
    <StyledCard height="70px" topBorder="1px solid #CCCCCC;">
      <CardContainer flexDirection="row" justifyContent="space-between;">
        <DiscardButton
          width="165px;"
          color="red"
          disabled={isDisabled}
          buttonStyle="outlined"
          onClick={discardClick}
        >
          DISCARD CHANGES
        </DiscardButton>
        <SaveButton
          width="140px;"
          data-testid="open-dialog"
          disabled={isDisabled}
          onClick={saveClick}
        >
          SAVE CHANGES
        </SaveButton>
      </CardContainer>
    </StyledCard>
  );
};

export default RateAdjustmentButtons;
