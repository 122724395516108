import React from 'react';
import { FilterInput } from 'shamrock-clover-ui';
import { styled } from 'shamrock-clover-ui';
import { Flex } from '../../components/Flex';
import { useRateProQueryParams } from '../../hooks/useQueryParams';
import { LabelText } from './rateProStyles';

const InputContainer = styled.div<{ isError: boolean }>`
  height: 36px;

  > div {
    border: ${({ isError, theme }) =>
      isError ? `1px solid ${theme.colors.red[50]}` : 'none'};
  }
`;

const StyledInput = styled(FilterInput)`
  border: none;
  font-family: Proxima Nova;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  color: #333333;
  height: 36px;

  ::placeholder {
    color: #333333;
  }
`;

const CustomerEntry = () => {
  const { addQueryParams, setQueryParams, queryParams } =
    useRateProQueryParams();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    addQueryParams({
      customerCode: event.target.value.toLocaleUpperCase().substring(0, 8),
    });
  };

  const customerCode = queryParams.customerCode ? queryParams.customerCode : '';

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <LabelText>Customer / Prospect Code</LabelText>
      <InputContainer
        isError={
          customerCode.length !== 0 &&
          customerCode.length !== 7 &&
          customerCode.length !== 8
        }
      >
        <StyledInput
          placeholder="7 or 8 Digit Code"
          height="30px"
          width="206px"
          onChange={onChange}
          value={customerCode}
          clearSearch={() =>
            setQueryParams({ ...queryParams, customerCode: undefined })
          }
        ></StyledInput>
      </InputContainer>
    </Flex>
  );
};

export default CustomerEntry;
