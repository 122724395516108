import React from 'react';
import { AdjustmentFormData } from '../creditRequestTypes';

// styles
import Grid from '@material-ui/core/Grid';
import { TextInputStyled, GridContainer } from './panelStyles';

// props
interface Props {
  formData: AdjustmentFormData;
  setFormData: Function;
}

export const AdjustmentForm = ({ formData, setFormData }: Props) => {
  const handleOnChangeForm = (name: string, value: string) => {
    if (/^\d*$/.test(value)) {
      setFormData({ ...formData, [name]: { value: value, errorMessage: '' } });
    }
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setFormData({
        ...formData,
        [name]: { value: '', errorMessage: 'Required field' },
      });
    }
  };

  return (
    <GridContainer container>
      <Grid item sm={4}>
        <TextInputStyled
          label="Current Credit Limit"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('currentCreditLimit', value);
          }}
          value={formData.currentCreditLimit.value}
          error={formData.currentCreditLimit.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Current Receivables"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('currentReceivables', value);
          }}
          value={formData.currentReceivables.value}
          error={formData.currentReceivables.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Number of Additional Loads"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('numberOfAdditionalLoads', value);
          }}
          value={formData.numberOfAdditionalLoads.value}
          onBlur={() => {
            handleOnBlurForm(
              'numberOfAdditionalLoads',
              formData.numberOfAdditionalLoads.value,
            );
          }}
          error={formData.numberOfAdditionalLoads.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Additional Credit Requested"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('additionalCreditRequested', value);
          }}
          value={formData.additionalCreditRequested.value}
          onBlur={() => {
            handleOnBlurForm(
              'additionalCreditRequested',
              formData.additionalCreditRequested.value,
            );
          }}
          error={formData.additionalCreditRequested.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Single Load Amount"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('singleLoadAmount', value);
          }}
          value={formData.singleLoadAmount.value}
          onBlur={() => {
            handleOnBlurForm(
              'singleLoadAmount',
              formData.singleLoadAmount.value,
            );
          }}
          error={formData.singleLoadAmount.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Multiple Load Amount"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('multipleLoadAmount', value);
          }}
          value={formData.multipleLoadAmount.value}
          onBlur={() => {
            handleOnBlurForm(
              'multipleLoadAmount',
              formData.multipleLoadAmount.value,
            );
          }}
          error={formData.multipleLoadAmount.errorMessage}
        />
      </Grid>
    </GridContainer>
  );
};
