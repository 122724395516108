import { CustomerInfoFormData } from '../creditRequestTypes';

// styles
import Grid from '@material-ui/core/Grid';
import { TextInputStyled, GridContainer } from './panelStyles';

// props
interface Props {
  formData: CustomerInfoFormData;
  setFormData: Function;
}

export const CustomerInfoForm = ({ formData, setFormData }: Props) => {
  const handleOnChangeForm = (name: string, value: string) => {
    setFormData({ ...formData, [name]: { value: value, errorMessage: '' } });
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setFormData({
        ...formData,
        [name]: { value: '', errorMessage: 'Required field' },
      });
    }
  };

  return (
    <GridContainer container>
      <Grid item sm={6}>
        <TextInputStyled
          label="Customer ID"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('customerId', value);
          }}
          value={formData.customerId.value}
          onBlur={() => {
            handleOnBlurForm('customerId', formData.customerId.value);
          }}
          error={formData.customerId.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={6}>
        <TextInputStyled
          label="Customer Name"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('customerName', value);
          }}
          value={formData.customerName.value}
          onBlur={() => {
            handleOnBlurForm('customerName', formData.customerName.value);
          }}
          error={formData.customerName.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={6}>
        <TextInputStyled
          label="Address 1"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('addressLine1', value);
          }}
          value={formData.addressLine1.value}
          onBlur={() => {
            handleOnBlurForm('addressLine1', formData.addressLine1.value);
          }}
          error={formData.addressLine1.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={6}>
        <TextInputStyled
          label="Address 2"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('addressLine2', value);
          }}
          value={formData.addressLine2.value}
          disabled
        />
      </Grid>
      <Grid item sm={5}>
        <TextInputStyled
          label="City"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('city', value);
          }}
          value={formData.city.value}
          onBlur={() => {
            handleOnBlurForm('city', formData.city.value);
          }}
          error={formData.city.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={5}>
        <TextInputStyled
          label="State"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('state', value);
          }}
          value={formData.state.value}
          onBlur={() => {
            handleOnBlurForm('state', formData.state.value);
          }}
          error={formData.state.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={2}>
        <TextInputStyled
          label="Zip Code"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('zip', value.replace(/\D/g, ''));
          }}
          value={formData.zip.value}
          onBlur={() => {
            handleOnBlurForm('zip', formData.zip.value);
          }}
          error={formData.zip.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={6}>
        <TextInputStyled
          label="Collector ID"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('collectorId', value);
          }}
          value={formData.collectorId.value}
          onBlur={() => {
            handleOnBlurForm('collectorId', formData.collectorId.value);
          }}
          error={formData.collectorId.errorMessage}
          disabled
        />
      </Grid>
      <Grid item sm={6}>
        <TextInputStyled
          label="Credit Rate Code"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('creditRateCode', value);
          }}
          value={formData.creditRateCode.value}
          onBlur={() => {
            handleOnBlurForm('creditRateCode', formData.creditRateCode.value);
          }}
          error={formData.creditRateCode.errorMessage}
          disabled
        />
      </Grid>
    </GridContainer>
  );
};
