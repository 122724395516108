import { Flex } from 'components/Flex';
import { CarrierCapacityContext } from 'features/carrierCapacity/context/CarrierCapacityContext';
import { useContext } from 'react';
import { SelectInput, Icon as CloverIcon } from 'shamrock-clover-ui';
import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import { StyledMenuItemText, StyledLanesDropdownContainer } from './DrawerStyles';

const sortingOptions: MenuOption[] = [
  { optionName: 'Most Recent' },
  { optionName: 'Capacity' },
  { optionName: 'Pickup' },
  { optionName: 'Delivery' },
];

const LanesSort = () => {
  const { selectedSortMethod, handleSelectedSortMethod } = useContext(
    CarrierCapacityContext,
  );

  return (
    <StyledLanesDropdownContainer>
      <SelectInput
        onOptionSelected={handleSelectedSortMethod}
        value={{ optionName: `Sort by: ${selectedSortMethod.optionName}` }}
        options={sortingOptions}
        renderOption={(option: MenuOption) => (
          <Flex alignItems="center">
            <StyledMenuItemText>{option.optionName}</StyledMenuItemText>
            {option.optionName === selectedSortMethod.optionName && (
              <CloverIcon icon="check" size={'20'} />
            )}
          </Flex>
        )}
      />
    </StyledLanesDropdownContainer>
  );
};

export default LanesSort;
