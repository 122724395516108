import { NewAdjustmentFormData } from '../creditRequestTypes';

// styles
import Grid from '@material-ui/core/Grid';
import SelectInputStyled from 'components/SelectInput';
import {
  TextInputStyled,
  MultilineTextInputStyled,
  PhoneFormatTextInputStyled,
  GridContainer,
  SelectInputMenu,
} from './panelStyles';

// props
interface Props {
  formData: NewAdjustmentFormData;
  setFormData: Function;
}

export const NewAdjustmentForm = ({ formData, setFormData }: Props) => {
  const businessObtainedOptions = [
    'Customer solicited us via Google PPC',
    'Customer solicited us (other) - Explain below',
    'We solicited customer - Explain below',
    'Referral - Explain below & include Cust McLeoad Code',
  ].map((optionName) => ({
    optionName,
  }));

  const potentialLongtermBusinessOptions = ['Yes', 'No'].map((optionName) => ({
    optionName,
  }));

  const handleOnChangeForm = (name: string, value: string) => {
    setFormData({ ...formData, [name]: { value: value, errorMessage: '' } });
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setFormData({
        ...formData,
        [name]: { value: '', errorMessage: 'Required field' },
      });
    }
    if (name === 'phoneNumber' && value && value.length < 10) {
      setFormData({
        ...formData,
        [name]: {
          value: value,
          errorMessage: 'Invalid 10-digit phone number',
        },
      });
      return;
    }
  };

  return (
    <GridContainer container>
      <Grid item sm={4}>
        <TextInputStyled
          label="Contact Name"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('contactName', value);
          }}
          value={formData.contactName.value}
          onBlur={() => {
            handleOnBlurForm('contactName', formData.contactName.value);
          }}
          error={formData.contactName.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Job Title"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('jobTitle', value);
          }}
          value={formData.jobTitle.value}
          onBlur={() => {
            handleOnBlurForm('jobTitle', formData.jobTitle.value);
          }}
          error={formData.jobTitle.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Fax Number"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            if (/^\d*$/.test(value)) {
              handleOnChangeForm('faxNumber', value);
            }
          }}
          value={formData.faxNumber.value}
          error={formData.faxNumber.errorMessage}
        />
      </Grid>
      <Grid item sm={3}>
        <PhoneFormatTextInputStyled
          label="Phone"
          changeHandler={(value: string) => {
            handleOnChangeForm('phoneNumber', value);
          }}
          value={formData.phoneNumber.value}
          onBlur={() => {
            handleOnBlurForm('phoneNumber', formData.phoneNumber.value);
          }}
          error={formData.phoneNumber.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Email"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('email', value);
          }}
          value={formData.email.value}
          onBlur={() => {
            handleOnBlurForm('email', formData.email.value);
          }}
          error={formData.email.errorMessage}
        />
      </Grid>
      <Grid item sm={5}>
        <SelectInputMenu>
          <SelectInputStyled
            label="Business Obtained"
            onOptionSelected={(option) => {
              handleOnChangeForm('businessObtained', option.optionName);
            }}
            options={businessObtainedOptions}
            value={{ optionName: formData.businessObtained.value }}
            error={formData.businessObtained.errorMessage}
          />
        </SelectInputMenu>
      </Grid>
      <Grid item sm={12}>
        <TextInputStyled
          label="Business Obtained Additional Info"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('businessObtainedAdditional', value);
          }}
          value={formData.businessObtainedAdditional.value}
          onBlur={() => {
            handleOnBlurForm(
              'businessObtainedAdditional',
              formData.businessObtainedAdditional.value,
            );
          }}
          error={formData.businessObtainedAdditional.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Immediate Credit Need"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            if (/^\d*$/.test(value)) {
              handleOnChangeForm('immediateCreditNeed', value);
            }
          }}
          value={formData.immediateCreditNeed.value}
          onBlur={() => {
            handleOnBlurForm('city', formData.immediateCreditNeed.value);
          }}
          error={formData.immediateCreditNeed.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Single Load Amount"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            if (/^\d*$/.test(value)) {
              handleOnChangeForm('singleLoadAmount', value);
            }
          }}
          value={formData.singleLoadAmount.value}
          onBlur={() => {
            handleOnBlurForm(
              'singleLoadAmount',
              formData.singleLoadAmount.value,
            );
          }}
          error={formData.singleLoadAmount.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInputStyled
          label="Multiple Load Amount"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            if (/^\d*$/.test(value)) {
              handleOnChangeForm('multipleLoadAmount', value);
            }
          }}
          value={formData.multipleLoadAmount.value}
          onBlur={() => {
            handleOnBlurForm(
              'multipleLoadAmount',
              formData.multipleLoadAmount.value,
            );
          }}
          error={formData.multipleLoadAmount.errorMessage}
        />
      </Grid>
      <Grid item sm={4}>
        <SelectInputStyled
          label="Potential Longterm Business"
          onOptionSelected={(option) =>
            handleOnChangeForm('potentialLongtermBusiness', option.optionName)
          }
          options={potentialLongtermBusinessOptions}
          value={{ optionName: formData.potentialLongtermBusiness.value }}
          error={formData.potentialLongtermBusiness.errorMessage}
        />
      </Grid>
      <Grid item sm={12}>
        <MultilineTextInputStyled
          label="Comments"
          variant="filled"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('comments', value);
          }}
          value={formData.comments.value}
          onBlur={() => {
            handleOnBlurForm('comments', formData.comments.value);
          }}
          error={formData.comments.errorMessage}
        />
      </Grid>
    </GridContainer>
  );
};
