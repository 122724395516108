import { styled } from 'shamrock-clover-ui';
import { SelectInput } from 'shamrock-clover-ui';
interface Props {
  label: string;
  error?: string;
  onOptionSelected: (value: { optionName: string }) => void;
  options: { optionName: string }[];
  value: { optionName: string };
  width?: string;
}

const Container = styled.div<{
  width?: string;
  error?: boolean | string;
}>`
  margin: auto;
  width: ${(props) => (!props.width ? '296px' : props.width)};
  margin-top: 6px;
  margin-bottom: 10px;
  > div > div {
    border-bottom: ${(props) =>
      props.error ? '1px solid #EF3824' : '1px solid #CCCCCC'};
  }
  > div {
    border-bottom: none;
  }
`;

const ErrorText = styled.div`
  margin: 5px 0px 0px 10px;
  color: #ef3824;
  font-size: 0.75em;
  background-color: transparent;
`;

const SelectInputStyled = ({
  label,
  error,
  onOptionSelected,
  options,
  value,
  width,
}: Props) => {
  return (
    <Container width={width} error={!!error}>
      <SelectInput
        label={label}
        onOptionSelected={(option: any) => onOptionSelected(option)}
        options={options}
        value={value}
      />
      <ErrorText>{error}</ErrorText>
    </Container>
  );
};

export default SelectInputStyled;
