import React from 'react';

const useConditionalWrapper = (
  condition: boolean,
  wrapper: any,
  children: any,
) => {
  return condition ? React.cloneElement(wrapper, {}, children) : children;
};

export default useConditionalWrapper;
