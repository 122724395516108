import { AccessorialFeesValues } from '../AccessorialFees';
import AccessorialFeesCard from './AccessorialFeesCard';
import AdditionalStopsCard from './AdditionalStopsCard';

interface Props {
  accessorialFees: {
    driverAssist: number;
    hazmat: number;
    palletJack: number;
    ramps: number;
    sameDay: number;
    tarps: number;
    tankerEndorsed: number;
    team: number;
    additionalStops: {
      oneStop: number;
      twoStops: number;
      threeStops: number;
      fourPlusStops: number;
    };
  };
  setAccessorialFees: (newFees: AccessorialFeesValues) => void;
  isLoading: boolean;
}

const AccessorialFeesColumn: React.FC<React.PropsWithChildren<Props>> = ({
  accessorialFees,
  setAccessorialFees,
  isLoading,
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    setAccessorialFees({
      ...accessorialFees,
      [type]: parseFloat(event.target.value || '0'),
    });
  };

  const handleStopsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    setAccessorialFees({
      ...accessorialFees,
      additionalStops: {
        ...accessorialFees.additionalStops,
        [type]: parseFloat(event.target.value || '0'),
      },
    });
  };

  return (
    <div flex-direction="column">
      <AccessorialFeesCard
        AccessoryType={'DRIVER ASSIST'}
        value={accessorialFees.driverAssist}
        onChange={(value) => handleChange(value, 'driverAssist')}
        isLoading={isLoading}
      />
      <AccessorialFeesCard
        AccessoryType={'HAZMAT'}
        value={accessorialFees.hazmat}
        onChange={(value) => handleChange(value, 'hazmat')}
        isLoading={isLoading}
      />
      <AccessorialFeesCard
        AccessoryType={'PALLET JACK'}
        value={accessorialFees.palletJack}
        onChange={(value) => handleChange(value, 'palletJack')}
        isLoading={isLoading}
      />
      <AccessorialFeesCard
        AccessoryType={'RAMPS'}
        value={accessorialFees.ramps}
        onChange={(value) => handleChange(value, 'ramps')}
        isLoading={isLoading}
      />
      <AccessorialFeesCard
        AccessoryType={'SAME DAY'}
        value={accessorialFees.sameDay}
        onChange={(value) => handleChange(value, 'sameDay')}
        isLoading={isLoading}
      />
      <AccessorialFeesCard
        AccessoryType={'TARPS'}
        value={accessorialFees.tarps}
        onChange={(value) => handleChange(value, 'tarps')}
        isLoading={isLoading}
      />
      <AccessorialFeesCard
        AccessoryType={'TANKER ENDORSED'}
        value={accessorialFees.tankerEndorsed}
        onChange={(value) => handleChange(value, 'tankerEndorsed')}
        isLoading={isLoading}
      />
      <AccessorialFeesCard
        AccessoryType={'TEAM'}
        value={accessorialFees.team}
        onChange={(value) => handleChange(value, 'team')}
        isLoading={isLoading}
      />
      <AdditionalStopsCard
        value={accessorialFees.additionalStops}
        onChange={handleStopsChange}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AccessorialFeesColumn;
