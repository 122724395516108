import { Flex } from 'components/Flex';
import SpinnerWithWait from 'components/Spinner';
import Card from 'components/Card';

import { LargeText, MediumText } from '../rateProStyles';
import HowIsThisCalculatedDialog from '../HowIsThisCalculatedDialog';
import React from 'react';
import { numberToWholeCurrencyString } from 'utils/numberUtils';
import { ErrorText, NoDataText } from '../rateProV2/RateProV2';

export const getSuggestedBuyRateStatus = (
  sonarScoreIsLoading: boolean,
  sonarScoreIsError: boolean,
  HubtekDataIsLoading: boolean,
  buyRate: number | undefined,
) => {
  if (sonarScoreIsLoading || HubtekDataIsLoading) {
    return 'loading';
  }
  if (buyRate) {
    return 'data';
  }
  if (sonarScoreIsError) {
    return 'noData';
  }

  return 'blank';
};

export type CardProps = {
  helpDialogTitle: string;
  helpDialogDescription: string;
  buyRate?: number;
  cardState: 'loading' | 'error' | 'data' | 'noData' | 'blank';
  sellRateRecalculating: boolean;
};

export const SuggestedbuyRateCard: React.FC<React.PropsWithChildren<CardProps>> = ({
  helpDialogTitle,
  helpDialogDescription,
  buyRate,
  cardState,
  sellRateRecalculating,
}) => {
  return (
    <Card
      contentHeight="87px"
      secondaryTitle="SUGGESTED BUY RATE"
      rightComponent={
        <HowIsThisCalculatedDialog
          title={helpDialogTitle}
          description={helpDialogDescription}
        />
      }
    >
      {cardState === 'noData' && <NoDataText text="No Buy Rate Available" />}
      {cardState === 'error' && (
        <ErrorText text="Failed to calculate Buy Rate" />
      )}
      {cardState === 'loading' && <SpinnerWithWait />}
      {cardState === 'data' && buyRate && (
        <Flex alignItems="center" height="100%">
          <LargeText margin="0px 0px 0px 0px">
            {sellRateRecalculating ? (
              <>$–</>
            ) : (
              numberToWholeCurrencyString(buyRate)
            )}
          </LargeText>
          <MediumText margin="18px 0px 0px 5px" bold={true}>
            CARRIER
          </MediumText>
        </Flex>
      )}
    </Card>
  );
};
