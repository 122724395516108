import { styled, Icon } from 'shamrock-clover-ui';

export const Container = styled.div`
  height: calc(100vh - 100px);
  background-color: ${({ theme }) => theme.colors.gray[10]};
  padding: 16px 64px;
  font-size: 14px;
`;

export const StyledHeader = styled.p`
  font-size: 36px;
  margin-bottom: 0;
`;

export const Flex = styled.div`
  display: flex;
`;

export const PageNavFlex = styled(Flex)`
  align-items: center;
  padding: 7px;
  float: right;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 50%;
  justify-content: center;
  position: absolute;
  width: 90%;
  z-index: 1;
`;

export const LabelText = styled.div`
  padding-left: 16px;
  margin-bottom: 4px;
  font-size: ${({ theme }) => theme.font.size.xxsmall};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.colors.gray[70]};
  text-transform: uppercase;
`;

export const StyledTable = styled.table`
  overflow: scroll;
  display: block;
  height: 50vh;
  border-collapse: collapse;
  width: 100%;
`;

export const TableContainer = styled.div`
  margin-top: 30px;
  padding: 10px;
  background-color: #ffffff;
`;

export const TableHead = styled.thead`
  display: table;
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
`;

export const TableBody = styled.tbody`
  display: inline-table;
  table-layout: fixed;
  width: 100%;
`;

export const StyledRow = styled.tr`
  padding: 5px;
  border-bottom: 1px solid #0000001f;
`;

export const StyledCell = styled.td`
  padding: 13px;
`;

export const TableFooter = styled.div`
  background: #f9f9f9;
  width: 100%;
  height: 64px;
`;

export const CloverIcon = styled(Icon)`
  padding-left: 10px;
  padding-right: 10px;
`;

export const NoSearchText = styled.div`
  color: ${({ theme }) => theme.colors.gray[60]};
  font-size: 20px;
  text-align: center;
  padding-top: 30vh;
`;

export const Link = styled.a`
  color: #0091ea;
  text-decoration: underline;
  cursor: pointer;
`;
