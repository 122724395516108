import * as React from 'react';
import {
  StyledCard,
  CardContainer,
  StyledDiscardButton,
  StyledSaveButton,
} from './rateAdjustmentStylesV2';

interface Props {
  isDisabled: boolean;
  saveClick: () => void;
  discardClick: () => void;
}

const RateAdjustmentButtons: React.FC<React.PropsWithChildren<Props>> = ({
  isDisabled,
  saveClick,
  discardClick,
}) => {
  return (
    <StyledCard height="70px" topBorder="1px solid #CCCCCC" paddingLeft="24px">
      <CardContainer
        flexDirection="row"
        justifyContent="end"
        paddingRight="16px"
      >
        <StyledDiscardButton
          buttonStyle="outlined"
          color="red"
          marginRight="16px"
          onClick={discardClick}
          disabled={isDisabled}
        >
          DISCARD CHANGES
        </StyledDiscardButton>
        <StyledSaveButton
          buttonStyle="solid"
          color="blue"
          width="140px"
          disabled={isDisabled}
          onClick={saveClick}
        >
          SAVE CHANGES
        </StyledSaveButton>
      </CardContainer>
    </StyledCard>
  );
};

export default RateAdjustmentButtons;
