import { Button, Snackbar } from 'shamrock-clover-ui';
import {
  TextContainer,
  ViewText,
  CapacitySearchContainer,
  Container,
  ToolbarContainer,
  StyledInput,
  StyledIcon,
} from 'features/carrierCapacity/CarrierCapacityStyles';
import { useContext, useEffect, useState } from 'react';
import CarrierSearchResults from './components/CarrierSearchResults';
import { CarrierCapacityContext } from './context/CarrierCapacityContext';

const Carriers = () => {
  const [value, setValue] = useState('');
  const MIN_SEARCH_LENGTH = 3;
  const isDisabled = value.length < MIN_SEARCH_LENGTH;

  const {
    carrierSearchResultsState,
    searchCarriers,
    resetCarrierSearch,
    carriers,
  } = useContext(CarrierCapacityContext);

  useEffect(() => {
    // If we want to retain results across sub tabs, we can remove this effect.
    resetCarrierSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSearch = () => {
    searchCarriers(value);
  };

  const clearSearch = () => {
    setValue('');
    resetCarrierSearch();
  };

  const getSnackbar = () => {
    switch (true) {
      case value.length > 0 && value.length < MIN_SEARCH_LENGTH:
        return (
          <Snackbar
            duration={1000000}
            message={`Enter a minimum of ${MIN_SEARCH_LENGTH} characters to search.`}
            open={true}
            variant="error"
          />
        );
      case carrierSearchResultsState === 'resultsAvailable' &&
        carriers.length === 0:
        return (
          <Snackbar
            duration={1000000}
            message="Search by MC# or DOT#. If results are still missing, add or remove prefix zeros (e.g. 00032156 or 32156)."
            open={true}
            variant="error"
          />
        );
      case carrierSearchResultsState === 'error':
        return (
          <Snackbar
            duration={1000000}
            message="An error occurred while searching."
            open={true}
            variant="error"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <CapacitySearchContainer>
        <ToolbarContainer>
          <StyledInput
            placeholder="Search by MC#, DOT#, or Company"
            height="36px"
            width="350px"
            clearSearch={clearSearch}
            value={value}
            onKeyDown={(e) =>
              e.key === 'Enter' && !isDisabled && submitSearch()
            }
            onChange={(e) => setValue((e.target as HTMLInputElement).value)}
          />
          <Button
            width="64px"
            height="36px"
            isRounded={true}
            disabled={isDisabled}
            onClick={submitSearch}
          >
            <StyledIcon icon="search" color="white" disabled={isDisabled}/>
          </Button>
        </ToolbarContainer>
        {carrierSearchResultsState === 'initialView' ? (
          <TextContainer>
            <ViewText>Search for a carrier</ViewText>
          </TextContainer>
        ) : (
          <CarrierSearchResults />
        )}
        {getSnackbar()}
      </CapacitySearchContainer>
    </Container>
  );
};

export default Carriers;
