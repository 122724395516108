import React from 'react';
import { FilterInput, styled } from 'shamrock-clover-ui';
import { Flex } from '../../../components/Flex';
import { useCreditRequestQueryParams } from '../../../hooks/useQueryParams';
import { LabelText } from '../creditRequestStyles';

const InputContainer = styled.div<{ isError: boolean }>`
  height: 36px;

  > div {
    border: ${({ isError, theme }) =>
      isError ? `1px solid ${theme.colors.red[50]}` : 'none'};
  }
`;

const StyledInput = styled(FilterInput)`
  border: none;
  font-family: Proxima Nova;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  color: #333333;
  height: 36px;

  ::placeholder {
    color: #333333;
  }
`;

type Props = {
  onSearch: () => void;
};

const CustomerIdInput = ({ onSearch }: Props) => {
  const { addQueryParams, setQueryParams, queryParams } =
    useCreditRequestQueryParams();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    addQueryParams({
      customerId: event.target.value.toLocaleUpperCase().substring(0, 8),
    });
  };

  const handleKeyDown = (event: { key: string }) => {
    if (
      event.key === 'Enter' &&
      (customerId.length === 7 || customerId.length === 8) &&
      queryParams.requestType
    ) {
      onSearch();
    }
  };

  const customerId = queryParams.customerId ? queryParams.customerId : '';

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <LabelText>Customer Id</LabelText>
      <InputContainer
        isError={
          customerId.length !== 0 &&
          customerId.length !== 7 &&
          customerId.length !== 8
        }
      >
        <StyledInput
          placeholder="ID"
          height="30px"
          width="206px"
          onChange={onChange}
          value={customerId}
          clearSearch={() =>
            setQueryParams({ ...queryParams, customerId: undefined })
          }
          onKeyDown={handleKeyDown}
        ></StyledInput>
      </InputContainer>
    </Flex>
  );
};

export default CustomerIdInput;
