import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Flex, FlexItem } from './Flex';
import { Select, MenuItem, withStyles, CheckboxProps } from '@material-ui/core';
import MenuFilters from 'features/quoting/components/MenuFilters';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import { styled } from 'shamrock-clover-ui';
import Icon from './Icon';
import PausedUserSelectDialog from './PausedUserDialog';
import {
  DashboardUser,
  CAMUserStatus,
} from 'features/quoting/utils/camUserServices';
import { getUserLocalStorage } from 'utils/authUtils';
import { QuotesEnhancementsContext } from 'features/quoting/context/QuotesEnhancementsContext';
import * as FirebaseUtils from '../utils/firebaseUtils';
import useSnackbar from '../hooks/useSnackbar';

const UserFilterMenu = styled.div`
  padding: 16px 32px;
  background: #ffffff;
  box-shadow:
    0px 2px 2px rgba(0, 0, 0, 0.2),
    inset 0px -1px 0px #f3f6f7;
  margin-bottom: 5px;
`;

const SelectContainer = styled(Flex)`
  border-bottom: 2px solid #0091ea;
  width: 260px;
  div {
    width: 250px;
    max-height: 100px;
    font-family: 'Proxima Nova';
  }
`;

const SelectItemText = styled.div`
  font-family: 'Proxima Nova';
  width: 100%;
`;

const BlueCheckbox = withStyles({
  root: {
    color: '#0091ea',
    '&$checked': {
      color: '#0091ea',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const PausedUserSelector = () => {
  const [showPausedDialog, setShowPausedDialog] = useState(false);
  const [isPausing, setIsPausing] = useState(false);
  const user = getUserLocalStorage();
  const { showSnackbar } = useSnackbar();

  const {
    loadingByFilter,
    inactiveUsers,
    selectedUsers,
    camUsers,
    getCurrentUser,
    filterByUser,
    setSelectedUser,
    setPausedUserStatus,
    setSelectedUsers,
    getUsers,
  } = useContext(QuotesEnhancementsContext);

  const currentUser = getCurrentUser();

  useEffect(() => {
    getUsers(currentUser!);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      if (inactiveUsers.find((inactive) => inactive._id === user._id))
        setShowPausedDialog(true);
    }
  }, [inactiveUsers, user]);

  const onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const selected = event.target.value as string[];
    if (selected.some((val) => val === 'all')) {
      if (selected.length - 1 === camUsers.length) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(camUsers);
      }
      return;
    }

    const unselect = selectedUsers.some((val) => selected.includes(val._id));
    if (unselect) {
      const removed = selectedUsers.filter(
        (val) => !selected.includes(val._id),
      );
      setSelectedUsers(removed);
    } else {
      const matched = camUsers.filter((cam) => selected.includes(cam._id));
      Array.prototype.push.apply(matched, selectedUsers);
      setSelectedUsers(matched);
    }
  };

  const pauseCamUser = async () => {
    setShowPausedDialog(true);
    setIsPausing(true);
    if (user) {
      const body: CAMUserStatus = {
        userId: user._id,
        userEmail: user.email,
        assignTo: '',
        assignToId: '',
        isInactive: true,
      };
      await setPausedUserStatus(body)
        .then(async () => {
          await FirebaseUtils.logFirebaseEvent(
            FirebaseUtils.FirebaseEvents.CLICK,
            FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
            FirebaseUtils.FirebasePages.LIST,
            {
              description: 'Pause Activity',
            },
          );
          setIsPausing(false);
        })
        .catch((err) => {
          showSnackbar({
            message: 'Unable to pause user. Please try again.',
            type: 'error',
          });
          console.error('Unable to pause user.', err.toString());
          setIsPausing(false);
          setShowPausedDialog(false);
        });
    }
  };

  const handleOnClose = () => {
    let adminAssigned: string = '';
    if (selectedUsers.length > 0) {
      adminAssigned = selectedUsers
        .filter((item) => item.email.trim() !== '')
        .map((x) => x.email)
        .join(',');

      const selected = selectedUsers
        .filter((item) => item.email.trim() !== '')
        .map((x) => x.email);
      setSelectedUser(selected);
      filterByUser({
        adminAssigned,
        types: ['new', 'negotiating', 'verifying', 'booked', 'dismissed'],
      });
    }
  };
  return (
    <UserFilterMenu>
      <PausedUserSelectDialog
        isOpen={showPausedDialog}
        setIsOpen={setShowPausedDialog}
        camUsers={camUsers.filter((x) => x.email !== '')}
        curUser={user}
        isPausing={isPausing}
      />
      <Flex>
        <Flex
          justifyContent="space-around"
          style={{ borderWidth: 1, borderColor: 'black' }}
        >
          <FlexItem
            justifyContent="space-between"
            margin="0 24px 0 0"
            alignItems="center"
            width="48"
          >
            <Icon
              alt="pause cam user button"
              name={showPausedDialog ? 'CamPauseActive' : 'CamPauseDefault'}
              width={48}
              height={48}
              onHoverName="CamPauseDefaultHover"
              onClick={async () => pauseCamUser()}
            />
          </FlexItem>
          <FlexItem
            justifyContent="space-between"
            margin="0 24px 0 0"
            width="80px"
            alignItems="center"
          >
            <div
              style={{
                color: '#666666',
                fontSize: 18,
                fontFamily: 'Proxima Nova',
                fontWeight: '500',
              }}
            >
              View as
            </div>
          </FlexItem>

          <FlexItem
            justifyContent="space-between"
            margin="0 20px 0 0"
            alignItems="flex-end"
          >
            <SelectContainer>
              <Select
                style={{
                  color: '#666666',
                  fontFamily: 'Proxima Nova',
                  margin: 5,
                }}
                input={<Input />}
                disableUnderline
                value={selectedUsers}
                onChange={onChange}
                disabled={loadingByFilter}
                onClose={handleOnClose}
                defaultValue={'none'}
                multiple
                renderValue={(selected) => {
                  if (
                    (selected as DashboardUser[]).length === camUsers.length
                  ) {
                    return 'All Users';
                  }
                  return (selected as DashboardUser[])
                    .map((user) => user.fullName)
                    .join(', ');
                }}
                MenuProps={{
                  variant: 'menu',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {camUsers.map((user, i) => (
                  <MenuItem
                    key={user._id}
                    value={user._id}
                    style={{
                      height: 56,
                      color: '#666666',
                      padding: 16,
                      borderBottom: i === 0 ? '1px solid #666666' : '',
                    }}
                  >
                    <BlueCheckbox
                      checked={selectedUsers.indexOf(user) > -1}
                      size="small"
                    />
                    <SelectItemText>{user.fullName}</SelectItemText>
                    {user.pausedUser && (
                      <Icon
                        alt="pause icon"
                        name="CamPause"
                        width={20}
                        height={20}
                      />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </SelectContainer>
          </FlexItem>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
          style={{ gap: '16px' }}
        >
          <MenuFilters label="New" type={'new'} />
          <MenuFilters label="Negotiating" type={'negotiating'} />
          <MenuFilters label="Verifying" type={'verifying'} />
          <MenuFilters label="Booked Loads" type={'booked'} />
          <MenuFilters label="Dismissed" type={'dismissed'} />
        </Flex>
      </Flex>
    </UserFilterMenu>
  );
};

export default PausedUserSelector;
