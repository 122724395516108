import { styled } from 'shamrock-clover-ui';

interface FlexProps {
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end';
  justifyContent?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  alignContent?: 'stretch' | 'center' | 'flex-start' | 'flex-end';
  height?: string;
  width?: string;
  margin?: string;
  marginLeft?: string;
  marginTop?: string;
  padding?: string;
  cursor?: string;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  height: ${({ height }) => height && height};
  width: ${({ width }) => width && width};
  align-items: ${({ alignItems }) => alignItems && alignItems};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection && flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap && flexWrap};
  align-content: ${({ alignContent }) => alignContent && alignContent};
  margin: ${({ margin }) => margin && margin};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  padding: ${({ padding }) => padding && padding};
  cursor: ${({ cursor }) => cursor && cursor};
`;

interface FlexItemProps {
  flexGrow?: number;
  flexShrink?: number;
  alignSelf?: 'auto' | 'stretch' | 'center' | 'flex-start' | 'flex-end';
  flex?: number;
}

export const FlexItem = styled(Flex)<FlexItemProps>`
  flex-grow: ${({ flexGrow }) => flexGrow && flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink && flexShrink};
  align-self: ${({ alignSelf }) => alignSelf && alignSelf};
  flex: ${({ flex }) => flex && flex};
`;
