import { FC } from 'react';
import { styled } from 'shamrock-clover-ui';
import { Icon, Button } from 'shamrock-clover-ui';
import { IconName } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';

const ToolbarInputDivider = styled.div`
  width: 1px;
  height: 30px;
  background-color: #bbbbbb;
` as any;

const ToolbarInput = styled.div`
  background-color: white;
  border: 1px solid #bbbbbb;
  border-radius: 40px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 2px;
  font-size: 14px;
` as any;

const RightComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
` as any;

const StopsCount = styled.div`
  width: 10px;
  text-align: right;
  padding-right: 6px;
` as any;

const StyledButton = styled(Button)<{ isTopButton: boolean }>`
  width: 16px !important;
  height: 8px;
  margin-right: 12px;
  align-items: ${({ isTopButton }) =>
    isTopButton ? 'flex-end' : 'flex-start'};
`;

export type PillComponentProps = {
  value: number;
  setValue: (value: number) => void;
};

export const PillCounter: FC<React.PropsWithChildren<PillComponentProps>> = ({
  value,
  setValue,
}) => {
  const iconButton = (iconName: IconName, onClick: () => void) => {
    const iconSize = '8';
    return (
      <StyledButton
        color="white"
        isTopButton={iconName === 'dropdownUp'}
        isRounded
        onClick={onClick}
        icon={<Icon icon={iconName} size={iconSize} />}
      />
    );
  };

  return (
    <ToolbarInput>
      Additional Stops
      <ToolbarInputDivider />
      <RightComponent>
        <StopsCount>{value === 4 ? '4+' : value}</StopsCount>

        <div>
          {iconButton('dropdownUp', () =>
            Number.isNaN(value)
              ? setValue(1)
              : setValue(value === 4 ? 4 : value + 1),
          )}
          {iconButton(
            'dropdownDown',
            () => value && setValue(value === 0 ? 0 : value - 1),
          )}
        </div>
      </RightComponent>
    </ToolbarInput>
  );
};
