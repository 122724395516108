import * as React from "react";
import RateChangeLogCard from "./RateChangeLogCard";
import {
  GridContainer,
  Container,
  GridArea,
  ChangeLogCardContainer,
  RegionTitleText,
  ChangeLogSelectContainer,
  StyledInputLabel,
  StyledMenuItem,
  StyledFormControlChangeLog,
  SonarSeperator,
} from "./rateAdjustmentStyles";
import { useGetRateAdjustmentData } from "./useRateAdminData";
import { regions } from "./rateProAdminRegions";
import { Select } from "@material-ui/core";
import Icon from "../../components/Icon";

const RateChangeLog = () => {
  const { rateAdjustmenResult } = useGetRateAdjustmentData();
  const [region, setRegion] = React.useState<string>("All");
  const getOptions = () => {
    return [{ name: "All" }, ...regions]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((e) => <StyledMenuItem value={e.name}>{e.name}</StyledMenuItem>);
  };

  const getListByRegion = () => {
    if (region.toLowerCase() === "all") {
      return rateAdjustmenResult;
    } else {
      return rateAdjustmenResult?.filter(
        (e) =>
          e.region?.toLowerCase() === region.toLowerCase() ||
          e.region?.toLowerCase() === "all"
      );
    }
  };

  return (
    <Container>
      <GridContainer minWidth="360px">
        <GridArea gridArea="1 / 1 / 1 / 13">
          <ChangeLogCardContainer
            onClick={(e) => e.stopPropagation()}
            flexDirection="column"
          >
            <RegionTitleText
              fontSize="24px"
              lineHeight="29px"
              width="`122px"
              height="29px"
              color="#333333"
            >
              Change log
            </RegionTitleText>
            <ChangeLogSelectContainer>
              <Icon
                margin="41px 22px 41px 27px"
                width={24}
                height={16}
                name="FilterIcon"
                alt="FilterIcon"
              ></Icon>
              <StyledFormControlChangeLog size="small">
                <StyledInputLabel id="region-select-label">
                  Region
                </StyledInputLabel>
                <Select
                  labelId="region-select-label"
                  id="region-simple-select"
                  MenuProps={{ variant: "menu" }}
                  onChange={(
                    event: React.ChangeEvent<{
                      name?: string;
                      value: unknown;
                    }>
                  ) => {
                    setRegion(event.target.value as string);
                  }}
                  disableUnderline
                  value={region}
                >
                  {getOptions()}
                </Select>
                <SonarSeperator width="220px" />
              </StyledFormControlChangeLog>
            </ChangeLogSelectContainer>
          </ChangeLogCardContainer>
          {getListByRegion()?.map((e) => {
            return <RateChangeLogCard rateAdjustment={e} />;
          })}
        </GridArea>
      </GridContainer>
    </Container>
  );
};

export default RateChangeLog;
