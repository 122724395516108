import { useQuery } from 'react-query';
import {
  getAccessorialFees,
  getRateAdminAdjustment,
  getRateAdminAdjustmentReasons,
} from './rateAdminHttp';


export const useGetRateAdjustment = () => {
  return useQuery(['getRateAdminAdjustment'], () => getRateAdminAdjustment(), {
    refetchOnMount: true,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
    refetchOnWindowFocus: false,
  });
};

export const useGetRateAdjustmentData = () => {
  let results = useGetRateAdjustment();
  return { ...results, rateAdjustmenResult: results.data };
};

export const useGetRateAdjustmentReason = () => {
  return useQuery(['getRateAdminAdjustmentReason'], () =>
    getRateAdminAdjustmentReasons(),
  );
};

export const useGetRateAdjustmentReasonData = () => {
  let results = useGetRateAdjustmentReason();
  return { ...results, rateAdjustmenReasonsResult: results.data };
};

export const useGetAccessorialFees = () => {
  return useQuery(['getAccessorialFees'], () => getAccessorialFees());
}

export const useGetAccessorialFeesData = () => { 
  let results = useGetAccessorialFees();
  return { ...results, accessorialFeesResult: results.data };
}