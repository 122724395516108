import {
  StyledCurrencyFormatTextInput,
  StyledFeesCard,
  StyledFeesCardContainer,
  StyledFeesCardHeader,
  StyledFeesText,
} from '../rateAdjustmentStylesV2';
import { LinearProgress } from 'shamrock-clover-ui';
interface Props {
  value: {
    oneStop: number;
    twoStops: number;
    threeStops: number;
    fourPlusStops: number;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>, type: string) => void;
  isLoading: boolean;
}

const AdditionalStopsCard: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  isLoading,
}) => {
  return (
    <StyledFeesCard width="200px" height="318px">
      <StyledFeesCardHeader>
        <StyledFeesText>ADDITIONAL STOPS</StyledFeesText>
      </StyledFeesCardHeader>
      {isLoading && <LinearProgress />}
      <StyledFeesCardContainer height="258px">
        <StyledCurrencyFormatTextInput
          allowNegative={false}
          label="1 additional stop"
          value={value.oneStop}
          onChange={(e) => onChange(e, 'oneStop')}
          numberFormatProps={{
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          paddingTop={'28px'}
          isLoading={isLoading}
        />
        <StyledCurrencyFormatTextInput
          allowNegative={false}
          label="2 additional stops"
          value={value.twoStops}
          onChange={(e) => onChange(e, 'twoStops')}
          numberFormatProps={{
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          isLoading={isLoading}
        />
        <StyledCurrencyFormatTextInput
          allowNegative={false}
          label="3 additional stops"
          value={value.threeStops}
          onChange={(e) => onChange(e, 'threeStops')}
          numberFormatProps={{
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          isLoading={isLoading}
        />
        <StyledCurrencyFormatTextInput
          allowNegative={false}
          label="4+ additional stops"
          value={value.fourPlusStops}
          onChange={(e) => onChange(e, 'fourPlusStops')}
          numberFormatProps={{
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          isLoading={isLoading}
        />
      </StyledFeesCardContainer>
    </StyledFeesCard>
  );
};

export default AdditionalStopsCard;
