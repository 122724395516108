import React, { FunctionComponent } from 'react';
import './panelFooterSection.scss';
import { Button } from 'shamrock-clover-ui';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  font-family: proxima-nova;
  text-transform: capitalize;
`;
interface Props {
  onSubmit: Function;
}

const PanelFooterSection: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onSubmit,
}) => {
  return (
    <div className="creditRequestFooter">
      <div className="submitButton">
        <StyledButton
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </StyledButton>
      </div>
    </div>
  );
};

export default React.memo(PanelFooterSection);
