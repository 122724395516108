import * as React from 'react';

import {
  StyledChangeLogCard,
  StyleChangeLogTitleText,
  StyledCard,
} from '../rateChangeLogStylesV2';
import { CardContainer } from '../rateAdjustmentStylesV2';

interface Props {
  trailerType: string;
  flatbedNumberOfLoads: string;
  flatbedRtsRate: string;
  flatbedDatRate: string;
}

const ChangeLogFlatbedCardItem: React.FC<React.PropsWithChildren<Props>> = ({
  trailerType,
  flatbedNumberOfLoads,
  flatbedRtsRate,
  flatbedDatRate,
}) => {
  return (
    <StyledChangeLogCard height="325px">
      <CardContainer flexDirection="column">
        <StyleChangeLogTitleText
          fontSize="24px"
          color="#333333"
          paddingTop="20px"
          paddingLeft="8px"
        >
          {`${trailerType}:`}
        </StyleChangeLogTitleText>
        <StyleChangeLogTitleText
          fontSize="18px"
          color="#666666"
          fontWeight="600"
          paddingLeft="8px"
          paddingTop="12px"
        >
          DAT RATE
        </StyleChangeLogTitleText>
        <StyledCard borderBottom="1px solid #CCCCCC" flexDirection="row">
          <StyleChangeLogTitleText
            fontSize="18px"
            color="#666666"
            fontWeight="600"
          >
            {flatbedDatRate}
          </StyleChangeLogTitleText>
        </StyledCard>
        <StyledCard flexDirection="column" borderBottom="1px solid #CCCCCC">
          <StyleChangeLogTitleText
            fontSize="18px"
            color="#666666"
            fontWeight="600"
          >
            RTS History Override
          </StyleChangeLogTitleText>
          <StyleChangeLogTitleText
            fontSize="18px"
            color="#666666"
            fontWeight="400"
            paddingTop="18px"
          >
            {`${flatbedNumberOfLoads} Loads,`} <br />{' '}
            {`RTS rate ${flatbedRtsRate?.toUpperCase()}`}
          </StyleChangeLogTitleText>
        </StyledCard>
      </CardContainer>
    </StyledChangeLogCard>
  );
};

export default ChangeLogFlatbedCardItem;
