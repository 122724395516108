import AppHeader from 'components/AppHeader';
import RatePro from 'features/ratePro';
import RateProV2 from '../../ratePro/rateProV2/RateProV2';
import CreditRequest from 'features/creditRequest';
import RateAdjustment from 'features/rateProAdmin/RateAdjustment';
import RateAdjustmentV2 from 'features/rateProAdmin/RateAdjustmentV2';
import RateChangeLog from 'features/rateProAdmin/RateChangeLog';
import RateChangeLogV2 from 'features/rateProAdmin/RateChangeLogV2';
import {
  Routes,
  Route,
  Navigate,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  isRateProUser,
  isLoadBoardInternalAdminUser,
  isCAMUser,
  isCustomerBoardInternalAdminUser,
} from '../../../utils/authUtils';
import QuotingEnhancement from './QuotingEnhancement';
import QuotesEnhancementsWrapper from '../context/QuotesEnhancementsContext';
import useConditionalWrapper from '../hooks/useConditionalWrapper';
import { useFeatureFlags } from 'utils/featureFlagContext';
import { useAccDialogContext } from 'hooks/useAccessorialFeesDialog';
import { useEffect } from 'react';
import Capacity from 'features/carrierCapacity/Capacity';

const Quoting = () => {
  const defaultLocation = isLoadBoardInternalAdminUser()
    ? '/quotes'
    : '/ratePro';
  const { isSellRateEnabled, isRateAdjustmentEnabled, isCapacityEnabled } =
    useFeatureFlags();

  const location = useLocation();
  const navigate = useNavigate();
  const shouldWrap = location.pathname.includes('quotes');
  const {
    formHasChange,
    setShowDialog,
    discardClicked,
    setFormHasChange,
    setDiscardClicked,
    urlDestination,
    setUrlDestination,
  } = useAccDialogContext();

  useEffect(() => {
    if (discardClicked) {
      setShowDialog(false);
      setFormHasChange(false);
      if (urlDestination !== '') {
        navigate(urlDestination);
        setUrlDestination('');
      }
      setDiscardClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discardClicked]);

  const wrappedChildren = useConditionalWrapper(
    shouldWrap,
    <QuotesEnhancementsWrapper />,
    <>
      <AppHeader>
        <>
          {(isLoadBoardInternalAdminUser() ||
            isCAMUser() ||
            isCustomerBoardInternalAdminUser()) && (
            <NavLink
              to="/quotes"
              className={({ isActive }) =>
                isActive ? 'navItem active' : 'navItem'
              }
              onClick={(e) => {
                if (formHasChange) {
                  setUrlDestination('/quotes');
                  setShowDialog(true);
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
              }}
            >
              Quote Dashboard
            </NavLink>
          )}

          {isRateProUser() && (
            <NavLink
              to="/ratePro"
              className={({ isActive }) =>
                isActive ? 'navItem active' : 'navItem'
              }
              onClick={(e) => {
                if (formHasChange) {
                  setUrlDestination('/ratePro');
                  setShowDialog(true);
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
              }}
            >
              Rate Pro
            </NavLink>
          )}
          <NavLink
            to="/creditRequest"
            className={({ isActive }) =>
              isActive ? 'navItem active' : 'navItem'
            }
            onClick={(e) => {
              if (formHasChange) {
                setUrlDestination('/creditRequest');
                setShowDialog(true);
                e.preventDefault();
                e.stopPropagation();
                return;
              }
            }}
          >
            Credit Request
          </NavLink>
          {isCapacityEnabled && isRateProUser() && (
            <NavLink
              to="/carrierCapacity"
              className={({ isActive }) =>
                isActive ? 'navItem active' : 'navItem'
              }
              onClick={(e) => {
                if (formHasChange) {
                  setUrlDestination('/carrierCapacity');
                  setShowDialog(true);
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
              }}
            >
              Carrier Capacity
            </NavLink>
          )}
        </>
      </AppHeader>
      <Routes>
        {(isLoadBoardInternalAdminUser() ||
          isCAMUser() ||
          isCustomerBoardInternalAdminUser()) && (
          <>
            <Route
              path="/quotes/new"
              element={<QuotingEnhancement filteredType="new" />}
            />
            <Route
              path="/quotes/negotiating"
              element={<QuotingEnhancement filteredType="negotiating" />}
            />
            <Route
              path="/quotes/verifying"
              element={<QuotingEnhancement filteredType="verifying" />}
            />
            <Route
              path="/quotes/booked"
              element={<QuotingEnhancement filteredType="booked" />}
            />
            <Route
              path="/quotes/dismissed"
              element={<QuotingEnhancement filteredType="dismissed" />}
            />
            <Route
              path="/quotes"
              element={<QuotingEnhancement filteredType="new" />}
            />
          </>
        )}
        <Route
          path="/ratePro/rateAdjustment"
          element={
            isRateAdjustmentEnabled ? <RateAdjustmentV2 /> : <RateAdjustment />
          }
        />
        <Route
          path="/ratePro/changeLog"
          element={
            isRateAdjustmentEnabled ? <RateChangeLogV2 /> : <RateChangeLog />
          }
        />
        <Route
          path="/ratePro"
          element={isSellRateEnabled ? <RateProV2 /> : <RatePro />}
        />
        <Route path="/creditRequest" element={<CreditRequest />} />
        {isCapacityEnabled && (
          <>
            <Route
              path="/carrierCapacity/carriers"
              element={<Capacity view={'carrier'} />}
            />
            <Route
              path="/carrierCapacity"
              element={<Capacity view={'searchCapacity'} />}
            />
          </>
        )}
        <Route path="/" element={<Navigate replace to={defaultLocation} />} />
      </Routes>
    </>,
  );
  return <>{wrappedChildren}</>;
};

export default Quoting;
