import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const useCurrentLocation = () => {
  const [location, setLocation] = useState<string>('');
  const { pathname } = useLocation();
  const path = pathname.split('/')[2];
  const type = !path ? 'new' : path;

  useEffect(() => {
    setLocation(type);
  }, [location, type]);

  return { location };
};

export default useCurrentLocation;
