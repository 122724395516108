import './creditRequestPanelContainer.scss';
import React, { useState } from 'react';
import { CreditRequestPanel } from '../CreditRequestPanel';
import Spinner from 'components/Spinner';
import styled from 'styled-components';
import { CreditRequestAutoPopulateType } from '../../creditRequestTypes';

interface Props {
  toggleIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPanelVisible: boolean;
  autoPopulateData: CreditRequestAutoPopulateType;
  showSnackbar: Function;
  requestType: 'new' | 'adjustment';
}

export const ErrorLabel = styled.label`
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  padding-top: 30px;
`;

const CreditRequestPanelContainer: React.FC<React.PropsWithChildren<Props>> = ({
  toggleIsPanelOpen,
  isPanelVisible,
  autoPopulateData,
  showSnackbar,
  requestType,
}) => {
  const [isLoading, toggleIsLoading] = useState<boolean>(false);

  if (!isPanelVisible) return <></>;
  return (
    <div className="underlay">
      <div className="creditRequestContainer">
        <CreditRequestPanel
          toggleIsPanelOpen={toggleIsPanelOpen}
          toggleIsLoading={toggleIsLoading}
          autoPopulateData={autoPopulateData}
          showSnackbar={showSnackbar}
          requestType={requestType}
        />
        {isLoading ? (
          <div className="creditRequestWaiting">
            <Spinner />
            <ErrorLabel>Loading... </ErrorLabel>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CreditRequestPanelContainer;
