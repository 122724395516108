import { useQuery } from 'react-query';
import { getFeature } from '../utils/featureUtils';

export const useFeatureFlag = (id: string) => {
  return useQuery(['featureFlag', id], () => getFeature(id));
};

export const useToggleBookItNowFeatureFlag = () => {
  let results = useFeatureFlag('RTD-1154-Mobile');
  return { ...results, isBookItNowToggleFeatureEnabled: results.data };
};

export const useBookedLoadsAlertFeatureFlag = () => {
  let results = useFeatureFlag('RTD-2317');

  return {
    ...results,
    isBookedLoadsAlertEnabled: results.data,
  };
};

export const useCAMIntegrationFeatureFlag = () => {
  let results = useFeatureFlag('RTD-2731-CAM');
  return { ...results, isCAMIntegrationEnabled: results.data };
};
