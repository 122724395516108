import * as React from "react";
import {
  RegionTitleText,
  StyledCard,
  CardContainer,
  EquipmentContainer,
} from "../rateAdjustmentStyles";

import FlatbedSonarCard from "./FlatbedSonarCard";
import FlatbedHistoryCard from "./FlatbedHistoryCard";

const FlatbedRow = () => {
  return (
    <StyledCard height="300px">
      <CardContainer flexDirection="column">
        <RegionTitleText
          fontSize="24px"
          lineHeight="29px"
          width="450px"
          height="29px"
        >
          Flatbed loads
        </RegionTitleText>
        <EquipmentContainer>
          <FlatbedSonarCard />
          <FlatbedHistoryCard />
        </EquipmentContainer>
      </CardContainer>
    </StyledCard>
  );
};

export default FlatbedRow;
