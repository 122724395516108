import * as React from 'react';
import { Flex } from 'components/Flex';
import { styled } from 'shamrock-clover-ui';
import { PlaceTypes } from 'features/search/location/LocationPicker';
import TrailerPicker from './TrailerPicker';
import CustomerEntry from './CustomerEntry';
import GetRatesButton from './GetRatesButton';
import { NewRateQuery } from './ratesTypes';
import { NewPickupDeliveryPicker } from 'features/search/location/NewPickupDeliveryPicker';
import { useRateProQueryParams } from 'hooks/useQueryParams';
import { useFeatureFlags } from 'utils/featureFlagContext';
import CustomerEntryV2 from './CustomerEntryV2';

const ToolbarContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  min-height: 55px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 16px;
  gap: 16px;
`;

const Divider = styled.div`
  height: 36px;
  background-color: #bbbbbb;
  width: 1px;
  margin-top: 19px;
`;

const DistanceText = styled.div`
  color: ${({ theme }) => theme.colors.gray[90]};
  font-size: 14px;
  margin-top: 29px;
  width: 130px;
`;

type Props = {
  getRatesOnClick: React.Dispatch<
    React.SetStateAction<NewRateQuery | undefined>
  >;
  mileage?: number;
  isLoading: boolean;
};

export type RateProForm = {
  pickupCity?: string;
  pickupState?: string;
  pickupPostalCode?: string;
  deliveryCity?: string;
  deliveryState?: string;
  deliveryPostalCode?: string;
  trailerType?: string;
  customer?: string;
};

const RateProToolbar = ({ getRatesOnClick, mileage, isLoading }: Props) => {
  const { isSellRateEnabled } = useFeatureFlags();
  const { setQueryParams, queryParams } = useRateProQueryParams();
  const [hasSelectedCustomerCode, setHasSelectedCustomerCode] =
    React.useState<boolean>(false);

  const updatePickupLocation = (
    city: string,
    state: string,
    postalCode?: string,
  ) => {
    const { pickupCity, pickupState, pickupPostalCode, ...restQueryParams } =
      queryParams;

    const newParams = {
      ...restQueryParams,
      ...(city && { pickupCity: city }),
      ...(state && { pickupState: state }),
      ...(postalCode && { pickupPostalCode: postalCode }),
    };

    setQueryParams(newParams);
  };

  const updateDeliveryLocation = (
    city: string,
    state: string,
    postalCode?: string,
  ) => {
    const {
      deliveryCity,
      deliveryState,
      deliveryPostalCode,
      ...restQueryParams
    } = queryParams;

    const newParams = {
      ...restQueryParams,
      ...(city && { deliveryCity: city }),
      ...(state && { deliveryState: state }),
      ...(postalCode && { deliveryPostalCode: postalCode }),
    };

    setQueryParams(newParams);
  };

  return (
    <ToolbarContainer flexWrap="wrap">
      <NewPickupDeliveryPicker
        value={queryParams}
        updateDeliveryLocation={updateDeliveryLocation}
        updatePickupLocation={updatePickupLocation}
        placeType={PlaceTypes.ALL}
      />
      <TrailerPicker />
      {isSellRateEnabled ? (
        <CustomerEntryV2
          hasSelectedCustomerCode={hasSelectedCustomerCode}
          setHasSelectedCustomerCode={setHasSelectedCustomerCode}
        />
      ) : (
        <CustomerEntry />
      )}
      <Divider />
      <DistanceText>Distance: {mileage ? `${mileage} miles` : ``}</DistanceText>
      <Flex margin="19px 0px 0px 0px">
        <GetRatesButton
          getRatesOnClick={getRatesOnClick}
          isLoading={isLoading}
          hasSelectedCustomerCode={hasSelectedCustomerCode}
        />
      </Flex>
    </ToolbarContainer>
  );
};

export default RateProToolbar;
