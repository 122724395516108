import { GetQuotes, InitialEnhancementsState } from "../utils/quoteTypes";

interface ActionTypes {
  type: string;
  payload: GetQuotes;
}

const quoteEnhancementsReducer = (
  state: InitialEnhancementsState,
  action: ActionTypes
) => {
  const { status, quote } = action.payload;
  switch (action.type) {
    case "GET_QUOTES":
    case "GET_QUOTE":
      return {
        ...state,
        [status as keyof InitialEnhancementsState]: quote,
      };
    case "SET_QUOTE":
      return {
        ...state,
        [status]: [
          ...state[status as keyof InitialEnhancementsState],
          ...quote,
        ],
      };
    case "SEARCH":
      return state;
    default:
      return state;
  }
};

export default quoteEnhancementsReducer;
