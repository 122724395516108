import { useState, useEffect } from 'react';
import AccessorialFeesButtons from './components/AccessorialFeesButtons';
import AccessorialFeesColumn from './components/AccessorialFeesColumn';
import {
  RegionTitleText,
  StyledCard,
  CardContainer,
} from './rateAdjustmentStylesV2';
import useSnackbar from 'hooks/useSnackbar';
import { useGetAccessorialFeesData } from './useRateAdminData';
import { createAccessorialFees } from './rateAdminHttp';
import { useAccDialogContext } from 'hooks/useAccessorialFeesDialog';

export interface AccessorialFeesValues {
  driverAssist: number;
  hazmat: number;
  palletJack: number;
  ramps: number;
  sameDay: number;
  tarps: number;
  tankerEndorsed: number;
  team: number;
  additionalStops: {
    oneStop: number;
    twoStops: number;
    threeStops: number;
    fourPlusStops: number;
  };
}

const AccessorialFees = () => {
  // initial undefined values for accessorial fees
  const initialAccessorialFees: AccessorialFeesValues = {
    driverAssist: 0,
    hazmat: 0,
    palletJack: 0,
    ramps: 0,
    sameDay: 0,
    tarps: 0,
    tankerEndorsed: 0,
    team: 0,
    additionalStops: {
      oneStop: 0,
      twoStops: 0,
      threeStops: 0,
      fourPlusStops: 0,
    },
  };

  const { setFormHasChange, resetForm, setResetForm, resetDialogHook } =
    useAccDialogContext();
  const { accessorialFeesResult: AccessorialFeesApiResult } =
    useGetAccessorialFeesData();
  const [defaultAccessorialFees, setDefaultAccessorialFees] =
    useState<AccessorialFeesValues>(initialAccessorialFees);
  const [accessorialFees, setAccessorialFees] = useState<AccessorialFeesValues>(
    initialAccessorialFees,
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const accessorialFeesValuesUnchanged: boolean = (() => {
    // compare current values to default values
    if (
      accessorialFees?.driverAssist !== defaultAccessorialFees?.driverAssist ||
      accessorialFees?.hazmat !== defaultAccessorialFees?.hazmat ||
      accessorialFees?.palletJack !== defaultAccessorialFees?.palletJack ||
      accessorialFees?.ramps !== defaultAccessorialFees?.ramps ||
      accessorialFees?.sameDay !== defaultAccessorialFees?.sameDay ||
      accessorialFees?.tarps !== defaultAccessorialFees?.tarps ||
      accessorialFees?.tankerEndorsed !==
        defaultAccessorialFees?.tankerEndorsed ||
      accessorialFees?.team !== defaultAccessorialFees?.team ||
      accessorialFees?.additionalStops.oneStop !==
        defaultAccessorialFees?.additionalStops.oneStop ||
      accessorialFees?.additionalStops.twoStops !==
        defaultAccessorialFees?.additionalStops.twoStops ||
      accessorialFees?.additionalStops.threeStops !==
        defaultAccessorialFees?.additionalStops.threeStops ||
      accessorialFees?.additionalStops.fourPlusStops !==
        defaultAccessorialFees?.additionalStops.fourPlusStops
    ) {
      return false;
    } else {
      return true;
    }
  })();

  useEffect(() => {
    if (resetForm) {
      setAccessorialFees(defaultAccessorialFees);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  useEffect(() => {
    if (AccessorialFeesApiResult && AccessorialFeesApiResult.length > 0) {
      const currentAccessorialFees =
        AccessorialFeesApiResult[AccessorialFeesApiResult.length - 1];
      setDefaultAccessorialFees(currentAccessorialFees);
      setAccessorialFees(currentAccessorialFees);
      setIsLoading(false);
    }
  }, [AccessorialFeesApiResult]);

  useEffect(() => {
    setIsDisabled(accessorialFeesValuesUnchanged);
    setFormHasChange(!accessorialFeesValuesUnchanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorialFeesValuesUnchanged]);

  const { showSnackbar } = useSnackbar();

  const saveClick = async () => {
    setIsLoading(true);
    await createAccessorialFees(accessorialFees);
    setDefaultAccessorialFees(accessorialFees);
    setIsDisabled(true);
    setIsLoading(false);
    resetDialogHook();
    showSnackbar({
      message: `Changes saved`,
      type: 'success',
    });
  };

  const discardClick = () => {
    if (defaultAccessorialFees) {
      setResetForm(true);
      updateAccessorialFees(defaultAccessorialFees);
    }
    showSnackbar({
      message: `Changes discarded`,
      type: 'info',
    });
  };

  const updateAccessorialFees = (newFees: AccessorialFeesValues) => {
    if (resetForm) {
      resetDialogHook();
      setAccessorialFees(newFees);
      return;
    }
    setAccessorialFees(newFees);
  };

  return (
    <StyledCard height="1750px" width="248px">
      <CardContainer flexDirection="column">
        <RegionTitleText
          fontSize="24px"
          lineHeight="29px"
          width="200px"
          height="29px"
          padding="16px"
          paddingLeft="24px"
        >
          Accessorial Fees
        </RegionTitleText>
        <AccessorialFeesButtons
          isDisabled={isDisabled}
          saveClick={saveClick}
          discardClick={discardClick}
        />
        <AccessorialFeesColumn
          accessorialFees={accessorialFees || initialAccessorialFees}
          setAccessorialFees={updateAccessorialFees}
          isLoading={isLoading}
        />
      </CardContainer>
    </StyledCard>
  );
};

export default AccessorialFees;
