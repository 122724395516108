import FilterListIcon from '@material-ui/icons/FilterList';
import { Flex, FlexItem } from 'components/Flex';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import MenuFilters from './MenuFilters';
import { styled } from 'shamrock-clover-ui';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';

const EnhancementsFiltersMenu = styled.div`
  padding: 16px 32px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2), inset 0px -1px 0px #f3f6f7;
  margin-bottom: 5px;
`;

const FilterEnhancement = () => {
  const [value, setValue] = useState<number>(1);
  const { getCurrentUser, filterByUser, adminAssing, loadingByFilter } =
    useContext(QuotesEnhancementsContext);

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (value && currentUser?.email) {
      const filters =
        value === 2
          ? {
              types: ['negotiating', 'verifying', 'booked', 'dismissed'],
              adminAssigned: 'allUser',
            }
          : {
              types: ['negotiating', 'verifying', 'booked', 'dismissed'],
              adminAssigned: currentUser.email,
            };
      filterByUser(filters);
    }
  }, [value, adminAssing, filterByUser, currentUser?.email]);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as number);
  };
  return (
    <EnhancementsFiltersMenu>
      <Flex>
        <Flex justifyContent="space-around">
          <FlexItem justifyContent="space-between" margin="0 20px 0 0">
            <FilterListIcon
              style={{
                width: '32px',
                height: '32px',
                position: 'relative',
                top: '10px',
              }}
            />
          </FlexItem>
          <FlexItem justifyContent="space-between" margin="0 20px 0 0">
            <MenuItem value={0} style={{ padding: '20px' }}>
              Assigned To
            </MenuItem>
          </FlexItem>
          <FlexItem justifyContent="space-between" margin="0 20px 0 0">
            <FormControl variant="standard" style={{ width: '247px' }}>
              <Select
                value={value}
                onChange={handleChange}
                style={{ marginTop: '5px', padding: '10px' }}
              >
                <MenuItem value={1} disabled={loadingByFilter}>
                  {currentUser?.firstname}
                </MenuItem>
                <MenuItem value={2} disabled={loadingByFilter}>
                  All users
                </MenuItem>
              </Select>
            </FormControl>
          </FlexItem>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
          style={{ gap: '16px' }}
        >
          <MenuFilters label="New" type={'new'} />
          <MenuFilters label="Negotiating" type={'negotiating'} />
          <MenuFilters label="Verifying" type={'verifying'} />
          <MenuFilters label="Booked Loads" type={'booked'} />
          <MenuFilters label="Dismissed" type={'dismissed'} />
        </Flex>
      </Flex>
    </EnhancementsFiltersMenu>
  );
};

export default FilterEnhancement;
