// packages
import React, { useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// components
import Icon from 'components/Icon';
import BookItNowToggleButton from './BookItNowToggleButton';
import { Flex } from './Flex';

// styles
import './AppHeader.scss';

// utils
import {
  getUserLocalStorage,
  isBookItNowToggleUser,
  isRateProAdmin,
  isRateProUser,
  redirectToLogout,
} from '../utils/authUtils';
import { RTS_PRO_URL } from '../utils/constants';
import RateProSubHeader from '../features/ratePro/RateProSubHeader';
import QuoteSearchInput from 'features/quoting/components/QuoteSearchInput';
import { useAccDialogContext } from 'hooks/useAccessorialFeesDialog';
import CapacitySubHeader from 'features/carrierCapacity/components/CapacitySubHeader';
import { useLocation } from 'react-router-dom';

interface HeaderProps {
  children: React.ReactNode;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  children,
}: HeaderProps) => {
  const location = useLocation();
  const { formHasChange, setShowDialog, discardClicked, setResetForm } =
    useAccDialogContext();

  const [menuClicked, setMenuClicked] = useState(false);
  const [target, setTarget] = useState<EventTarget & HTMLDivElement>();

  const path = location.pathname.toLowerCase();
  const displaySubMenu = path.includes('/ratepro') && isRateProAdmin();
  const displayCapacitySubMenu =
    path.indexOf('/carriercapacity') !== -1 && isRateProUser();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleSettingsClicked = () => {
    setAnchorEl(null);
    const settingsUrl = `${RTS_PRO_URL}#/login?cb=application.users.accounts`;
    window.location.assign(settingsUrl);
  };

  const handleLogoutClicked = () => {
    setAnchorEl(null);
    redirectToLogout();
  };

  const user = getUserLocalStorage();
  const firstName = user?.firstname || '';
  const lastName = user?.lastname ? ` ${user?.lastname}` : '';

  useEffect(() => {
    if (discardClicked && menuClicked && target) {
      setResetForm(true);
      setAnchorEl(target);
      setMenuClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discardClicked, menuClicked]);

  return (
    <div className="header">
      <div className="appBarTopMenu">
        <div className="toolBar">
          <div className="rightSideHeader">
            <div
              className="menuItem"
              onClick={(event) => {
                if (formHasChange) {
                  setShowDialog(true);
                  setTarget(event.currentTarget);
                  setMenuClicked(true);
                  event.preventDefault();
                  event.stopPropagation();
                  return;
                }
                setAnchorEl(event.currentTarget);
              }}
            >
              {firstName + lastName}
              <div
                className={
                  anchorEl === undefined ? '' : anchorEl ? 'open' : 'closed'
                }
              >
                <ArrowDropDownIcon />
              </div>
            </div>
          </div>
          <Menu
            classes={{ paper: 'menuRoot' }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={handleSettingsClicked}>
              <div className="menuItem">Settings</div>
            </MenuItem>
            <MenuItem onClick={handleLogoutClicked}>
              <div className="menuItem">Log Out</div>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="appBarPrimaryNavigation">
        <Flex alignItems="center" height="100%" width="50%">
          <Icon alt="logo" name="RTSProLogo" padding="0px 32px 0px 0px" />
          {children}
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end" width="50%">
          {path.includes('/quotes') ? (
            <>
              <QuoteSearchInput />
              {isBookItNowToggleUser() && <BookItNowToggleButton />}
            </>
          ) : null}
        </Flex>
      </div>
      {displaySubMenu && <RateProSubHeader />}
      {displayCapacitySubMenu && <CapacitySubHeader />}
    </div>
  );
};

export default Header;
