import React, { FC, useContext } from 'react';
import { Flex } from 'components/Flex';
import Icon from 'components/Icon';
import { styled, Button, SingleSelectMenu } from 'shamrock-clover-ui';
import { Quote } from '../utils/quoteTypes';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import { useQuotingQueryParams } from 'hooks/useQueryParams';

export const QuotedText = styled.div`
  cursor: pointer;
  font-family: proxima-nova;
  user-select: none;
  font-size: ${({ theme }) => theme.font.size.xsmall};
`;

const TextContainer = styled(Flex)`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue[50]};
  line-height: 1;
  display: flex;
  height: 40px;
  width: 188px;
  align-items: center;
  justify-content: space-around;
  padding: 0px 0px 0px 12px;
  font-size: ${({ theme }) => theme.font.size.xsmall};
`;

interface AcceptedButtonPros {
  quote: Quote;
}

const QuotingInput: FC<React.PropsWithChildren<AcceptedButtonPros>> = ({
  quote,
}) => {
  const { cancellAceeptance } = useContext(QuotesEnhancementsContext);
  const { quotes, orderId } = quote;
  const { history, userId } = quotes[0];

  const {
    setPathAndQuery,
    queryParams: { orderId: _orderId },
  } = useQuotingQueryParams();

  const lastHistoryItem = history[history.length - 1];

  const onClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const onMenuItemClick = async (option?: any) => {
    if (option.key === 'cancel') {
      cancellAceeptance({ orderId, userId });
      setPathAndQuery('negotiating', { orderId: _orderId });
    }
  };

  return (
    <div onClick={onClick}>
      <SingleSelectMenu
        VisualElement={
          <Button width="84%" buttonStyle="outlined">
            <TextContainer>
              <QuotedText>$</QuotedText>
              <QuotedText>
                {lastHistoryItem?.amount.toLocaleString()} ACCEPTED
              </QuotedText>
              <Icon name="DownArrowBlack" alt="down arrow" onClick={() => {}} />
            </TextContainer>
          </Button>
        }
        onOptionSelected={(option) => onMenuItemClick(option)}
        menuOptions={[{ key: 'cancel', optionName: 'Cancel Acceptance' }]}
        selectedOption={undefined}
        menuLocation="below"
        fontSize="14"
      />
    </div>
  );
};

export default QuotingInput;
