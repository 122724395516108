import { FC } from 'react';
import { Button } from 'shamrock-clover-ui';
import { styled } from 'shamrock-clover-ui';
import * as FirebaseUtils from '../../../utils/firebaseUtils';
import { findLast } from 'lodash';
import { QuoteHistoryItem, ButtonsInputsProps } from '../utils/quoteTypes';
import { useContext } from 'react';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import { useQuotingQueryParams } from 'hooks/useQueryParams';
import { getTimerDisplay } from '../utils/quoteUtils';
import useCurrentLocation from '../hooks/useCurrentLocation';

export const DialogContainer = styled.div``;

const QuotingMarkAsComplete: FC<
  React.PropsWithChildren<ButtonsInputsProps>
> = ({ userId, orderId, history, carrierName, mcleodId, isBookNow }) => {
  const { markQuoteAsComplete, getStatusFromQuoteContext } = useContext(
    QuotesEnhancementsContext,
  );

  const { location } = useCurrentLocation();

  const { setPathAndQuery } = useQuotingQueryParams();

  const getAmount = (history: QuoteHistoryItem[]) => {
    let last = findLast(history);
    return last ? last.amount : '';
  };

  const onCompleteClick = () => {
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.COMPLETE,
      FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
      FirebaseUtils.FirebasePages.DETAILS,
      {
        carrierName,
        isBookNow,
        orderNumber: orderId,
        description: 'Mark as Booked',
        status: getStatusFromQuoteContext(orderId),
        timer: getTimerDisplay(history),
        quoteAmount: history ? getAmount(history) : '',
        mcleodId: mcleodId ? mcleodId : '',
      },
    );
    markQuoteAsComplete({ userId, orderId });
    setPathAndQuery(location);
  };

  return history.length > 0 ? (
    <Button color="green" onClick={() => onCompleteClick()}>
      Mark as Booked
    </Button>
  ) : null;
};

export default QuotingMarkAsComplete;
