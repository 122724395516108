import { styled, SmrTable } from 'shamrock-clover-ui';
import { Column, Row } from 'components/cloverTable/TableTypes';
import { FC, useContext, useEffect, useState } from 'react';
import { RtsLoadHistoryRow } from 'features/ratePro/loadHistory/types';
import { CarrierCapacityContext } from 'features/carrierCapacity/context/CarrierCapacityContext';
import { ResultsState } from 'features/carrierCapacity/CapacityTypes';
import { getCarrierLoadHistory } from 'features/carrierCapacity/ratesApi';

const TABLE_COLUMNS: Column[] = [
  {
    key: 'orderId',
    label: 'Order ID',
    isSortable: false,
    width: 10.7,
  },
  {
    key: 'pickupDate',
    label: 'Pickup Date',
    isSortable: false,
    width: 11.8,
  },
  {
    key: 'customer',
    label: 'Customer',
    isSortable: false,
    width: 14,
  },
  {
    key: 'equipment',
    label: 'Equipment',
    isSortable: false,
    width: 11.8,
  },
  {
    key: 'origin',
    label: 'Origin',
    isSortable: false,
    width: 15,
  },
  {
    key: 'destination',
    label: 'Destination',
    isSortable: false,
    width: 15,
  },
  {
    key: 'miles',
    label: 'Miles',
    isSortable: false,
    width: 8.1,
  },
  {
    key: 'customerRate',
    label: 'Customer Rate',
    isSortable: false,
    width: 10,
  },
];

const DetailsContainer = styled.div`
  width: 100%;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 16px;
  flex-grow: 1;
  box-sizing: border-box;
  gap: 16px;
  box-shadow:
    0px 1px 3px 0px #00000033,
    0px 2px 1px 0px #0000001f,
    0px 1px 1px 0px #00000024;
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border: hidden;
    border-bottom: 1px solid #cccccc;
  }
  thead {
    position: sticky;
    top: 0;
    th {
      span {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
  tbody {
    font-size: 14px;
    td {
      font-size: 14px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
`;

const TableHeader = styled.div`
  height: 37px;
  display: flex;
  align-items: center;
  background: transparent;
  width: 100%;
  strong {
    font-size: 24px;
    font-weight: 500;
    margin-right: 10px;
  }
  font-size: 20px;
  font-weight: 300;
`;

const convertHistoryRowtoTableRow = (history: RtsLoadHistoryRow): Row => {
  const origin = `${history.originCityName}, ${history.originState}`;
  const destination = `${history.destCityName}, ${history.destState}`;
  const pickupDate = new Date(history.pickupDate);

  const pickupDateString = pickupDate.toLocaleDateString('en-US', {
    timeZone: 'America/Chicago',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formattedCustomerRate = formatter.format(history.customerRate);

  return {
    cells: [
      { value: history.orderId, content: history.orderId },
      { value: pickupDateString, content: pickupDateString },
      { value: history.customer, content: history.customer },
      { value: history.equipment, content: history.equipment },
      { value: origin, content: origin },
      { value: destination, content: destination },
      { value: history.miles.toString(), content: history.miles.toString() },
      {
        value: formattedCustomerRate,
        content: formattedCustomerRate,
      },
    ],
  };
};

type CarrierLoadHistoryProps = {
  selectedRowIndex: number;
};

export const CarrierLoadHistory: FC<CarrierLoadHistoryProps> = ({
  selectedRowIndex,
}) => {
  const { lanesAndCapacity, carriers, carrierPagination } = useContext(
    CarrierCapacityContext,
  );

  const [historyResultsState, setHistoryResultsState] =
    useState<ResultsState>('initialView');

  const [loadHistory, setLoadHistory] = useState<RtsLoadHistoryRow[]>([]);

  const companyInfo =
    lanesAndCapacity[
      carrierPagination.carrierIndex !== undefined
        ? carrierPagination.carrierIndex
        : selectedRowIndex
    ] ||
    carriers[
      carrierPagination.carrierIndex !== undefined
        ? carrierPagination.carrierIndex
        : selectedRowIndex
    ];

  const carrierId = companyInfo.carrier.mcleodCarrierId;

  useEffect(() => {
    const getHistory = async (carrierId: string) => {
      console.log(`getHistory for carrierId: ${carrierId}`);
      if (carrierId) {
        setHistoryResultsState('isLoading');
        try {
          const history = await getCarrierLoadHistory(carrierId);
          console.log('setting history up for carrierId: ', carrierId);
          console.log('saving history: ', history);
          setLoadHistory(history);
          setHistoryResultsState('resultsAvailable');
        } catch (error) {
          setHistoryResultsState('error');
        }
      }
    };
    getHistory(carrierId);
  }, [carrierId]);

  const dataRows = loadHistory
    ? loadHistory.map(convertHistoryRowtoTableRow)
    : [];

  return (
    <>
      <DetailsContainer>
        <TableHeader>
          <strong>90 Day History</strong>
          {historyResultsState === 'resultsAvailable' && (
            <>
              : {dataRows.length} {dataRows.length === 1 ? 'Result' : 'Results'}
            </>
          )}
        </TableHeader>
        <TableContainer id="smr-table-container">
          <SmrTable
            columns={TABLE_COLUMNS}
            rows={dataRows}
            loading={historyResultsState === 'isLoading'}
            emptyMessage="No load history."
            footerOptions={{
              rowsPerPage: 10,
              page: 1,
              totalRowsDisplay: 'rows',
              rowsPerPageSelector: true,
              height: 40,
            }}
          />
        </TableContainer>
      </DetailsContainer>
    </>
  );
};
