import * as React from 'react';
import {
  RegionTitleText,
  StyledCard,
  CardContainer,
  EquipmentContainer,
} from '../rateAdjustmentStylesV2';

import EquipmentCardV2 from './EquipmentCardV2';
import EquipmentHistoryV2 from './EquipmentHistoryV2';

interface Props {
  title: string;
}

const EquipmentRowV2: React.FC<React.PropsWithChildren<Props>> = ({ title }) => {
  return (
    <StyledCard height="auto" borderBottom="1px solid #CCCCCC">
      <CardContainer flexDirection="column">
        <RegionTitleText
          fontSize="24px"
          lineHeight="29px"
          width="450px"
          height="29px"
          padding="0px"
        >
          {`${title} Loads`}
        </RegionTitleText>
        <EquipmentContainer flexDirection='row' flexWrap='wrap'>
          <EquipmentCardV2 trailerType={title} datRateValue="LOW" />
          <EquipmentCardV2 trailerType={title} datRateValue="AVERAGE" />
          <EquipmentCardV2 trailerType={title} datRateValue="HIGH" />
          <EquipmentHistoryV2
            trailerType={title.toLowerCase() as 'van' | 'reefer'}
          />
        </EquipmentContainer>
      </CardContainer>
    </StyledCard>
  );
};

export default EquipmentRowV2;
