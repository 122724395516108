import { styled } from 'shamrock-clover-ui';

interface PaginationFooterContainerProps {
  height?: number;
}

export const StyledPaginationFooterContainer = styled.div<PaginationFooterContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || 22}px;
  padding: 14px 16px;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  color: ${(props) => props.theme.colors.gray[90]};
`;

export const StyledPaginationFooterRowsPerPageContainer = styled.div`
  margin-right: 26px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const StyledPaginationFooterDropdown = styled.select`
  border-radius: 0;
  border: none;
  text-align: center;
  font-size: 12px;
  background-color: ${(props) => props.theme.white};
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.gray[20]};
  }
`;

export const StyledPaginationFooterCurrentPageContainer = styled.div`
  margin-right: 16px;
  width: 80px;
`;

export const StyledPaginationFooterArrowsContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
`;
