import { FC } from 'react';
import { Flex } from 'components/Flex';
import { CardHeaderProps, QuoteData } from '../utils/quoteTypes';
import {
  HeaderContainer,
  CustomPointer,
  AssignedText,
  LabelContainer,
  StyledIcon,
} from '../styledComponents/QuoteCarHeaderStyles';
import {
  getLastQuoteUpdated,
  isBookItNow,
  isDeclined,
  isQuoteReady,
} from '../utils/quoteUtils';
import { CancelRounded } from '@material-ui/icons';
import Timer from './Timer';
import RemoveButton from './RemoveButton';
import useTimer from '../hooks/useTimer';
import { styled } from 'shamrock-clover-ui';
import useCurrentLocation from '../hooks/useCurrentLocation';
import { useBookedLoadsAlertFeatureFlag } from 'hooks/useFeatureFlag';

const CancelIcon = styled(CancelRounded)`
  color: red;
  position: relative;
  top: 4px;
`;

const WarningTag = styled.span<{ color?: string }>`
  position: relative;
  bottom: 3px;
  color: ${({ color }) => (color ? color : 'inherit')};
  text-transform: uppercase;
`;

const QuoteCardHeader: FC<React.PropsWithChildren<CardHeaderProps>> = ({
  adminAssigned,
  cancelledButton,
  orderId,
  declineQuote,
  quote,
  inactiveAssignedUser,
}) => {
  const quoteData: QuoteData = quote.quotes[0];
  const { userId } = quoteData;
  const { isBookedLoadsAlertEnabled } = useBookedLoadsAlertFeatureFlag();

  const lastQuoteUpdated =
    quote.quotes.length > 0
      ? getLastQuoteUpdated(quote.quotes, 10)
      : quote.quotes[0];

  const { hours, minutes, seconds, expired } = useTimer(
    lastQuoteUpdated.history,
    10,
  );
  const { location } = useCurrentLocation();

  return (
    <HeaderContainer flag="none">
      <Flex justifyContent="space-between">
        <Flex>
          <LabelContainer>
            {inactiveAssignedUser ? (
              <StyledIcon
                name="CamPauseDefaultHover"
                alt="camPauseDefaultHover"
                width={24}
              />
            ) : (
              <CustomPointer />
            )}
            <AssignedText>{adminAssigned || 'Unassinged'}</AssignedText>
          </LabelContainer>
          {isBookItNow(quoteData) ? (
            <LabelContainer>
              <CustomPointer color="#FF9000" />
              <AssignedText>Book It Now</AssignedText>
            </LabelContainer>
          ) : isQuoteReady(quoteData) ? (
            isBookedLoadsAlertEnabled &&
            quoteData.history[quoteData.history.length - 1].isInternalUser &&
            quoteData.history[quoteData.history.length - 1].status ===
              'approved' ? null : expired &&
              quoteData.history[quoteData.history.length - 1].isInternalUser ? (
              <div>
                <CancelIcon /> <WarningTag color="red">Expired</WarningTag>
              </div>
            ) : (
              <LabelContainer>
                <CustomPointer color="#72BF44" />
                <AssignedText>Ready</AssignedText>
              </LabelContainer>
            )
          ) : isDeclined(quoteData.history) ? (
            <div>
              <CancelIcon />{' '}
              {lastQuoteUpdated.history[lastQuoteUpdated.history.length - 1]
                .comment === 'Expired by Carrier' ? (
                <WarningTag>Expired</WarningTag>
              ) : (
                <WarningTag>Declined</WarningTag>
              )}
            </div>
          ) : expired &&
            quoteData.history[quoteData.history.length - 1].isInternalUser ? (
            location === 'booked' ||
            location === 'dismissed' ||
            location === 'new' ? null : (
              <div>
                <CancelIcon /> <WarningTag color="red">Expired</WarningTag>
              </div>
            )
          ) : null}
        </Flex>
        {cancelledButton && location !== 'dismissed' ? (
          <RemoveButton
            userId={userId}
            orderId={orderId}
            adminAssigned={adminAssigned}
            history={lastQuoteUpdated.history}
            declineQuote={declineQuote}
          />
        ) : null}
        {location === 'booked' ||
        location === 'dismissed' ? null : isBookedLoadsAlertEnabled &&
          quoteData.history[quoteData.history.length - 1].isInternalUser &&
          quoteData.history[quoteData.history.length - 1].status ===
            'approved' ? (
          <Timer
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            cancelledButton={cancelledButton}
          />
        ) : expired &&
          location !== 'new' &&
          quoteData.history[quoteData.history.length - 1].isInternalUser &&
          !cancelledButton ? (
          <RemoveButton
            expired={expired}
            userId={userId}
            orderId={orderId}
            adminAssigned={adminAssigned}
            history={lastQuoteUpdated.history}
            declineQuote={declineQuote}
          />
        ) : (
          <Timer
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            cancelledButton={cancelledButton}
          />
        )}
      </Flex>
    </HeaderContainer>
  );
};

export default QuoteCardHeader;
