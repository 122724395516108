import { RateAdjustmentApiResult } from 'features/rateProAdmin/rateAdminTypes';
import { TrailerType } from '../ratesTypes';

import {
  RegionAdjustementByEquipmentType,
  adjustRateForRtsHistory,
  getSonarSuggestedBuyRate,
  regionAdjustmentByEquipmentType,
  FlatbedDatRateValues,
} from './suggestedBuyRateUtils';

export type BuyRateParams = {
  regionAdjustment: RateAdjustmentApiResult | undefined;
  sonarScore?: number;
  rateViewAverage?: number;
  rateViewHigh?: number;
  rateViewLow?: number;
  rtsHistoryRateAverage?: number;
  rtsHistoryLoads?: number;
  equipmentType?: TrailerType;
};

export const getSuggestedBuyRateNew = ({
  regionAdjustment,
  sonarScore,
  rateViewAverage,
  rateViewHigh,
  rateViewLow,
  rtsHistoryRateAverage,
  rtsHistoryLoads,
  equipmentType,
}: BuyRateParams): number | undefined => {
  if (
    rateViewAverage === undefined ||
    rateViewHigh === undefined ||
    rateViewLow === undefined ||
    equipmentType === undefined
  ) {
    return undefined;
  }

  if (!regionAdjustment) {
    const rate = getSuggestedBuyRateWithoutRegion(
      sonarScore,
      rateViewAverage,
      rateViewHigh,
      rateViewLow,
      rtsHistoryRateAverage,
      rtsHistoryLoads,
      equipmentType,
    );

    return rate;
  }

  const regionAdjustmentByType: RegionAdjustementByEquipmentType =
    regionAdjustmentByEquipmentType(regionAdjustment, equipmentType);

  let sonarRate = sonarScore
    ? getSonarSuggestedBuyRate(
        regionAdjustmentByType,
        sonarScore,
        rateViewAverage,
        rateViewHigh,
        rateViewLow,
      )
    : rateViewAverage;

  if (equipmentType.toUpperCase() === TrailerType.FLATBED) {
    /**
     * Flatbed does not have a sonar score, so instead of adjusting dat rate
     * up or down based on sonar score plus config, we use the flatbedDatRate
     * value from the api to adjust in a similar way to how the other equipement
     * types use sonar score. I am calling this sonarRate still because it is the rate
     * that is used to compare to rts history based on the number of loads.
     */

    sonarRate = regionAdjustment?.flatbedDatRate?.toUpperCase()
      ? regionAdjustment.flatbedDatRate.toUpperCase() ===
        FlatbedDatRateValues.HIGH
        ? rateViewHigh
        : rateViewLow
      : rateViewAverage;
  }

  if (!rtsHistoryRateAverage || !rtsHistoryLoads) {
    return sonarRate;
  }

  const suggestedBuyRate = adjustRateForRtsHistory(
    sonarRate,
    regionAdjustmentByType,
    rtsHistoryRateAverage,
    rtsHistoryLoads,
  );

  return suggestedBuyRate;
};

const getSuggestedBuyRateWithoutRegion = (
  sonarScore?: number,
  rateViewAverage?: number,
  rateViewHigh?: number,
  rateViewLow?: number,
  rtsHistoryRateAverage?: number,
  rtsHistoryLoads?: number,
  equipmentType?: TrailerType,
) => {
  if (
    rateViewAverage === undefined ||
    rateViewHigh === undefined ||
    rateViewLow === undefined ||
    equipmentType === undefined
  ) {
    return undefined;
  }

  if (
    (equipmentType.toUpperCase() === TrailerType.VAN ||
      equipmentType.toUpperCase() === TrailerType.REEFER) &&
    sonarScore !== undefined &&
    (rtsHistoryLoads === undefined || rtsHistoryLoads < 5)
  ) {
    if (sonarScore >= 1) {
      return rateViewAverage;
    }
    if (sonarScore <= -1) {
      return rateViewLow;
    }
  }

  if (rtsHistoryRateAverage !== undefined && rtsHistoryLoads !== undefined) {
    if (rtsHistoryLoads >= 5) {
      return rtsHistoryRateAverage <= rateViewAverage
        ? rtsHistoryRateAverage
        : rateViewAverage;
    }
    return rateViewAverage;
  }

  return rateViewAverage;
};
