import * as React from 'react';
import { FormControl, Select, MenuItem, withStyles } from '@material-ui/core';
import { useSearchCapacityQueryParams } from 'hooks/useQueryParams';
import { Flex } from 'components/Flex';

const deadheadOptions = [
  '25',
  '50',
  '75',
  '100',
  '150',
  '200',
  '250',
  '300',
  '350',
];

const StyledFormControl = withStyles({
  root: {
    width: '116px',
    height: '36px',
    background: 'white',
    borderRadius: '20px',
  },
})(FormControl);

const StyledMenuItem = withStyles({
  root: {
    fontSize: '14px',
  },
})(MenuItem);

const StyledSelect = withStyles({
  root: {
    fontSize: '14px',
  },
  select: {
    fontSize: '14px',
    paddingLeft: '15px',
    paddingTop: '10px',
    fontFamily: 'Proxima Nova',
  },
})(Select);

const DeadheadPicker = ({ type }: { type: string }) => {
  const { addQueryParams, queryParams, setQueryParams } =
    useSearchCapacityQueryParams();

  const selectedPickupDeadhead = queryParams.pickupDeadheadMiles
    ? queryParams.pickupDeadheadMiles
    : '75';
  const selectedDeliveryDeadhead = queryParams.deliveryDeadheadMiles
    ? `${queryParams.deliveryDeadheadMiles}`
    : `75`;

  if (!deadheadOptions.includes(selectedPickupDeadhead)) {
    setQueryParams({ ...queryParams, pickupDeadheadMiles: undefined });
  }

  if (!deadheadOptions.includes(selectedDeliveryDeadhead)) {
    setQueryParams({ ...queryParams, deliveryDeadheadMiles: undefined });
  }

  const onChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: any }>,
  ) => {
    if (type === 'pickup') {
      addQueryParams({ pickupDeadheadMiles: event.target.value });
    }
    if (type === 'delivery') {
      addQueryParams({ deliveryDeadheadMiles: event.target.value });
    }
  };

  return (
    <Flex
      marginTop="9px"
      marginLeft="6px"
      height="100%"
      flexDirection="column"
      justifyContent="space-evenly"
    >
      <StyledFormControl>
        <StyledSelect
          MenuProps={{ variant: 'menu' }}
          disableUnderline
          value={
            type === 'pickup'
              ? selectedPickupDeadhead
              : selectedDeliveryDeadhead
          }
          onChange={onChange}
          renderValue={(value) => `DH ${value}m`}
        >
          {deadheadOptions.map((deadhead) => (
            <StyledMenuItem key={deadhead} value={deadhead}>
              {deadhead} miles
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </Flex>
  );
};

export default DeadheadPicker;
