// packages
import styled from 'styled-components';
import {
  Button,
  TextInput,
  PhoneFormatTextInput,
  MultilineTextInput,
} from 'shamrock-clover-ui';
import Grid from '@material-ui/core/Grid';

export const ContentContainer = styled.div`
  background-color: #ffffff;
  width: 845px;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 600px) {
    width: auto;
    padding: 10px;
    margin-bottom: 30px;
  }
`;

export const GridContainer = styled(Grid)`
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

export const TextInputStyled = styled(TextInput)`
  margin: auto;
  padding: 5px;
  margin-bottom: 10px;
`;

export const MultilineTextInputStyled = styled(MultilineTextInput)`
  margin-bottom: 10px;
`;
export const MultilineTextInputFilled = styled(MultilineTextInput)`
  margin-top: 10px;
  margin-bottom: 10px;
  height: 200px;
  div {
    div {
      height: 200px;
      textarea {
        height: 180px;
      }
    }
  }
`;
export const InputLabel = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  color: #333333;
`;

export const CreditInfoContainer = styled(Grid)`
  height: 100%;
  min-height: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const PhoneFormatTextInputStyled = styled(PhoneFormatTextInput)`
  margin-top: 5px;
`;

export const SelectInputMenu = styled.div`
  .SingleSelectMenuContainer {
    width: 420px;
    left: 140px;
  }
`;

export const ErrorText = styled.div`
  margin: 5px 0px 0px 10px;
  color: #ef3824;
  font-size: 0.75em;
  background-color: transparent;
`;

export const SectionHeader = styled.b`
  background-color: #eeeeee;
  display: flex;
  padding: 15px;
  color: #666666;
`;

export const Details = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
