import * as React from 'react';
import { CardContainer, SplitCardText } from './rateAdjustmentStylesV2';

import {
  StyledChangeLogTrailerContainer,
  StyledMainCard,
  StyledChangeLogHeaderText,
  SplitCardBackgroundContainer,
  SplitCardBackground,
} from './rateChangeLogStylesV2';
import ChangeLogCardItem from './components/ChangeLogCardItemV2';
import ChangeLogFlatbedCardItem from './components/ChangeLogFlatbedCardItemV2';
import { RateAdjustmentApiResult } from './rateAdminTypes';

interface Props {
  rateAdjustment: RateAdjustmentApiResult;
}

const RateChangeLogCard: React.FC<React.PropsWithChildren<Props>> = ({ rateAdjustment }) => {
  const date = new Date(rateAdjustment.createdAt);

  const isReset = rateAdjustment.reason.indexOf('Reset all regions') !== -1;

  const dateString =
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear();

  return (
    <StyledMainCard>
      <CardContainer flexDirection="column">
        <StyledChangeLogHeaderText>
          {rateAdjustment.email} <span>{dateString}</span>
        </StyledChangeLogHeaderText>
        <SplitCardBackgroundContainer>
          <SplitCardBackground>
            <SplitCardText>
              <span style={{ fontWeight: '600' }}>REGION:&nbsp;</span>
              {`${rateAdjustment?.region ? rateAdjustment.region : ''}`}
            </SplitCardText>
          </SplitCardBackground>
          <SplitCardBackground>
            <SplitCardText>
              <span style={{ fontWeight: '600' }}>REASON:&nbsp;</span>
              <span style={{ color: isReset ? 'red' : '#666666' }}>{`${
                rateAdjustment?.reason ? rateAdjustment.reason : ''
              }`}</span>
            </SplitCardText>
          </SplitCardBackground>
        </SplitCardBackgroundContainer>
        <StyledChangeLogTrailerContainer>
          <ChangeLogCardItem
            trailerType="VAN"
            lowStart={rateAdjustment.lowVanSonarBeginning}
            lowEnd={rateAdjustment.lowVanSonarEnd}
            averageStart={rateAdjustment.averageVanSonarBeginning}
            averageEnd={rateAdjustment.averageVanSonarEnd}
            highEnd={rateAdjustment.highVanSonarEnd}
            highStart={rateAdjustment.highVanSonarBeginning}
            numberOfLoads={rateAdjustment.vanNumberOfLoads}
            dataHistory={rateAdjustment.vanRtsRate}
          />
          <ChangeLogCardItem
            trailerType="REEFER"
            lowStart={rateAdjustment.lowReeferSonarBeginning}
            lowEnd={rateAdjustment.lowReeferSonarEnd}
            averageStart={rateAdjustment.averageReeferSonarBeginning}
            averageEnd={rateAdjustment.averageReeferSonarEnd}
            highEnd={rateAdjustment.highReeferSonarEnd}
            highStart={rateAdjustment.highReeferSonarBeginning}
            numberOfLoads={rateAdjustment.reeferNumberOfLoads}
            dataHistory={rateAdjustment.reeferRtsRate}
          />
          <ChangeLogFlatbedCardItem
            trailerType="FLATBED"
            flatbedDatRate={rateAdjustment.flatbedDatRate}
            flatbedNumberOfLoads={rateAdjustment.flatbedNumberOfLoads}
            flatbedRtsRate={rateAdjustment.flatbedRtsRate}
          />
        </StyledChangeLogTrailerContainer>
      </CardContainer>
    </StyledMainCard>
  );
};

export default RateChangeLogCard;
