import React, { useState, createContext, useContext } from 'react';
import { Snackbar } from 'shamrock-clover-ui';

interface ShowSnackbarProps {
  message: string;
  type: 'info' | 'error' | 'success';
  duration?: number;
}

interface SnackbarProps {
  message: string;
  type: 'info' | 'error' | 'success';
  isOpen: boolean;
  duration?: number;
}

const SnackbarContext = createContext<any | undefined>(undefined);

export const SnackbarProvider = ({ children }: any) => {
  const [snack, setSnackbar] = useState<SnackbarProps>();

  const showSnackbar = (snackbar: ShowSnackbarProps) => {
    setSnackbar({ ...snackbar, isOpen: true });
  };

  const handleClose = () => {
    if (snack) {
      setSnackbar({ ...snack, isOpen: false });
    }
  };

  const value = {
    showSnackbar,
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {snack && (
        <Snackbar
          open={snack.isOpen}
          onClose={() => handleClose()}
          message={snack.message}
          variant={snack.type}
          duration={snack.duration ? snack.duration : 4000}
        />
      )}
    </SnackbarContext.Provider>
  );
};

const useSnackbars = (): {
  showSnackbar: (snackbar: ShowSnackbarProps) => void;
} => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbars must be used within the SnackbarProvider');
  }

  return context;
};

export default useSnackbars;
