import { useSearchCapacityQueryParams } from 'hooks/useQueryParams';
import { styled, Button } from 'shamrock-clover-ui';
import { useEffect, useState } from 'react';

const StyledButton = styled(Button)<{ border: string }>`
  margin-top: 18px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  background-color: #eeeeee;
  border: ${({ border }) => border && border};
`;

const ResetButton = ({ onClick }: { onClick: () => void }) => {
  const {
    queryParams: {
      pickupCity,
      pickupState,
      deliveryCity,
      deliveryState,
      equipmentType,
      deliveryDeadheadMiles,
      pickupDeadheadMiles,
    },
  } = useSearchCapacityQueryParams();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(
      !(
        pickupCity ||
        pickupState ||
        deliveryCity ||
        deliveryState ||
        equipmentType ||
        deliveryDeadheadMiles ||
        pickupDeadheadMiles
      ),
    );
  }, [
    pickupCity,
    pickupState,
    deliveryCity,
    deliveryState,
    equipmentType,
    deliveryDeadheadMiles,
    pickupDeadheadMiles,
  ]);

  return (
    <StyledButton
      width="81px"
      height="36px"
      isRounded={true}
      buttonStyle="outlined"
      disabled={isDisabled}
      onClick={onClick}
      textTransform="sentenceCase"
      border={isDisabled ? '1px solid #999999' : '1px solid #0091EA'}
    >
      Reset all
    </StyledButton>
  );
};

export default ResetButton;
