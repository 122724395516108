import {
  RtsLoadHistoryApiResponse,
  RtsLoadHistoryRow,
} from 'features/ratePro/loadHistory/types';
import { REACT_APP_RTS_HISTORY_BASE_URL } from 'utils/constants';
import http from 'utils/httpUtils';

export const getCarrierLoadHistory = async (
  carrierId: string,
): Promise<RtsLoadHistoryRow[]> => {
  const LOOKBACK_DAYS = '90';
  const queryString = new URLSearchParams({
    carrier: carrierId,
    lookbackDays: LOOKBACK_DAYS,
  }).toString();
  const fullPath = `${REACT_APP_RTS_HISTORY_BASE_URL}/rates/history?${queryString}`;

  const response = await http
    .get<RtsLoadHistoryApiResponse>(fullPath)()
    .catch((error: any) => {
      return Promise.reject(`error: ${JSON.stringify(error)}`);
    });

  return response.data;
};
