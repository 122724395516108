import { NewRateQuery } from 'features/ratePro/ratesTypes';
import { useQuery } from 'react-query';
import { getRtsLoadHistory } from '../utils/loadHistoryHttp';

export const useLoadHistoryData = (searchQuery: NewRateQuery | undefined) => {
  const LoadHistoryResults = useQuery(
    ['LoadHistoryData', JSON.stringify(searchQuery)],
    async () => {
      if (!searchQuery) {
        return;
      }
      return await getRtsLoadHistory(searchQuery);
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  return {
    LoadHistoryResults: {
      data: LoadHistoryResults.data,
      isError: LoadHistoryResults.isError,
      isLoading: LoadHistoryResults.isLoading,
      error: LoadHistoryResults.error as Error,
    },
  };
};
