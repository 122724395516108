import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { FreightAdminFeatures, getAllFeatures } from './featureUtils';

const defaultFlags: FreightAdminFeatures = {
  isRtsMilesServiceFallbackEnabled: false,
  isSellRateEnabled: false,
  isRateAdjustmentEnabled: false,
  isCapacityEnabled: false
};

export const FeatureFlagContext =
  createContext<FreightAdminFeatures>(defaultFlags);

interface FeatureFlagProviderProps {
  children: ReactNode;
}

export const FeatureFlagProvider: FC<React.PropsWithChildren<FeatureFlagProviderProps>> = ({
  children,
}) => {
  const [flags, setFlags] = useState<FreightAdminFeatures>(defaultFlags);

  useEffect(() => {
    // Simulated API call to fetch feature flags
    const fetchFeatureFlags = async () => {
      const { isRtsMilesServiceFallbackEnabled, isSellRateEnabled, isRateAdjustmentEnabled, isCapacityEnabled } =
        await getAllFeatures();
      setFlags({
        isRtsMilesServiceFallbackEnabled,
        isSellRateEnabled,
        isRateAdjustmentEnabled,
        isCapacityEnabled
      });
    };

    fetchFeatureFlags();
  }, []);

  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = (): FreightAdminFeatures => {
  return useContext(FeatureFlagContext);
};
