import usStates from './usStates.json';
import canadaStates from './canadaStates.json';

export const getStateOrProvinceAbbreviation = (stateOrProvince: string) => {
  const usState = usStates.find(
    (state) => state.name === stateOrProvince,
  )?.abbreviation;
  const caProvince = canadaStates.find(
    (state) => state.name === stateOrProvince,
  )?.abbreviation;
  return usState ?? caProvince;
};

export const getStateOrProvinceName = (stateOrProvince: string) => {
  const usState = usStates.find(
    (state) => state.abbreviation === stateOrProvince,
  )?.name;
  const caProvince = canadaStates.find(
    (state) => state.abbreviation === stateOrProvince,
  )?.name;
  return usState ?? caProvince;
};