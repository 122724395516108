import * as React from "react";

import {
  StyledChangeLogCard,
  StyleChangeLogTitleText,
} from "../rateChangeLogStyles";
import { StyledCard, CardContainer } from "../rateAdjustmentStyles";

interface Props {
  trailerType: string;
  flatbedNumberOfLoads: string;
  flatbedRtsRate: string;
  flatbedDatRate: string;
}

const ChangeLogFlatbedCardItem: React.FC<React.PropsWithChildren<Props>> = ({
  trailerType,
  flatbedNumberOfLoads,
  flatbedRtsRate,
  flatbedDatRate,
}) => {
  return (
    <StyledChangeLogCard width="360px" height="450px">
      <CardContainer flexDirection="column">
        <StyleChangeLogTitleText
          fontSize="24px"
          lineHeight="29px"
          width="386px"
          height="29px"
          color="#333333"
        >
          {`${trailerType}:`}
        </StyleChangeLogTitleText>
        <StyleChangeLogTitleText
          fontSize="18px"
          lineHeight="29px"
          width="386px"
          height="22px"
          color="#666666"
          fontWeight="600"
        >
          DAT RATE
        </StyleChangeLogTitleText>
        <StyledCard
          background="#F9F9F9"
          height="40px"
          borderBottom="1px solid #CCCCCC"
          flexDirection="row"
          justifyContent="space-between"
        >
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="67px"
            height="22px"
            color="#666666"
            fontWeight="600"
          >
            {flatbedDatRate}
          </StyleChangeLogTitleText>
        </StyledCard>
        <StyledCard
          background="#F9F9F9"
          height="25px"
          flexDirection="row"
          justifyContent="space-between"
        >
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="200px"
            height="40px"
            color="#666666"
            fontWeight="600"
          >
            RTS History Override
          </StyleChangeLogTitleText>
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="170px"
            height="40px"
            color="#666666"
            fontWeight="400"
            textAlign="right"
          >
            {`${flatbedNumberOfLoads} Loads`} <br />{" "}
            {`RTS rate ${flatbedRtsRate?.toUpperCase()}`}
          </StyleChangeLogTitleText>
        </StyledCard>
      </CardContainer>
    </StyledChangeLogCard>
  );
};

export default ChangeLogFlatbedCardItem;
