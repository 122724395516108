import React, { PropsWithChildren } from 'react';
import {
  ExpandableCard,
  SelectInput,
  GlobalNotification,
} from 'shamrock-clover-ui';

function withChildren<P>(WrappedComponent: React.ComponentType<P>) {
  const WithChildren: React.FC<PropsWithChildren<P>> = (props) => {
    return <WrappedComponent {...props} />;
  };

  return WithChildren;
}

export const SelectInputWithChildren = withChildren(SelectInput);

export const ExpandableCardWithChildren = withChildren(ExpandableCard) as any;
ExpandableCardWithChildren.Details = ExpandableCard.Details as React.FC;
ExpandableCardWithChildren.Summary = ExpandableCard.Summary as React.FC;
export const GlobalNotificationWithChildren = withChildren(GlobalNotification);
