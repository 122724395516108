import http from 'utils/httpUtils';
import { REACT_APP_CREDIT_SERVICE_BASE_URL } from 'utils/constants';
import {
  AdjustmentCreditRequestEmailData,
  CreditRequestApiResult,
  CreditRequestSearchQuery,
  NewCreditRequestEmailData,
} from './creditRequestTypes';

const parseQuery = (query: CreditRequestSearchQuery) => {
  let buildQuery: string = '?';

  for (const key in query) {
    if (query[key as keyof CreditRequestSearchQuery] !== '') {
      console.log('Object.keys(query).length :>> ', Object.keys(query).length);
      buildQuery += `${key}=${query[key as keyof CreditRequestSearchQuery]}&`;
    }
  }
  return buildQuery;
};

export const getCreditRequestData = async (query: CreditRequestSearchQuery) => {
  const queryString: string = parseQuery(query);
  return http.get<CreditRequestApiResult, undefined>(
    `${REACT_APP_CREDIT_SERVICE_BASE_URL}/logs${queryString}`,
  )(undefined);
};

export const postNewCreditRequestEmail = async (
  data: NewCreditRequestEmailData,
) => {
  return data
    ? http.post<
        { message: string },
        { emailNewRequestBody: NewCreditRequestEmailData }
      >(`${REACT_APP_CREDIT_SERVICE_BASE_URL}/newEmail`)({
        emailNewRequestBody: data,
      })
    : Promise.reject(new Error('Invalid request body'));
};

export const postAdjustmentCreditRequestEmail = async (
  data: AdjustmentCreditRequestEmailData,
) => {
  return data
    ? http.post<
        { message: string },
        { emailAdjustmentRequestBody: AdjustmentCreditRequestEmailData }
      >(`${REACT_APP_CREDIT_SERVICE_BASE_URL}/adjustmentEmail`)({
        emailAdjustmentRequestBody: data,
      })
    : Promise.reject(new Error('Invalid request body'));
};
