import { styled } from 'shamrock-clover-ui';

export const Container = styled.div`
  height: calc(100vh - 100px);
  background-color: ${({ theme }) => theme.colors.gray[10]};
`;

export const GridContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  padding: 16px 64px;
  height: 100%;
  margin: 0 auto;
`;
export const GridArea = styled.div<{ gridArea?: string }>`
  background-color: white;
  padding: 24px;
  min-width: 580px;
`;
export const SplitCardBackgroundContainer = styled.div`
  justify-content: space-around;
  display: flex;
  gap: 16px;
  padding: 0px 18px;
  margin-bottom: 10px;
`;
export const SplitCardBackground = styled.div`
  width: 100%;
  min-height: 38px;
  height: 100%;
  background: #f9f9f9;
  display: flex;
  align-items: flex-start;
`;

export const StyledChangeLogCardHeader = styled.div`
  align-items: flex-start;
  display: flex;
  width: 48%;
  height: 38px;
  background: #f9f9f9;
  align-items: center;
  justify-content: flex-start;
  margin-right: 24px;
`;

export const StyleChangeLogTitleText = styled.div<{
  fontSize?: string;
  fontWeight?: string;
  paddingTop?: string;
  paddingLeft?: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  color: #333333;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '0px')};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '0px')};
`;

export const StyledChangeLogHeaderText = styled.div`
  font-size: 14px;
  color: #666666;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
`;

export const StyledChangeLogCard = styled.div<{
  height?: string;
  width?: string;
}>`
  display: flex;
  flex-direction: column;
  min-width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: #f9f9f9;
  border-radius: 2px;
  min-width: 432px;
  width: 32%;
`;

export const StyledChangeLogTrailerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 8px 0px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const StyledContainerCard = styled.div<{
  height: string;
  topBorder?: string;
  background?: string;
  flexDirection?: string;
  justifyContent?: string;
  width?: string;
  paddingLeft?: string;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'column'};
  align-items: center;
  padding: 24px 0px 0px;
  gap: 24px;
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => height || 'auto'};
  background: ${({ background }) => (background ? background : '#ffffff')};
  border-top: ${({ topBorder }) => topBorder};
  justify-content: ${({ justifyContent }) => justifyContent};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '0px')};
  margin-bottom: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: blue;
`;

export const StyledMainCard = styled.div<{
  flexDirection?: string;
}>`
  gap: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'row'};
  display: flex;
  justify-content: space-between;
  padding: 14px 0px;
  margin: 22px 0px;
`;

export const StyledCard = styled.div<{
  borderBottom?: string;
  flexDirection?: string;
}>`
  border-bottom: ${({ borderBottom }) => borderBottom};
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'row'};
  display: flex;
  justify-content: space-between;
  margin: 0px 8px;
  padding: 10px 0px;
`;
