import * as FirebaseUtils from 'utils/firebaseUtils';
import { FC, useContext } from 'react';
import { useQuotingQueryParams } from 'hooks/useQueryParams';
import { RemoveButtonStyled } from '../styledComponents/QuoteCarHeaderStyles';
import { getTimerDisplay } from '../utils/quoteUtils';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import useCurrentLocation from '../hooks/useCurrentLocation';
import { RemoveButtonProps } from '../utils/quoteTypes';

const RemoveButton: FC<React.PropsWithChildren<RemoveButtonProps>> = ({
  userId,
  orderId,
  adminAssigned,
  history,
  declineQuote,
  expired,
}) => {
  const {
    setPathAndQuery,
    queryParams: { orderId: _orderId },
  } = useQuotingQueryParams();

  const { getStatusFromQuoteContext } = useContext(QuotesEnhancementsContext);
  const { location } = useCurrentLocation();

  const handleRemoveButton = async () => {
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.REMOVE,
      FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
      FirebaseUtils.FirebasePages.DETAILS,
      {
        orderNumber: _orderId,
        status: getStatusFromQuoteContext(orderId),
        timer: getTimerDisplay(history),
        description: 'Remove Quote',
        adminUser: adminAssigned,
      },
    );
    declineQuote({
      userId,
      orderId,
      doNotSendNotification: false,
      comment: expired ? 'Expired by Carrier' : 'Carrier unavailable',
    });
    setPathAndQuery(location);
  };

  return (
    <RemoveButtonStyled onClick={() => handleRemoveButton()}>
      REMOVE
    </RemoveButtonStyled>
  );
};

export default RemoveButton;
