import React from 'react';
import { styled, FilterInput } from 'shamrock-clover-ui';
import { Flex } from '../../../components/Flex';
import { useCreditRequestQueryParams } from '../../../hooks/useQueryParams';
import { LabelText } from '../creditRequestStyles';

const InputContainer = styled.div<{ isError?: boolean }>`
  height: 36px;

  > div {
    border: ${({ isError, theme }) =>
      isError ? `1px solid ${theme.colors.red[50]}` : 'none'};
  }
`;

const StyledInput = styled(FilterInput)`
  border: none;
  font-family: Proxima Nova;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  color: #333333;
  height: 36px;

  ::placeholder {
    color: #333333;
  }
`;

type Props = {
  onSearch: () => void;
};

const CustomerNameInput = ({ onSearch }: Props) => {
  const { addQueryParams, setQueryParams, queryParams } =
    useCreditRequestQueryParams();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    addQueryParams({
      customerName: event.target.value.toLocaleUpperCase().substring(0, 20),
    });
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter' && queryParams.requestType) {
      onSearch();
    }
  };

  const customerName = queryParams.customerName ? queryParams.customerName : '';

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <LabelText>Customer Name</LabelText>
      <InputContainer>
        <StyledInput
          placeholder="Name"
          height="30px"
          width="206px"
          onChange={onChange}
          value={customerName}
          clearSearch={() =>
            setQueryParams({ ...queryParams, customerName: undefined })
          }
          onKeyDown={handleKeyDown}
        ></StyledInput>
      </InputContainer>
    </Flex>
  );
};

export default CustomerNameInput;
