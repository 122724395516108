import { RefObject, useEffect, useState } from 'react';

const useHover = (ref?: RefObject<Element>): boolean => {
  const [value, setValue] = useState(false);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(() => {
    const node = ref?.current;
    if (node) {
      node.addEventListener('mouseenter', handleMouseOver);
      node.addEventListener('mouseleave', handleMouseOut);
    }
    return () => {
      if (node) {
        node.removeEventListener('mouseenter', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseOut);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current]);

  return value;
};

export default useHover;
