import { Flex } from 'components/Flex';
import { styled } from 'shamrock-clover-ui';

export const LabelText = styled.div`
  font-family: proxima-nova, sans-serif;
  margin-bottom: 5px;
  color: #666666;
  font-size: 12px;
  cursor: text;
`;

export const HelperText = styled.div`
  font-family: proxima-nova, sans-serif;
  margin: 5px 0px 0px 10px;
  font-size: 0.75em;
  color: #666666;
  background-color: transparent;
  margin-bottom: 1em;
`;

export const QuotedText = styled.div`
  cursor: pointer;
  font-family: proxima-nova;
  user-select: none;
  font-size: ${({ theme }) => theme.font.size.xsmall};
`;

export const DialogContainer = styled.div`
  font-weight: 400;
  text-align: left;
`;

export const QuotingInputContainer = styled(Flex)<{
  amount?: number;
  showTextCursor: boolean;
  isEdit: boolean;
  isCountering: boolean;
}>`
  box-sizing: border-box;
  height: 40px;
  border: ${({ theme }) => `1px solid ${theme.colors.blue[50]}`};
  border-radius: 3px;
  cursor: ${({ showTextCursor }) => (showTextCursor ? 'text' : 'default')};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  color: ${({ theme, amount }) =>
    amount && amount > 99 ? theme.colors.blue[50] : theme.colors.gray[40]};

  & > div {
    ${({ isEdit }) =>
      isEdit
        ? {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }
        : {
            width: '100px',
          }}
  }

  & > div > :nth-of-type(2) {
    ${({ isCountering }) =>
      isCountering ? {} : { width: '200px', marginTop: '42px' }}
  }

  li {
    min-width: 106px;
    height: 40px;
  }

  span {
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    color: ${({ theme }) => theme.colors.gray[90]};
    font-family: proxima-nova;
  }
`;

export const Input = styled.input`
  width: 60%;
  border: 0;
  color: inherit;
  margin-left: 5px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font: ${({ theme }) => `${theme.font.size.small} proxima-nova`};

  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray[40]};
    font: ${({ theme }) => `${theme.font.size.small} proxima-nova`};
    font-weight: ${({ theme }) => theme.font.weight.medium};
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
