import {
  RegionTitleText,
  StyledCard,
  CardContainer,
} from './rateAdjustmentStylesV2';
import EquipmentRowV2 from './components/EquipmentRowV2';
import FlatbedRowV2 from './components/FlatbedRowV2';

const RateAdjustmentStep2V2 = () => {
  return (
    <StyledCard height="auto" topBorder="1px solid #CCCCCC" paddingLeft="24px">
      <CardContainer flexDirection="column">
        <RegionTitleText
          fontSize="24px"
          lineHeight="29px"
          width="450px"
          height="29px"
          padding="0px"
        >
          2. Select equipment type to change rates
        </RegionTitleText>
        <EquipmentRowV2 title="Van" />
        <EquipmentRowV2 title="Reefer" />
        <FlatbedRowV2 />
      </CardContainer>
    </StyledCard>
  );
};

export default RateAdjustmentStep2V2;
