import { Button } from 'shamrock-clover-ui';
import { Icon } from 'shamrock-clover-ui';
import { styled } from 'shamrock-clover-ui';

const StyledButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 20px;
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-family: Proxima Nova;
  height: 36px;
  margin-top: 19px;
  margin-left: auto;
  z-index: 999;
`;

interface Props {
  disableButton: boolean;
  onClick: () => void;
}

const SubmitRateButton = ({ disableButton, onClick }: Props) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disableButton}
      color="green"
      icon={<Icon color="white" size="14" icon="arrowRight" />}
    >
      Submit Your Rate
    </StyledButton>
  );
};

export default SubmitRateButton;
