import { useState, useEffect } from 'react';
import { Dialog } from 'shamrock-clover-ui';
import { numberToWholeCurrencyString } from 'utils/numberUtils';
// *
// Until we can get up to date with clover, I've copied the current table component
// files into freight admin so we can use them now, and make an easy transition in the future
// *
// import SmrTable from 'shamrock-clover-ui/dist/clover/components/Table/Table';
import SmrTable from '../../../components/cloverTable/Table';
import {
  Cell,
  Column,
  Row,
  SortOrder,
} from 'shamrock-clover-ui/dist/clover/components/Table/TableTypes';
import styled from 'styled-components';
import { RtsLoadHistoryProps, RtsLoadHistoryRow } from './types';

const RTS_LOAD_HIST_COLUMNS: Column[] = [
  {
    key: 'orderId',
    label: 'Order ID',
    width: 11,
    alignment: 'left',
    isSortable: true,
  },
  {
    key: 'pickupDate',
    label: 'Pickup Date',
    width: 10.1,
    alignment: 'left',
    isSortable: true,
  },
  {
    key: 'deliveryDate',
    label: 'Delivery Date',
    width: 10,
    alignment: 'left',
    isSortable: true,
  },
  {
    key: 'customer',
    label: 'Customer',
    width: 14.6,
    alignment: 'left',
    isSortable: true,
  },
  {
    key: 'customerRate',
    label: 'Customer Rate',
    width: 11,
    alignment: 'left',
    isSortable: true,
  },
  {
    key: 'carrier',
    label: 'Carrier',
    width: 15,
    alignment: 'left',
    isSortable: true,
  },
  {
    key: 'carrierRate',
    label: 'Carrier Rate',
    width: 11.6,
    alignment: 'left',
    isSortable: true,
  },
  {
    key: 'miles',
    label: 'Miles',
    width: 6.9,
    alignment: 'left',
    isSortable: true,
  },
  {
    key: 'equipment',
    label: 'Equipment',
    width: 9.8,
    alignment: 'left',
    isSortable: true,
  },
];

const DialogContainer = styled.div`
  width: 100%;
  margin-bottom: -49px;
  border: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TableContainer = styled.div`
  width: 100%;
  max-height: 520px;
  overflow-y: scroll;
  table {
    border: hidden;
    border-bottom: 1px solid #cccccc;
  }
  thead {
    position: sticky;
    top: 0;
    th {
      span {
        font-weight: 700;
      }
    }
  }
  tbody {
    font-weight: 400;
  }
  div {
    position: sticky;
    bottom: 0;
    border: hidden;
  }
`;

const Wrapper = styled.div`
  [role='dialog'] {
    background-color: #f9f9f9;
  }
`;

const Link = styled.div`
  padding: 0px 16px;
  color: #0091ea;
  cursor: pointer;
`;

const convertToRows = (data: RtsLoadHistoryRow[]): Row[] => {
  const rows: Row[] = [];
  const defaultCell: Cell = {
    content: '',
    value: '',
  };

  (data as RtsLoadHistoryRow[]).forEach((row: RtsLoadHistoryRow) => {
    let cells: Cell[] = [];
    cells = RTS_LOAD_HIST_COLUMNS.map((column): Cell => {
      let columnValueRaw = row[column.key as keyof RtsLoadHistoryRow];

      if (['pickupDate', 'deliveryDate'].includes(column.key)) {
        if (columnValueRaw instanceof Date) {
          // Date value for value
          const columnValue = columnValueRaw; // Already a date
          const date = columnValue;
          const localizedDate = date.toLocaleDateString('en-US', {
            timeZone: 'America/Chicago',
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          });
          const newCell: Cell = {
            content: localizedDate,
            value: localizedDate,
          };
          return newCell;
        } else {
          console.info(
            `Expected Date, got string for column.key: ${
              column.key
            }: ${typeof columnValueRaw}`,
          );
          if (typeof columnValueRaw === 'string') {
            const dateValue = new Date(columnValueRaw);
            const localizedDate = dateValue.toLocaleDateString('en-US', {
              timeZone: 'America/Chicago',
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });
            const newCell: Cell = {
              content: localizedDate,
              value: localizedDate,
            };
            return newCell;
          }
          return defaultCell;
        }
      } else if (
        ['customerRate', 'carrierRate', 'miles'].includes(column.key)
      ) {
        if (typeof columnValueRaw === 'number') {
          const columnValue = columnValueRaw as number;
          // Currency value for value
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          const formattedDollar = formatter.format(columnValue);
          const newCell: Cell = {
            content:
              column.key !== 'miles' ? formattedDollar : columnValue.toFixed(0),
            value:
              column.key !== 'miles'
                ? numberToWholeCurrencyString(columnValue)
                : columnValue.toFixed(0),
          };

          return newCell;
        } else {
          return defaultCell;
        }
      } else if (typeof columnValueRaw === 'string') {
        const columnValue = columnValueRaw;
        const newCell: Cell = {
          content: columnValue,
          value: columnValue,
        };
        return newCell;
      } else {
        console.info(
          `None of above: Unexpected type for column.key: ${
            column.key
          }: ${typeof columnValueRaw}`,
        );
        return defaultCell;
      }
    });

    rows.push({
      cells: cells,
    });
  });

  return rows;
};

export const RtsHistoryDialog: React.FC<
  React.PropsWithChildren<RtsLoadHistoryProps>
> = ({ loadHistoryData }) => {
  const loadHistoryDataRows = loadHistoryData.data;
  const tableRows = loadHistoryDataRows
    ? convertToRows(loadHistoryDataRows)
    : [];
  const loadHistoryTotalResults = loadHistoryData.totalResults;
  const [open, setOpen] = useState(false);

  const [sortedColumnIndex, setSortedColumnIndex] = useState(-1);
  const [sortDirection, setSortDirection] = useState<SortOrder>('desc');
  const [sortedTableRows, setSortedTableRows] = useState<Row[]>(tableRows);

  useEffect(() => {
    const tableRows = loadHistoryDataRows
      ? convertToRows(loadHistoryDataRows)
      : [];
    setSortedTableRows(tableRows);
  }, [loadHistoryDataRows]);

  const handleSort = (columnIndex?: number, direction?: SortOrder) => {
    if (columnIndex !== undefined && direction) {
      setSortedColumnIndex(columnIndex);
      setSortDirection(direction);

      const newRows = [...sortedTableRows];
      newRows.sort((a, b) => {
        const aValue = a.cells[columnIndex].value.toString();
        const bValue = b.cells[columnIndex].value.toString();

        // Detecting the type of the value
        const isNumberA =
          !isNaN(Number(aValue.replace(/[$,]+/g, ''))) &&
          !isNaN(parseFloat(aValue.replace(/[$,]+/g, '')));
        const isDateA = Date.parse(aValue);

        // Comparing based on type
        if (isNumberA) {
          // Are numbers
          return compareNumbers(
            Number(aValue.replace(/[$,]+/g, '')),
            Number(bValue.replace(/[$,]+/g, '')),
            direction,
          );
        } else if (isDateA) {
          // Are dates
          return compareDates(aValue, bValue, direction);
        } else {
          // Default to string comparison
          return compareStrings(aValue, bValue, direction);
        }
      });

      setSortedTableRows(newRows);
    }
  };

  const compareNumbers = (a: number, b: number, direction: 'asc' | 'desc') => {
    return direction === 'asc' ? a - b : b - a;
  };

  const compareDates = (a: string, b: string, direction: 'asc' | 'desc') => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return direction === 'asc'
      ? Number(dateA) - Number(dateB)
      : Number(dateB) - Number(dateA);
  };

  const compareStrings = (a: string, b: string, direction: 'asc' | 'desc') => {
    if (direction === 'asc') {
      return a.localeCompare(b);
    } else {
      return b.localeCompare(a);
    }
  };

  return (
    <>
      <Link onClick={() => setOpen(true)}>View Load History</Link>
      <Wrapper>
        <Dialog
          content={
            <DialogContainer>
              <TableContainer>
                <SmrTable
                  columns={RTS_LOAD_HIST_COLUMNS}
                  rows={sortedTableRows}
                  sortingOptions={{
                    onSort: handleSort,
                    sortedColumnIndex: sortedColumnIndex,
                    sortDirection: sortDirection,
                  }}
                  footerOptions={{
                    rowsPerPage: 10,
                    page: 1,
                    rowsPerPageSelector: true,
                    height: 40,
                    customRowsPerPageOptions: [10, 25, 50, 75, 100],
                  }}
                />
              </TableContainer>
            </DialogContainer>
          }
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          title={`Load History: ${
            loadHistoryTotalResults ? loadHistoryTotalResults : '0'
          } Results`}
          width="1360px"
        />
      </Wrapper>
    </>
  );
};
