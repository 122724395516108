import {
  RateViewApiResult,
  RateBody,
  RateCastApiResult,
  SonarScoreApiResult,
  SubmitRateBody,
  RTSMilesBody,
  RTSMilesApiResult,
  HubtekRequestBody,
  CustomerCodesApiResult
} from './ratesTypes';
import http from 'utils/httpUtils';
import {
  API_BASE_URL_V2,
  REACT_APP_RTS_PRO_MILES_SERVICE_URL,
  REACT_APP_RATE_SERVICE_BASE_URL,
} from 'utils/constants';

export const getDATRateview = async (body: RateBody | undefined) => {
  return body
    ? http.post<RateViewApiResult, RateBody>(
        `${API_BASE_URL_V2}/rates/rateView`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};

export const getRateCast = async (body: RateBody | undefined) => {
  return body
    ? http.post<RateCastApiResult[], RateBody>(
        `${API_BASE_URL_V2}/rates/rateCast`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};

export const getRTSRateHistory = async (body: RateBody | undefined) => {
  return body
    ? http.post<RateViewApiResult, RateBody>(
        `${API_BASE_URL_V2}/rates/rtsHistoryRate`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};

export const getRateSonarScore = async (body: RateBody | undefined) => {
  return body
    ? http.post<SonarScoreApiResult, RateBody>(
        `${API_BASE_URL_V2}/rates/sonarScore`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};

export const submitRate = async (body: SubmitRateBody) => {
  return body
    ? http.post<{ rateId: string }, RateBody>(
        `${API_BASE_URL_V2}/rates/submitRate`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};

export const getRTSMiles = async (body: RTSMilesBody) => {
  return body
    ? http.post<RTSMilesApiResult, RTSMilesBody>(
        `${REACT_APP_RTS_PRO_MILES_SERVICE_URL}/mileage`,
      )(body)
    : Promise.reject(new Error('Invalid request body'));
};

export const getHubtekRate = async (body: HubtekRequestBody) => {
  return http.post<any, HubtekRequestBody>(
    `${REACT_APP_RATE_SERVICE_BASE_URL}/rates/hubtek`,
  )(body);
};

export const getCustomerCodes = async () => {
  return http.get<CustomerCodesApiResult>(
    `${REACT_APP_RATE_SERVICE_BASE_URL}/rates/customerCodes`
  )();
};