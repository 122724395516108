import React from 'react';
import { CargoInfoFormData } from '../creditRequestTypes';

// styles
import Grid from '@material-ui/core/Grid';
import { TextInputStyled, GridContainer } from './panelStyles';

// props
interface Props {
  formData: CargoInfoFormData;
  setFormData: Function;
}

export const CargoInfoForm = ({ formData, setFormData }: Props) => {
  const handleOnChangeForm = (name: string, value: string) => {
    setFormData({ ...formData, [name]: { value: value, errorMessage: '' } });
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setFormData({
        ...formData,
        [name]: { value: '', errorMessage: 'Required field' },
      });
    }
  };

  return (
    <GridContainer container>
      <Grid item sm={6}>
        <TextInputStyled
          label="Commodities to be Hauled"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('commodities', value);
          }}
          value={formData.commodities.value}
          onBlur={() => {
            handleOnBlurForm('commodities', formData.commodities.value);
          }}
          error={formData.commodities.errorMessage}
        />
      </Grid>
      <Grid item sm={6}>
        <TextInputStyled
          label="Average Value of Loads to be Hauled"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            if (/^\d*$/.test(value)) {
              handleOnChangeForm('averageValue', value);
            }
          }}
          value={formData.averageValue.value}
          onBlur={() => {
            handleOnBlurForm('averageValue', formData.averageValue.value);
          }}
          error={formData.averageValue.errorMessage}
        />
      </Grid>
      <Grid item sm={6}>
        <TextInputStyled
          label="Maximum Expected Value to be Hauled"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            if (/^\d*$/.test(value)) {
              handleOnChangeForm('maxExpectedValue', value);
            }
          }}
          value={formData.maxExpectedValue.value}
          onBlur={() => {
            handleOnBlurForm(
              'maxExpectedValue',
              formData.maxExpectedValue.value,
            );
          }}
          error={formData.maxExpectedValue.errorMessage}
        />
      </Grid>
      <Grid item sm={6}>
        <TextInputStyled
          label="Mode of Transportation"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('modeOfTransportation', value);
          }}
          value={formData.modeOfTransportation.value}
          onBlur={() => {
            handleOnBlurForm(
              'modeOfTransportation',
              formData.modeOfTransportation.value,
            );
          }}
          error={formData.modeOfTransportation.errorMessage}
        />
      </Grid>
    </GridContainer>
  );
};
