import CapacityScoreChip from '../CapacityScoreChip';
import {
  DrawerTitle,
  DrawHeader,
  PaginationButton,
  PaginationContainer,
} from './DrawerStyles';
import { Icon } from 'shamrock-clover-ui';
import { FC } from 'react';
import { CarrierProfileDrawerHeaderProps } from '../../CapacityTypes';

const CarrierProfileDrawerHeader: FC<CarrierProfileDrawerHeaderProps> = ({
  companyInfo,
  currentCarrierIndex,
  searchCapacity = false,
  handlePagination,
}) => {
  return (
    <>
      <DrawerTitle>
        <p>Carrier Profile</p>
      </DrawerTitle>
      <DrawHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: searchCapacity ? '8px' : '0px',
          }}
        >
          {searchCapacity && (
            <CapacityScoreChip score={companyInfo.carrier.score} />
          )}
          <h2
            style={{
              fontSize: '32px',
              marginLeft: searchCapacity ? '20px' : '0px',
            }}
          >
            {companyInfo.carrier.companyName}
          </h2>
        </div>
        {searchCapacity && (
          <PaginationContainer>
            <span>Previous</span>
            <PaginationButton
              onClick={() =>
                handlePagination({
                  type: 'previous',
                  carrierIndex: currentCarrierIndex,
                })
              }
            >
              <Icon icon="chevronLeft" size="15" color="blue" />
            </PaginationButton>
            <PaginationButton
              onClick={() =>
                handlePagination({
                  type: 'next',
                  carrierIndex: currentCarrierIndex,
                })
              }
            >
              <Icon icon="chevronRight" size="15" color="blue" />
            </PaginationButton>
            <span>Next</span>
          </PaginationContainer>
        )}
      </DrawHeader>
    </>
  );
};

export default CarrierProfileDrawerHeader;
