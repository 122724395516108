import http from 'utils/httpUtils';
import { REACT_APP_TRANSQUOTE_SERVICE_URL } from 'utils/constants';

export interface DashboardUser {
  _id: string;
  fullName: string;
  email: string;
  pausedUser: boolean;
}

export interface InactiveUsers {
  _id: string;
  userEmail: string;
  assignTo: string;
  assignToId: string;
  createdAt: string;
}

export interface CAMUserStatus {
  userEmail: string;
  userId: string;
  assignTo: string;
  assignToId: string;
  isInactive: boolean;
}

export const getUsersByPermissions = http.get<DashboardUser[]>(
  `${REACT_APP_TRANSQUOTE_SERVICE_URL}/admin/get-users-by-permissions`,
);

export const getCAMPausedUsers = http.get<InactiveUsers[]>(
  `${REACT_APP_TRANSQUOTE_SERVICE_URL}/admin/camUsersGet`,
);

export const postCamUserStatus = async (body: CAMUserStatus) => {
  return body
    ? http.post<{ message: any }, { camUserBody: CAMUserStatus }>(
        `${REACT_APP_TRANSQUOTE_SERVICE_URL}/admin/CamUserSetStatus`,
      )({
        camUserBody: body,
      })
    : Promise.reject(new Error('Invalid request body'));
};
