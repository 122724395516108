import { useContext, useEffect } from 'react';
import {
  Container,
  CapacitySearchContainer,
  ViewText,
  TextContainer,
} from './CarrierCapacityStyles';
import SearchCapacityToolbar from './components/SearchCapacityToolbar';
import SearchCapacityResults from './components/SearchCapacityResults';
import { CarrierCapacityContext } from './context/CarrierCapacityContext';
import { Snackbar } from 'shamrock-clover-ui';

const SearchCapacity = () => {
  const {
    carrierCapacitySearchResultsState,
    cleanLanesAndCapacityState,
    lanesAndCapacity,
  } = useContext(CarrierCapacityContext);

  useEffect(() => {
    // If we want to retain results across sub tabs, we can remove this effect.
    cleanLanesAndCapacityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showNoResultsSnackbar =
    carrierCapacitySearchResultsState === 'resultsAvailable' &&
    lanesAndCapacity.length === 0;

  return (
    <Container>
      <CapacitySearchContainer>
        <SearchCapacityToolbar />
        {carrierCapacitySearchResultsState === 'initialView' ? (
          <>
            <TextContainer>
              <ViewText>
                Add details to find carriers with matching lanes and capacity
              </ViewText>
            </TextContainer>
          </>
        ) : (
          <SearchCapacityResults />
        )}
        <Snackbar
          duration={1000000}
          message="No results. Change deadheads to broaden your search."
          open={showNoResultsSnackbar}
          variant="error"
        />
      </CapacitySearchContainer>
    </Container>
  );
};

export default SearchCapacity;
