import * as React from "react";

import {
  StyledChangeLogCard,
  StyleChangeLogTitleText,
} from "../rateChangeLogStyles";
import { StyledCard, CardContainer } from "../rateAdjustmentStyles";

interface Props {
  trailerType: string | undefined;
  lowStart: string | undefined;
  lowEnd: string | undefined;
  averageStart: string | undefined;
  averageEnd: string | undefined;
  highStart: string | undefined;
  highEnd: string | undefined;
  dataHistory: string | undefined;
  numberOfLoads: string | undefined;
}

const ChangeLogCardItem: React.FC<React.PropsWithChildren<Props>> = ({
  trailerType,
  lowStart,
  lowEnd,
  averageStart,
  averageEnd,
  highStart,
  highEnd,
  dataHistory,
  numberOfLoads,
}) => {
  return (
    <StyledChangeLogCard width="360px" height="450px">
      <CardContainer flexDirection="column">
        <StyleChangeLogTitleText
          fontSize="24px"
          lineHeight="29px"
          width="386px"
          height="29px"
          color="#333333"
        >
          {`${trailerType}:`}
        </StyleChangeLogTitleText>
        <StyleChangeLogTitleText
          fontSize="18px"
          lineHeight="29px"
          width="386px"
          height="22px"
          color="#666666"
          fontWeight="600"
        >
          DAT RATE
        </StyleChangeLogTitleText>
        <StyledCard
          background="#F9F9F9"
          height="40px"
          borderBottom="1px solid #CCCCCC"
          flexDirection="row"
          justifyContent="space-between"
        >
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="67px"
            height="22px"
            color="#666666"
            fontWeight="600"
          >
            Low
          </StyleChangeLogTitleText>
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="48px"
            height="22px"
            color="#666666"
            fontWeight="600"
          >
            {`${lowStart}, ${lowEnd}`}
          </StyleChangeLogTitleText>
        </StyledCard>
        <StyledCard
          background="#F9F9F9"
          height="40px"
          borderBottom="1px solid #CCCCCC"
          flexDirection="row"
          justifyContent="space-between"
        >
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="67px"
            height="22px"
            color="#666666"
            fontWeight="600"
          >
            Average
          </StyleChangeLogTitleText>
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="40px"
            height="22px"
            color="#666666"
            fontWeight="600"
          >
            {`${averageStart}, ${averageEnd}`}
          </StyleChangeLogTitleText>
        </StyledCard>
        <StyledCard
          background="#F9F9F9"
          height="25px"
          borderBottom="1px solid #CCCCCC"
          flexDirection="row"
          justifyContent="space-between"
        >
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="67px"
            height="40px"
            color="#666666"
            fontWeight="600"
          >
            High
          </StyleChangeLogTitleText>
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="40px"
            height="22px"
            color="#666666"
            fontWeight="600"
          >
            {`${highStart}, ${highEnd}`}
          </StyleChangeLogTitleText>
        </StyledCard>
        <StyledCard
          background="#F9F9F9"
          height="25px"
          flexDirection="row"
          justifyContent="space-between"
        >
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="200px"
            height="40px"
            color="#666666"
            fontWeight="600"
          >
            RTS History Override
          </StyleChangeLogTitleText>
          <StyleChangeLogTitleText
            fontSize="18px"
            lineHeight="29px"
            width="170px"
            height="40px"
            color="#666666"
            fontWeight="400"
            textAlign="right"
          >
            {`${numberOfLoads} Loads`} <br />{" "}
            {`RTS rate ${dataHistory?.toUpperCase()}`}
          </StyleChangeLogTitleText>
        </StyledCard>
      </CardContainer>
    </StyledChangeLogCard>
  );
};

export default ChangeLogCardItem;
