import { TableExportOptions } from './Table';
import { Column, Row } from './TableTypes';

export function exportSpreadsheet(
  rows: Row[],
  columns: Column[],
  options?: TableExportOptions
) {
  const dataToExport = options?.dataOverride ?? rows;
  const filename = options?.title ?? `datatest-${new Date().toLocaleString()}`;
  const parsedData = dataToExport.map((row) =>
    row.cells.map((cell) => cell.value)
  );
  let spreadsheetResult;
  let download;
  if (options?.includeColumnHeaders) {
    const columnData = columns.map((c) => c.label);
    console.log(columnData);
    parsedData.unshift(columnData);
  }
  if (options?.xlsx) {
    const XLSX = options.xlsx;
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(parsedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const xlsxBuffer = XLSX.write(workbook, {
      type: 'buffer',
      bookType: 'xlsx',
    });
    const blob = new Blob([xlsxBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    spreadsheetResult = url;
    download = `${filename}.xlsx`;
  } else {
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      encodeURIComponent(parsedData.map((row) => row.join(',')).join('\n'));
    spreadsheetResult = csvContent;
    download = `${filename}.csv`;
  }
  const link = document.createElement('a');
  link.href = spreadsheetResult;
  link.download = download;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
