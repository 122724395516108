import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Flex } from 'components/Flex';
import { NoRateText } from '../rateProStyles';

interface Props {
  fallbackText: string;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const NoDataText = ({ text }: { text: string }) => (
  <Flex height="100%" alignItems="center" justifyContent="center">
    <NoRateText>{text}</NoRateText>
  </Flex>
);

export class RateProErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    console.error('dane');
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      const errorText = this.props.fallbackText || 'Error Loading Load History';
      return <NoDataText text={errorText} />;
    }

    return this.props.children;
  }
}
