import { FC, useContext, useState } from 'react';
import Autosuggest, {
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import { getPlaceSuggestions } from 'utils/googleMaps';
import {
  StyledAutosuggestWrapper,
  StyledInput,
  StyledInputContainer,
  StyledSuggestion,
  StyledSuggestionsContainer,
} from './Drawer/DrawerStyles';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import { LaneAndCapacityForm } from '../CapacityTypes';

interface Terms {
  value: string;
}

export type City = {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  terms: Terms[];
};

const SearchLocationsInputs: FC<{ name: string }> = ({ name }) => {
  const {
    laneAndCapacityForm,
    handleSuggestLocation,
    handleSuggestionSelected,
  } = useContext(CarrierCapacityContext);
  const [suggestions, setSuggestions] = useState<City[]>([]);

  const getSuggestionValue = (suggestion: City): string =>
    suggestion.description;

  const renderSuggestion = (suggestion: City) => {
    return <StyledSuggestion>{suggestion.description}</StyledSuggestion>;
  };

  const handleSuggestionsFetchRequested = async ({
    value,
  }: SuggestionsFetchRequestedParams) => {
    if (value.length > 1) {
      try {
        const getSuggestedData = await getPlaceSuggestions(value);
        const suggestedData = getSuggestedData
          .map((x) => {
            const buildCityState =
              x.terms.length === 4
                ? `${x.terms[0].value}, ${x.terms[1].value}, ${x.terms[2].value}`
                : x.terms.length === 3
                  ? `${x.terms[0].value}, ${x.terms[1].value}`
                  : '';
            return { ...x, description: buildCityState };
          })
          .filter((city) => city.description !== '');
        setSuggestions(suggestedData);
      } catch (error) {
        setSuggestions([]);
      }
    }
  };

  const handleSuggestionsClearRequested = (): void => {
    setSuggestions([]);
  };

  const handleOnBlur = () => {
    const currentValue =
      laneAndCapacityForm[name as keyof LaneAndCapacityForm]?.optionSelected
        ?.suggestion;

    if (!currentValue) {
      handleSuggestLocation({ target: { name, value: '' } } as any, {
        newValue: '',
        method: 'type',
      });
    }
  };

  const inputProps: Autosuggest.InputProps<City> = {
    name,
    value:
      laneAndCapacityForm[name as keyof LaneAndCapacityForm]?.optionSelected
        ?.optionName || '',
    onChange: handleSuggestLocation,
    onBlur: handleOnBlur,
  };

  return (
    <StyledAutosuggestWrapper>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(event, data) =>
          handleSuggestionSelected(event, data, name)
        }
        inputProps={inputProps}
        theme={{
          container: 'searcLocationsInputs__container',
          input: 'searcLocationsInputs__input',
          suggestionsContainer: 'searcLocationsInputs__suggestions-container',
          suggestionsList: 'searcLocationsInputs__suggestions-list',
          suggestion: 'searcLocationsInputs__suggestion',
        }}
        renderInputComponent={(inputProps) => (
          <StyledInputContainer
            className={
              laneAndCapacityForm[name as keyof LaneAndCapacityForm]
                ?.optionSelected?.optionName
                ? 'has-value'
                : ''
            }
          >
            <label className="floating-label">Location</label>
            <StyledInput {...inputProps} />
          </StyledInputContainer>
        )}
        renderSuggestionsContainer={({ containerProps, children }) => (
          <StyledSuggestionsContainer {...containerProps}>
            {children}
          </StyledSuggestionsContainer>
        )}
      />
    </StyledAutosuggestWrapper>
  );
};

export default SearchLocationsInputs;
