import { RtsLoadHistoryApiResponse } from '../types';
import http from '../../../../utils/httpUtils';
import { REACT_APP_RTS_HISTORY_BASE_URL } from 'utils/constants';
import {
  NewRateQuery,
  TrailerType,
  RateHistoryQueryParams,
} from 'features/ratePro/ratesTypes';

export const getRtsLoadHistory = async (
  searchQuery: NewRateQuery,
): Promise<RtsLoadHistoryApiResponse> => {
  if (
    !searchQuery ||
    !searchQuery.pickupCity ||
    !searchQuery.pickupState ||
    !searchQuery.deliveryCity ||
    !searchQuery.deliveryState ||
    !searchQuery.trailerType
  ) {
    return Promise.reject('missing search query params');
  }

  const requestParams: RateHistoryQueryParams = {
    originCity: searchQuery.pickupCity || '',
    originState: searchQuery.pickupState || '',
    destinationCity: searchQuery.deliveryCity || '',
    destinationState: searchQuery.deliveryState || '',
    trailerType: searchQuery.trailerType || TrailerType.VAN,
  };

  const queryString = new URLSearchParams(requestParams).toString();
  const fullPath = `${REACT_APP_RTS_HISTORY_BASE_URL}/rates/history?${queryString}`;

  const response = await http
    .get<RtsLoadHistoryApiResponse>(fullPath)()
    .catch((error: any) => {
      return Promise.reject(`error: ${JSON.stringify(error)}`);
    });

  return response as RtsLoadHistoryApiResponse;
};
