import queryString from 'query-string';
import { getToken } from './authUtils';

const getDefaultOptions = () => {
  return {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  };
};

const handleResponse = async (response: Response) => {
  if (response.status === 201) {
    return {};
  }
  const responseBody = await response.text();
  let data;

  try {
    data = JSON.parse(responseBody);
  } catch (error) {
    data = responseBody;
  }

  if (!response.ok) {
    const message =
      typeof data === 'string' ? data : data?.message || 'Unknown error';
    throw new Error(message);
  }

  return data;
};

const get =
  <T extends unknown, Q extends unknown = {}>(url: string) =>
  async (query?: Q): Promise<T> => {
    const formattedUrl = query
      ? `${url}?${queryString.stringify(query as any)}`
      : `${url}`;

    return handleResponse(await fetch(formattedUrl, getDefaultOptions()));
  };

const post =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body: B): Promise<T> => {
    return handleResponse(
      await fetch(`${url}`, {
        ...getDefaultOptions(),
        method: 'POST',
        body: JSON.stringify(body),
      }),
    );
  };

const put =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body: B): Promise<T> => {
    return handleResponse(
      await fetch(`${url}`, {
        ...getDefaultOptions(),
        method: 'PUT',
        body: JSON.stringify(body),
      }),
    );
  };

export type HttpMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const customHTTPMethodWithPath =
  <P, C extends HttpMethods, B extends unknown>(url: string) =>
  async <T extends unknown>(path: P, method: C, body?: B): Promise<T> => {
    return handleResponse(
      await fetch(`${url}/${path}`, {
        ...getDefaultOptions(),
        method,
        ...(body && { body: JSON.stringify(body) }),
      }),
    );
  };

const httpUtils = { get, put, post, customHTTPMethodWithPath };
export default httpUtils;
