import * as Ably from 'ably';
import http from 'utils/httpUtils';
import {
  ABLY_AUTH_SERVICE_URL_V2,
  ABLY_QUOTE_CHANNEL,
  ABLY_QUOTE_CHANNEL_CAM_USER,
} from 'utils/constants';
import { getUserLocalStorage, isCAMUser } from 'utils/authUtils';

let ably: Ably.Types.RealtimePromise | undefined;

const getAblyWildcardToken = (channel: string) =>
  http.get<Ably.Types.TokenDetails>(`${ABLY_AUTH_SERVICE_URL_V2}/${channel}`);

const ablyAuthCallback =
  (channel: string) =>
  async (
    _tokenParams: Ably.Types.TokenParams,
    callback: (
      error: Ably.Types.ErrorInfo | string,
      tokenRequestOrDetails:
        | Ably.Types.TokenDetails
        | Ably.Types.TokenRequest
        | string,
    ) => void,
  ) => {
    try {
      const _getAblyWildcardToken = getAblyWildcardToken(channel);
      const token = await _getAblyWildcardToken();
      console.log('Ably Capability :>> ', token.capability);
      callback('', token);
    } catch (error) {
      callback(error as Ably.Types.ErrorInfo, '');
    }
  };

export const initAbly = () => {
  const currentUser = getUserLocalStorage();
  const _isCAMUser = isCAMUser();
  console.log('_isCAMUser :>> ', _isCAMUser);
  let channelName: string = '';

  if (_isCAMUser) {
    console.log('Cam ably channel');
    channelName = ABLY_QUOTE_CHANNEL_CAM_USER as string;
  } else {
    console.log('loadBoard Ably Channel');
    channelName = ABLY_QUOTE_CHANNEL as string;
  }

  console.log('channelName :>> ', channelName);

  if (!currentUser && process.env.NODE_ENV !== 'test') {
    throw new Error(
      'Cannot initialize ably without a currently logged in user.',
    );
  }
  ably = new Ably.Realtime.Promise({
    authCallback: ablyAuthCallback(channelName),
  });
  return ably;
};
