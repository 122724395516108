import 'shamrock-clover-ui/dist/clover/css/clover.css';
import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { reportWebVitals } from './reportWebVitals';
import { CloverThemeProvider } from 'shamrock-clover-ui';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from './hooks/useSnackbar';
import * as firebaseUtils from './utils/firebaseUtils';
import { AccessorialDialogProvider } from 'hooks/useAccessorialFeesDialog';

const queryClient = new QueryClient();

const firebaseConfigProd = {
  apiKey: 'AIzaSyAnfAhdvyuTNmrhcik20-zsbplwkD2_jrI',
  authDomain: 'carrierpro-144918.firebaseapp.com',
  databaseURL: 'https://carrierpro-144918.firebaseio.com',
  projectId: 'carrierpro-144918',
  storageBucket: 'carrierpro-144918.appspot.com',
  messagingSenderId: '715693729194',
  appId: '1:715693729194:web:9ddbb35da0c9750d67b7d3',
  measurementId: 'G-Y1EFMEJ7NL',
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyA54njeRl8HgA_1LQe9QRaCtESlr3DvzEo',
  authDomain: 'carrierpro-dev.firebaseapp.com',
  databaseURL: 'https://carrierpro-dev.firebaseio.com',
  projectId: 'carrierpro-dev',
  storageBucket: 'carrierpro-dev.appspot.com',
  messagingSenderId: '980554620681',
  appId: '1:980554620681:web:3d0bd3ae9a4ed2b8157dd8',
  measurementId: 'G-Y7W83YK56Q',
};

var environment = 'development';
try {
  environment = process.env.NODE_ENV || 'development';
} catch (e) {}

firebaseUtils
  .init(environment === 'production' ? firebaseConfigProd : firebaseConfigDev)
  .catch((error) => {
    console.log(error.message);
  });

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <CloverThemeProvider>
      <AccessorialDialogProvider>
        <SnackbarProvider>
          <Router>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </Router>
        </SnackbarProvider>
      </AccessorialDialogProvider>
    </CloverThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
