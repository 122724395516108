import { isFifteenMinutesPastTime } from 'utils/dateUtils';
import isArray from 'lodash/isArray';
import {
  QuoteData,
  QuoteHistoryItem,
  Quote,
} from 'features/quoting/utils/quoteTypes';
import { isInternalTestingUser } from '../../../utils/authUtils';
import { findLast } from 'lodash';
import { OffersHistory, TimePassed } from './quoteTypes';

export const isExpired = (
  history: QuoteHistoryItem[] | QuoteHistoryItem,
  setTimer?: number,
) => {
  if (isArray(history)) {
    const lastHistoryItem = history[history.length - 1];
    return (
      (lastHistoryItem.status === 'approved' ||
        lastHistoryItem.status === 'countered') &&
      lastHistoryItem.isInternalUser === true &&
      isFifteenMinutesPastTime(lastHistoryItem.createdAt, setTimer)
    );
  }
  return (
    (history.status === 'approved' || history.status === 'countered') &&
    history.isInternalUser === true &&
    isFifteenMinutesPastTime(history.createdAt, setTimer)
  );
};

export const getTimePassed = (timePassed: Date): TimePassed => {
  const now = new Date();
  const getTimePassed = Math.abs(now.getTime() - timePassed.getTime());

  const secondsPassed = Math.floor(getTimePassed / 1000);

  const minutesPassed = Math.floor(secondsPassed / 60);
  const hoursPassed = Math.floor(minutesPassed / 60);
  const minutesRemaining = minutesPassed % 60;
  const secondsRemaining = secondsPassed % 60;

  return { minutesRemaining, hoursPassed, secondsRemaining };
};

const timeText = (timePortion: number): string => {
  const textOutput = timePortion < 10 ? `0${timePortion}` : `${timePortion}`;
  return textOutput;
};

export const getTimerDisplay = (history: QuoteHistoryItem[]): string => {
  const lastHistory = history[history.length - 1];
  const historyDate = new Date(lastHistory.createdAt);
  const timePassed: TimePassed = getTimePassed(historyDate);
  const timeDisplay =
    timePassed.hoursPassed > 0
      ? `${timeText(timePassed.hoursPassed)}:${timeText(
          timePassed.minutesRemaining,
        )}:${timeText(timePassed.secondsRemaining)}`
      : `${timeText(timePassed.minutesRemaining)}:${timeText(
          timePassed.secondsRemaining,
        )}`;
  return timeDisplay;
};

export const getLastCustomerQuoteWithNoAdminAction = (quote: QuoteData) => {
  return quote.history.reduce(
    (lastCustomerQuote, historyItem, index, history) => {
      const nextItem = history[index + 1];
      if (nextItem) {
        return nextItem.isInternalUser ? null : lastCustomerQuote;
      }
      return historyItem.isInternalUser ? lastCustomerQuote : historyItem;
    },
    null as QuoteHistoryItem | null,
  );
};

export const getRelevantHistoryItem = (history: QuoteHistoryItem[]) => {
  return findLast(
    history,
    (x) =>
      (x.status === 'countered' && x.isInternalUser === false) ||
      x.status === 'pending',
  );
};

export const isBookItNow = (quote: QuoteData) => {
  return quote.bookItRequest;
};

export const getDisplayByStatus = (status: string) => {
  switch (status) {
    case 'new':
      return 'New Quote';
    case 'negotiating':
      return 'Negotiating Quotes';
    case 'verifying':
      return 'Verifying Quotes';
    case 'booked':
      return 'Booked Quotes';
    case 'dismissed':
      return 'Dismissed Quotes';
    default:
      return 'Other Quote';
  }
};

export const isCarrierDeclined = (
  history: QuoteHistoryItem[],
  removeQuote?: boolean,
) => {
  const lastHistoryItem = history[history.length - 1];
  return (
    lastHistoryItem.status === 'denied' &&
    lastHistoryItem.isInternalUser === false
  );
};

export const isPending = (quote: QuoteData) => {
  if (!isInternalTestingUser() && isRyanTransEmailQuote(quote)) {
    return false;
  }
  const lastHistoryItem = findLast(quote.history);
  return (
    lastHistoryItem?.status !== 'denied' &&
    lastHistoryItem?.status !== 'completed'
  );
};

export const searchIsPending = (quote: QuoteData) => {
  if (!isInternalTestingUser() && isRyanTransEmailQuote(quote)) {
    return false;
  }
  const lastHistoryItem = findLast(quote.history);
  return (
    lastHistoryItem?.status !== 'denied' &&
    lastHistoryItem?.status !== 'completed'
  );
};

export const isBooked = (quote: QuoteData) => {
  if (!isInternalTestingUser() && isRyanTransEmailQuote(quote)) {
    return false;
  }
  const lastHistoryItem = findLast(quote.history);
  return lastHistoryItem?.status === 'completed';
};

export const getBookedHistoryItem = (quotes: QuoteData[]) => {
  const bookedQuote = quotes.find(isBooked);
  return bookedQuote ? findLast(bookedQuote.history) : undefined;
};

const isRyanTransEmailQuote = ({ email }: QuoteData) => {
  const domain = email.substring(email.lastIndexOf('@'), email.length);
  return domain === '@ryanrts.com';
};

export const isQuoteReady = (quotes: QuoteData) => {
  const lastHistoryItem = quotes.history[quotes.history.length - 1];
  return lastHistoryItem.status === 'approved';
};

export const getStatusFromPath = (pathname: string): string =>
  !pathname.split('/')[2] ? 'new' : pathname.split('/')[2];

export const currentAndPreviousOffer = (
  historyReverseOffer: QuoteHistoryItem[],
): OffersHistory => {
  const offer: OffersHistory = {
    count: 0,
    previouslyOffer: [],
    currentOffer: [],
    BNCount: 0,
  };

  for (const data of historyReverseOffer) {
    if (data.status === 'pending' && offer.count === 0) {
      offer.currentOffer.push(data);
      offer.count = offer.count + 1;
    } else if (offer.count === 0 && data.status !== 'pending') {
      offer.currentOffer.push(data);
    } else {
      offer.previouslyOffer.push(data);
    }
  }

  if (
    offer.previouslyOffer.length === 0 &&
    offer.currentOffer.some((x) => x.status === 'denied')
  ) {
    offer.currentOffer = [];
    for (const BN of historyReverseOffer) {
      if (BN.status === 'approved' && offer.BNCount === 0) {
        offer.currentOffer.push(BN);
        offer.BNCount = offer.BNCount + 1;
      } else if (offer.BNCount === 0 && BN.status !== 'approved') {
        offer.currentOffer.push(BN);
      } else {
        offer.previouslyOffer.push(BN);
      }
    }
  }
  return offer;
};

export const reverseHistory = (history: QuoteHistoryItem[]) =>
  history.map((_, i, arr) => arr[arr.length - i - 1]);

export const getMargin = (total: number, currentValue: number): string => {
  const _total = `Counter, ${(((total - currentValue) / total) * 100).toFixed(
    0,
  )}% Margin`;
  return _total;
};

export const getDeclineBy = (quoteHistoryItem: QuoteHistoryItem): string => {
  if (quoteHistoryItem.comment === 'Expired by Carrier') {
    return 'Expired by Carrier';
  }
  if (quoteHistoryItem?.isInternalUser) {
    return `Decline by ${quoteHistoryItem.adminUser || 'Rep'}`;
  }

  return 'Decline by Carrier';
};

export const sortNewQuoteTypes = (
  aQuoteData: QuoteData,
  aLatestQuoteHistoryItem: QuoteHistoryItem,
  bQuoteData: QuoteData,
  bLatestQuoteHistoryItem: QuoteHistoryItem,
): number => {
  // Book It Now
  //  Oldest Date/Time Received
  //  Newest Date/Time Received
  // New Quotes
  //  Oldest Date/Time Received
  //  Newest Date/Time Received

  // If both quotes are book it now or both are not book it now, the oldest one is first
  if (
    (isBookItNow(aQuoteData) && isBookItNow(bQuoteData)) ||
    (!isBookItNow(aQuoteData) && !isBookItNow(bQuoteData))
  ) {
    return sortQuotesByOldestFirst(
      aLatestQuoteHistoryItem,
      bLatestQuoteHistoryItem,
    );
  }

  // If a is a book it now, it goes first
  if (isBookItNow(aQuoteData)) {
    return -1;
  }

  // If b is a book it now, it goes first
  if (isBookItNow(bQuoteData)) {
    return 1;
  }

  // Everything should have fallen into the conditions above, but sort by oldest first just in case
  return sortQuotesByOldestFirst(
    aLatestQuoteHistoryItem,
    bLatestQuoteHistoryItem,
  );
};

export const sortNegotiatingQuoteTypes = (
  aLatestQuoteHistoryItem: QuoteHistoryItem,
  bLatestQuoteHistoryItem: QuoteHistoryItem,
): number => {
  // Carrier Counters
  //  Oldest Date/Time Received
  //  Newest Date/Time Received
  // Carrier Declines
  //  Oldest Date/Time Received
  //  Newest Date/Time Received
  // RT Counters
  //  Oldest Date/Time Received
  //  Newest Date/Time Received

  // If both quotes are carrier counters or pending, the oldest one is first
  if (
    ['pending', 'countered'].includes(aLatestQuoteHistoryItem.status) &&
    aLatestQuoteHistoryItem.isInternalUser === false &&
    ['pending', 'countered'].includes(bLatestQuoteHistoryItem.status) &&
    bLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return sortQuotesByOldestFirst(
      aLatestQuoteHistoryItem,
      bLatestQuoteHistoryItem,
    );
  }

  // If a is a carrier counter or pending, it goes first
  if (
    ['pending', 'countered'].includes(aLatestQuoteHistoryItem.status) &&
    aLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return -1;
  }

  // If b is a carrier counter or pending, it goes first
  if (
    ['pending', 'countered'].includes(bLatestQuoteHistoryItem.status) &&
    bLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return 1;
  }

  // If both quotes are carrier declines, the oldest one is first
  if (
    aLatestQuoteHistoryItem.status === 'denied' &&
    aLatestQuoteHistoryItem.isInternalUser === false &&
    bLatestQuoteHistoryItem.status === 'denied' &&
    bLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return sortQuotesByOldestFirst(
      aLatestQuoteHistoryItem,
      bLatestQuoteHistoryItem,
    );
  }

  // If a is carrier denied, it goes first
  if (
    aLatestQuoteHistoryItem.status === 'denied' &&
    aLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return -1;
  }

  // If b is carrier denied, it goes first
  if (
    bLatestQuoteHistoryItem.status === 'denied' &&
    bLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return 1;
  }

  // If both quotes are RT counter, the oldest one is first
  if (
    aLatestQuoteHistoryItem.status === 'countered' &&
    aLatestQuoteHistoryItem.isInternalUser === true &&
    bLatestQuoteHistoryItem.status === 'countered' &&
    bLatestQuoteHistoryItem.isInternalUser === true
  ) {
    return sortQuotesByOldestFirst(
      aLatestQuoteHistoryItem,
      bLatestQuoteHistoryItem,
    );
  }

  // If a is RT counter, it goes first
  if (
    aLatestQuoteHistoryItem.status === 'countered' &&
    aLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return -1;
  }

  // If b is RT counter, it goes first
  if (
    bLatestQuoteHistoryItem.status === 'countered' &&
    bLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return 1;
  }

  // Everything should have fallen into the conditions above, but sort by oldest first just in case
  return sortQuotesByOldestFirst(
    aLatestQuoteHistoryItem,
    bLatestQuoteHistoryItem,
  );
};

export const sortVerifyingQuoteTypes = (
  aLatestQuoteHistoryItem: QuoteHistoryItem,
  bLatestQuoteHistoryItem: QuoteHistoryItem,
): number => {
  // Carrier Accepts
  //  Oldest Date/Time Received
  //  Newest Date/Time Received
  // RT Accepts
  //  Oldest Date/Time Received
  //  Newest Date/Time Received

  // If both quotes are carrier approved, the oldest one is first
  if (
    aLatestQuoteHistoryItem.status === 'approved' &&
    aLatestQuoteHistoryItem.isInternalUser === false &&
    bLatestQuoteHistoryItem.status === 'approved' &&
    bLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return sortQuotesByOldestFirst(
      aLatestQuoteHistoryItem,
      bLatestQuoteHistoryItem,
    );
  }

  // If a is carrier approved, it goes first
  if (
    aLatestQuoteHistoryItem.status === 'approved' &&
    aLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return -1;
  }

  // If b is carrier approved, it goes first
  if (
    bLatestQuoteHistoryItem.status === 'approved' &&
    bLatestQuoteHistoryItem.isInternalUser === false
  ) {
    return 1;
  }

  // If both quotes are RT approved, the oldest one is first
  if (
    aLatestQuoteHistoryItem.status === 'approved' &&
    aLatestQuoteHistoryItem.isInternalUser === true &&
    bLatestQuoteHistoryItem.status === 'approved' &&
    bLatestQuoteHistoryItem.isInternalUser === true
  ) {
    return sortQuotesByOldestFirst(
      aLatestQuoteHistoryItem,
      bLatestQuoteHistoryItem,
    );
  }

  // If a is RT approved, it goes first
  if (
    aLatestQuoteHistoryItem.status === 'approved' &&
    aLatestQuoteHistoryItem.isInternalUser === true
  ) {
    return -1;
  }

  // If b is RT approved, it goes first
  if (
    bLatestQuoteHistoryItem.status === 'approved' &&
    bLatestQuoteHistoryItem.isInternalUser === true
  ) {
    return 1;
  }

  // Everything should have fallen into the conditions above, but sort by oldest first just in case
  return sortQuotesByOldestFirst(
    aLatestQuoteHistoryItem,
    bLatestQuoteHistoryItem,
  );
};

export const sortQuotesByOldestFirst = (
  aLatestQuoteHistoryItem: QuoteHistoryItem,
  bLatestQuoteHistoryItem: QuoteHistoryItem,
): number => {
  return aLatestQuoteHistoryItem.createdAt.localeCompare(
    bLatestQuoteHistoryItem.createdAt,
  );
};

export const sortQuotesByNewestFirst = (
  aLatestQuoteHistoryItem: QuoteHistoryItem,
  bLatestQuoteHistoryItem: QuoteHistoryItem,
): number => {
  return bLatestQuoteHistoryItem.createdAt.localeCompare(
    aLatestQuoteHistoryItem.createdAt,
  );
};

export const sortQuotes = (
  type: string,
  aQuote: Quote,
  bQuote: Quote,
): number => {
  const aReverseHistory = reverseHistory(aQuote.quotes[0].history);
  const aLatestQuoteHistoryItem =
    currentAndPreviousOffer(aReverseHistory).currentOffer[0];

  const bReverseHistory = reverseHistory(bQuote.quotes[0].history);
  const bLatestQuoteHistoryItem =
    currentAndPreviousOffer(bReverseHistory).currentOffer[0];

  switch (type) {
    case 'new':
      return sortNewQuoteTypes(
        aQuote.quotes[0],
        aLatestQuoteHistoryItem,
        bQuote.quotes[0],
        bLatestQuoteHistoryItem,
      );
    case 'negotiating':
      return sortNegotiatingQuoteTypes(
        aLatestQuoteHistoryItem,
        bLatestQuoteHistoryItem,
      );
    case 'verifying':
      return sortVerifyingQuoteTypes(
        aLatestQuoteHistoryItem,
        bLatestQuoteHistoryItem,
      );
    case 'booked':
    case 'dismissed':
      return sortQuotesByNewestFirst(
        aLatestQuoteHistoryItem,
        bLatestQuoteHistoryItem,
      );
    default:
      return sortQuotesByOldestFirst(
        aLatestQuoteHistoryItem,
        bLatestQuoteHistoryItem,
      );
  }
};

export const getLastQuoteUpdated = (
  quotes: QuoteData[],
  timer: number,
): QuoteData => {
  let lastCreatedAt = new Date(0);
  let lastQuoteWithLastHistory: QuoteData = {} as QuoteData;
  let getExpiredQuote: QuoteData = {} as QuoteData;

  for (const quote of quotes) {
    const lastHistoryItem = quote.history[quote.history.length - 1];
    const createdAt = new Date(lastHistoryItem.createdAt);

    if (lastHistoryItem.isInternalUser) {
      const { hoursPassed, minutesRemaining } = getTimePassed(createdAt);
      if (hoursPassed > 0 || minutesRemaining > timer) {
        getExpiredQuote = quote;
      }
    }

    if (createdAt > lastCreatedAt) {
      lastCreatedAt = createdAt;
      lastQuoteWithLastHistory = quote;
    }
  }
  return Object.keys(getExpiredQuote).length > 0
    ? getExpiredQuote
    : lastQuoteWithLastHistory;
};

export const isDeclined = (history: QuoteHistoryItem[]) => {
  const lastHistoryItem = history[history.length - 1];
  return lastHistoryItem.status === 'denied';
};

export const isAdminApproved = (history: QuoteHistoryItem[]) => {
  const lastHistoryItem = history[history.length - 1];
  return (
    lastHistoryItem.status === 'approved' &&
    lastHistoryItem.isInternalUser === true
  );
};

export const isCarrierApproved = (history: QuoteHistoryItem[]) => {
  const lastHistoryItem = history[history.length - 1];
  return (
    lastHistoryItem.status === 'approved' &&
    lastHistoryItem.isInternalUser === false
  );
};

export const isAdminCountered = (history: QuoteHistoryItem[]) => {
  const lastHistoryItem = history[history.length - 1];
  return (
    lastHistoryItem.status === 'countered' &&
    lastHistoryItem.isInternalUser === true
  );
};
