export interface RateAdjustmentBody {
  region?: string;
  lowVanSonarBeginning?: string;
  lowVanSonarEnd?: string;
  averageVanSonarBeginning?: string;
  averageVanSonarEnd?: string;
  highVanSonarBeginning?: string;
  highVanSonarEnd?: string;
  lowReeferSonarBeginning?: string;
  lowReeferSonarEnd?: string;
  averageReeferSonarBeginning?: string;
  averageReeferSonarEnd?: string;
  highReeferSonarBeginning?: string;
  highReeferSonarEnd?: string;
  flatbedDatRate?: string;
  flatbedNumberOfLoads?: string;
  flatbedRtsRate?: string;
  email?: string;
  reeferNumberOfLoads?: string;
  reeferRtsRate?: string;
  vanNumberOfLoads?: string;
  vanRtsRate?: string;
  reason?: string;
}

export interface RateAdjustmentReasonBody {
  reason?: string;
}

export interface RateAdjustmentReasonApiResult {
  reason: string;
  createdAt: Date;
  email: string;
}

export interface RateAdjustmentApiResult {
  region: string;
  lowVanSonarBeginning: string;
  lowVanSonarEnd: string;
  averageVanSonarBeginning: string;
  averageVanSonarEnd: string;
  highVanSonarBeginning: string;
  highVanSonarEnd: string;
  lowReeferSonarBeginning: string;
  lowReeferSonarEnd: string;
  averageReeferSonarBeginning: string;
  averageReeferSonarEnd: string;
  highReeferSonarBeginning: string;
  highReeferSonarEnd: string;
  flatbedDatRate: string;
  flatbedNumberOfLoads: string;
  flatbedRtsRate: string;
  createdAt: Date;
  email: string;
  reeferNumberOfLoads: string;
  reeferRtsRate: string;
  vanNumberOfLoads: string;
  vanRtsRate: string;
  reason: string;
}

export const defaultsParams: RateAdjustmentBody = {
  averageReeferSonarBeginning: '1',
  averageReeferSonarEnd: '3',
  averageVanSonarBeginning: '1',
  averageVanSonarEnd: '3',
  flatbedDatRate: 'AVERAGE',
  flatbedNumberOfLoads: '5',
  flatbedRtsRate: 'LOWER',
  highReeferSonarBeginning: '-',
  highReeferSonarEnd: '-',
  highVanSonarBeginning: '-',
  highVanSonarEnd: '-',
  lowReeferSonarBeginning: '-3',
  lowReeferSonarEnd: '-1',
  lowVanSonarBeginning: '-3',
  lowVanSonarEnd: '-1',
  reeferNumberOfLoads: '5',
  reeferRtsRate: 'LOWER',
  vanNumberOfLoads: '5',
  vanRtsRate: 'LOWER',
};

/*
Aggressive
Van/Reefer
Sonar -3 to -1: DAT Low
Sonar 1 to 3: DAT Average
Override: 5 and LOW
Flatbed
DAT: Low
Override: 5 and LOW
*/
export const aggressiveDefaultsParams: RateAdjustmentBody = {
  lowReeferSonarBeginning: '-3',
  lowReeferSonarEnd: '-1',
  lowVanSonarBeginning: '-3',
  lowVanSonarEnd: '-1',
  averageReeferSonarBeginning: '1',
  averageReeferSonarEnd: '3',
  averageVanSonarBeginning: '1',
  averageVanSonarEnd: '3',
  highReeferSonarBeginning: '-',
  highReeferSonarEnd: '-',
  highVanSonarBeginning: '-',
  highVanSonarEnd: '-',
  vanNumberOfLoads: '5',
  vanRtsRate: 'LOWER',
  reeferNumberOfLoads: '5',
  reeferRtsRate: 'LOWER',
  flatbedNumberOfLoads: '5',
  flatbedRtsRate: 'LOWER',
  flatbedDatRate: 'LOW',
};
/*
Neutral/Moderate
Van/Reefer
Sonar -3 to -1: DAT Low
Sonar 1 to 2: DAT Average
Sonar 3: DAT High
Override: 5 and LOW
Flatbed
DAT: Average
Override: 5 and LOW
*/
export const moderateDefaultsParams: RateAdjustmentBody = {
  lowReeferSonarBeginning: '-3',
  lowReeferSonarEnd: '-1',
  lowVanSonarBeginning: '-3',
  lowVanSonarEnd: '-1',
  averageReeferSonarBeginning: '1',
  averageReeferSonarEnd: '2',
  averageVanSonarBeginning: '1',
  averageVanSonarEnd: '2',
  highReeferSonarBeginning: '3',
  highReeferSonarEnd: '3',
  highVanSonarBeginning: '3',
  highVanSonarEnd: '3',
  vanNumberOfLoads: '5',
  vanRtsRate: 'LOWER',
  reeferNumberOfLoads: '5',
  reeferRtsRate: 'LOWER',
  flatbedNumberOfLoads: '5',
  flatbedRtsRate: 'LOWER',
  flatbedDatRate: 'AVERAGE',
};

/*
High
Van/Reefer
Sonar -3: DAT Low
Sonar -2 to 1: DAT Average
Sonar 2 to 3: DAT High
Override: 5 and LOW
Flatbed
DAT: High
Override: 5 and LOW
 */
export const highDefaultsParams: RateAdjustmentBody = {
  lowReeferSonarBeginning: '-3',
  lowReeferSonarEnd: '-3',
  lowVanSonarBeginning: '-3',
  lowVanSonarEnd: '-3',
  averageReeferSonarBeginning: '-2',
  averageReeferSonarEnd: '1',
  averageVanSonarBeginning: '-2',
  averageVanSonarEnd: '1',
  highReeferSonarBeginning: '2',
  highReeferSonarEnd: '3',
  highVanSonarBeginning: '2',
  highVanSonarEnd: '3',
  vanNumberOfLoads: '5',
  vanRtsRate: 'LOWER',
  reeferNumberOfLoads: '5',
  reeferRtsRate: 'LOWER',
  flatbedNumberOfLoads: '5',
  flatbedRtsRate: 'LOWER',
  flatbedDatRate: 'HIGH',
};

export const clearValuesDefaultsParams: RateAdjustmentBody = {
  region: undefined,
  lowVanSonarBeginning: undefined,
  lowVanSonarEnd: undefined,
  averageVanSonarBeginning: undefined,
  averageVanSonarEnd: undefined,
  highVanSonarBeginning: undefined,
  highVanSonarEnd: undefined,
  lowReeferSonarBeginning: undefined,
  lowReeferSonarEnd: undefined,
  averageReeferSonarBeginning: undefined,
  averageReeferSonarEnd: undefined,
  highReeferSonarBeginning: undefined,
  highReeferSonarEnd: undefined,
  flatbedDatRate: undefined,
  flatbedNumberOfLoads: undefined,
  flatbedRtsRate: undefined,
  vanNumberOfLoads: undefined,
  vanRtsRate: undefined,
  reeferNumberOfLoads: undefined,
  reeferRtsRate: undefined,
  reason: undefined,
};

export interface AccessorialFeesApiResult {
  _id: string;
  driverAssist: number;
  hazmat: number;
  palletJack: number;
  ramps: number;
  sameDay: number;
  tarps: number;
  tankerEndorsed: number;
  team: number;
  additionalStops: {
    oneStop: number;
    twoStops: number;
    threeStops: number;
    fourPlusStops: number;
  };
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
}

export interface AccessorialFees {
  _id: string;
  driverAssist: number;
  hazmat: number;
  palletJack: number;
  ramps: number;
  sameDay: number;
  tarps: number;
  tankerEndorsed: number;
  team: number;
  additionalStops: {
    oneStop: number;
    twoStops: number;
    threeStops: number;
    fourPlusStops: number;
  };
}

export interface AccessorialFeesBody {
  driverAssist: number;
  hazmat: number;
  palletJack: number;
  ramps: number;
  sameDay: number;
  tarps: number;
  tankerEndorsed: number;
  team: number;
  additionalStops: {
    oneStop: number;
    twoStops: number;
    threeStops: number;
    fourPlusStops: number;
  };
}

export interface AccessorialFeesPostResponse {
  message: string;
}

export interface AccessorialChangeLog {
  accessorialChange: true;
  change: {
    accessorial: string;
    previousValue: number | { key: string; value: number }[];
    updatedValue: number | { key: string; value: number }[];
  }[];
  createdAt: Date;
  email: string;
}
