import { styled } from 'shamrock-clover-ui';
import {
  FormControl,
  MenuItem,
  withStyles,
  InputLabel,
} from '@material-ui/core';
import {
  Button,
  CurrencyFormatTextInput,
  SelectInput,
} from 'shamrock-clover-ui';
import Grid from '@material-ui/core/Grid';

export const Container = styled.div<{
  paddingLeft?: string;
  paddingTop?: string;
  paddingRight?: string;
}>`
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.gray[10]};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '0px')};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '0px')};
  padding-right: ${({ paddingRight }) => (paddingRight ? paddingRight : '0px')};
`;

export const GridContainer = styled.div<{ minWidth?: string }>`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  padding: 16px 64px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  align-items: center;
  max-width: 430px;
  min-width: 360px;
`;

export const GridArea = styled.div<{ gridArea?: string }>`
  background-color: white;
  padding: 24px;
  min-width: 580px;
`;

export const RegionTitleText = styled.div<{
  fontSize: string;
  height?: string;
  lineHeight?: string;
  width?: string;
  color?: string;
  padding?: string;
  paddingLeft?: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => (color ? color : '#333333')};
  height: ${({ height }) => height};
  line-height: ${({ lineHeight }) => lineHeight};
  width: ${({ width }) => width};
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  padding: ${({ padding }) => (padding ? padding : '24px 0px 24px 0px')};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '0px')};
`;

export const CardContainer = styled.div<{
  flexDirection: string;
  justifyContent?: string;
  paddingRight?: string;
}>`
  display: flex;
  flex: 1;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-grow: 1;
  width: 100%;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
  padding-right: ${({ paddingRight }) => (paddingRight ? '16px' : '')};
`;

export const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Proxima Nova',
    fontSize: 16,
    color: '#333333',
  },
})(MenuItem);

export const StyledInputLabel = styled(InputLabel)`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #333333;
`;

export const SelectContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  min-width: 300px;
  max-width: 725px;
`;

export const SelectReasonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 36px;
  width: 725px
  flex-direction: column;
  z-index: 9999999999 !important;
`;

export const Seperator = styled.div`
  width: 725px;
  height: 1px;
  background-color: #cccccc;
  margin-top: 14px;
`;

export const EquipmentContainer = styled(Grid)<{
  flexDirection?: string;
  justifyContent?: string;
  flexWrap?: string;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: 1 1 auto;
  gap: 24px;
  width: 100%;
  height: auto;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const StyledEquipmentCard = styled.div<{
  height: string;
  width: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.294007);
  border-radius: 2px;
`;

export const StyledEquipmentCardHeader = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 47px;
  background-color: #cccccc;
  background: #eeeeee;
  align-items: center;
  justify-content: flex-start;
`;

export const StyleDatText = styled.div<{
  marginLeft: string;
  fontWeight: string;
  marginTop: string;
  padding?: string;
}>`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0875px;
  color: #666666;
  padding: ${({ padding }) => (padding ? padding : '0px')};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const StyledCard = styled.div<{
  height: string;
  topBorder?: string;
  borderBottom?: string;
  background?: string;
  flexDirection?: string;
  justifyContent?: string;
  width?: string;
  paddingLeft?: string;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'column'};
  align-items: center;
  padding: 24px 0px 0px;
  gap: 24px;
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => height || 'auto'};
  background: ${({ background }) => (background ? background : '#ffffff')};
  border-top: ${({ topBorder }) => topBorder};
  border-bottom: ${({ borderBottom }) => borderBottom};
  justify-content: ${({ justifyContent }) => justifyContent};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '0px')};
  max-width: 1471px;
`;

export const SplitCardText = styled.div`
  font-family: Proxima Nova;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.92px;
  text-align: left;

  color: #333333;
  width: 100%;
  min-width: 190px;
  padding: 10px 8px;
`;
export const SplitCardTextLine = styled.div`
  margin-top: 8px;
`;

export const StyledEquipmentCardContainer = styled.div<{
  flexDirection: string;
}>`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 109px;
  justify-content: flex-start;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const StyledSonarContainer = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 109px;
  justify-content: flex-start;
  flex-direction: row;
`;

export const StyledSonarFormControl = withStyles({
  root: {
    marginTop: '18px',
    width: '86px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    marginLeft: '15px',
  },
})(FormControl);

export const SonarSeperator = styled.div<{
  width: string;
}>`
  width:: ${({ width }) => width};
  height: 1px;
  background-color: #cccccc;
`;

export const StyledSonarReeferFormControl = withStyles({
  root: {
    marginTop: '18px',
    width: '88%',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    marginLeft: '15px',
  },
})(FormControl);

export const StyledHistoryReeferFormControl = withStyles({
  root: {
    marginTop: '18px',
    width: '86px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    marginLeft: '15px',
  },
})(FormControl);

export const Step1HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 109px;
  justify-content: space-between;
  flex-direction: row;
`;

export const Step1HeaderDefaultButton = styled.div`
  width: 148px;
  height: 17px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #0091ea;
  margin-right: 24px;
  margin-top: 24px;
  cursor: pointer;
`;

export const SelectDefaultContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%
  flex-direction: column;
`;

export const ChangeLogSelectContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background: #f9f9f9;
  border-radius: 4px;
  align-items: center;
  height: 98px;
  margin-bottom: 24px;
`;

export const ChangeLogCardContainer = styled.div<{
  flexDirection: string;
  justifyContent?: string;
}>`
  display: flex;
  flex: 1;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
  background: #ffffff;
`;

export const StyledFormControlChangeLog = withStyles({
  root: {
    marginTop: '5px',
    minWidth: '90px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: '#333333',
    width: '225px',
  },
})(FormControl);

export const StyledFeesCard = styled.div<{
  height: string;
  width: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.294007);
  border-radius: 2px;
  margin-left: 24px;
  margin-bottom: 24px;
`;

export const StyledFeesCardHeader = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 47px;
  background-color: #cccccc;
  background: #eeeeee;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledFeesText = styled.div`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0875px;
  color: #666666;
  margin-left: 16px;
  margin-top: 0px;
`;

export const StyledFeesCardContainer = styled.div<{
  height?: string;
}>`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: ${({ height }) => (height ? height : '109px')};
  justify-content: flex-start;
  flex-direction: column;
`;

export const StyledCurrencyFormatTextInput = styled(CurrencyFormatTextInput)<{
  paddingTop?: string;
  isLoading?: boolean;
}>`
  width: 172px;
  margin-left: 16px;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '0px')};
  input {
    ${({ isLoading }) => isLoading && 'color: #999999 !important;'}
  }
`;

export const StyledGridContainer = styled(Grid)<{
  paddingRight?: string;
  paddingLeft?: string;
  paddingBottom?: string;
  paddingTop?: string;
}>`
  padding-right: ${({ paddingRight }) =>
    paddingRight ? paddingRight : '24px'};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '24px')};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '24px')};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : ''};
`;

export const StyledDiscardButton = styled(Button)<{
  marginRight?: string;
}>`
  width: 165px;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
`;

export const StyledSaveButton = styled(Button)`
  width: 140px;
`;

export const StyledRateAdjustmentGridItem = styled(Grid)`
  padding-left: 18px;
  padding-bottom: 24px;
`;

export const StyledSelectInput = styled(SelectInput)<{
  id: string;
}>`
  id: ${({ id }) => id};
`;
