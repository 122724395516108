import * as React from 'react';
import RateProToolbar from './RateProToolbar';
import Footer from './Footer/Footer';
import Card from 'components/Card';
import { useMpactData, useRateData } from './useRateData';
import { NewRateQuery, TrailerType } from './ratesTypes';
import { Flex } from 'components/Flex';
import SpinnerWithWait from 'components/Spinner';
import RateDisplay from './RateDisplay';
// import HowIsThisCalculatedDialog from './HowIsThisCalculatedDialog';
import { getSuggestedBuyRateNew } from './suggestedBuyRates/suggestedBuyRate';

import {
  GridContainer,
  Container,
  GridArea,
  ErrrorText,
  NoRateText,
  LargeText,
  MediumText,
  Line,
} from './rateProStyles';

import { Rate } from './ratesTypes';
import { useGetRateAdjustmentData } from '../../features/rateProAdmin/useRateAdminData';
import { MpactCard } from './cards/MpactCard';
import { useFeatureFlags } from 'utils/featureFlagContext';
import { fetchContentfulData } from './contentfulApi';
import { RtsLoadHistoryRateDisplay } from './loadHistory/RtsLoadHistoryRateDisplay';
import { RateProErrorBoundary } from './loadHistory/RTSHistoryErrorBoundary';
import { useLoadHistoryData } from './loadHistory/hooks/useLoadHistoryData';
import { useState } from 'react';
import { RateAdjustmentApiResult } from 'features/rateProAdmin/rateAdminTypes';
import {
  SuggestedbuyRateCard,
  getSuggestedBuyRateStatus,
} from './cards/SuggestedBuyRateCard';
import { getRegionByState } from 'features/rateProAdmin/rateProAdminRegions';

const ErrorText = ({ text }: { text: string }) => (
  <Flex height="100%" alignItems="center" justifyContent="center">
    <ErrrorText>{text}</ErrrorText>
  </Flex>
);

const NoDataText = ({ text }: { text: string }) => (
  <Flex height="100%" alignItems="center" justifyContent="center">
    <NoRateText>{text}</NoRateText>
  </Flex>
);

const RatePro = () => {
  const { rateAdjustmenResult } = useGetRateAdjustmentData();
  const [newBuyRate, setNewBuyRate] = useState<number>();
  const [suggestedRateByRegion, setSuggestedRateByRegion] = useState<
    string | undefined
  >(undefined);
  const [searchQuery, setSearchQuery] = useState<NewRateQuery>();
  const [dialogTitle, setDialogTitle] = useState<string>();
  const [dialogDescription, setDialogDescription] = useState<string>();

  React.useEffect(() => {
    const fetchData = async () => {
      const { title, description } = await fetchContentfulData();
      if (title) {
        setDialogTitle(title);
      }
      if (description) {
        setDialogDescription(description);
      }
    };

    fetchData();
  }, []);
  const { isRtsMilesServiceFallbackEnabled } = useFeatureFlags();

  const {
    LoadHistoryResults: {
      isLoading: LoadHistoryIsLoading,
      data: loadHistoryData,
      error: LoadHistoryError,
    },
  } = useLoadHistoryData(searchQuery);

  const {
    RateViewResults: {
      isError: RateViewIsError,
      isLoading: RateViewIsLoading,
      data: RateViewData,
      rateViewAverage,
      rateViewHigh,
      rateViewLow,
      error: RateViewError,
      rateViewFuelCostPerTrip,
    },
    RTSHistoryRateResults: {
      // isError: RTSHistoryRateIsError,
      // isLoading: RTSHistoryRateIsLoading,
      data: RTSHistoryRateData,
      // error: RTSHistoryRateError,
    },
    RTSMilesResults: {
      // isError: RTSMilesIsError,
      // isLoading: RTSMilesIsLoading,
      data: RTSMilesData,
      // error: RTSMilesError,
    },
    RateCastResults: {
      isError: RateCastIsError,
      isLoading: RateCastIsLoading,
      data: RateCastData,
      error: RateCastError,
    },
    SonarScoreResults: {
      isError: SonarScoreIsError,
      isLoading: SonarScoreIsLoading,
      data: SonarScoreData,
    },
    getSuggestedBuyRateByRegion,
    submitRate,
  } = useRateData(searchQuery);

  const {
    MpactDataResults: {
      isError: MpactDataIsError,
      isLoading: MpactDataIsLoading,
      data: MpactData,
      error: MpactDataError,
    },
  } = useMpactData(searchQuery);

  // Calculate the suggested buy rate and watch for changes.
  React.useEffect(() => {
    const getBuyRate = async () => {
      const originState = searchQuery?.pickupState;
      const rateAdjustmentApiResult: RateAdjustmentApiResult[] | undefined =
        rateAdjustmenResult;
      const sonarScore = SonarScoreData?.score;

      // The calculation is the same as before except with new RTS history data.
      const rtsHistoryRateAverage = loadHistoryData?.stats.avg;
      const rtsHistoryLoads = loadHistoryData?.totalResults;
      let equipmentType = searchQuery?.trailerType;

      if (
        originState === undefined ||
        rateAdjustmentApiResult === undefined ||
        rateViewAverage === undefined ||
        rateViewHigh === undefined ||
        rateViewLow === undefined ||
        equipmentType === undefined
      ) {
        return;
      }

      const region = getRegionByState(originState);

      const regionAdjustment = rateAdjustmentApiResult?.find(
        (e) => e.region === region || e.region === 'all',
      );

      const buyRateResult = await getSuggestedBuyRateNew({
        regionAdjustment,
        sonarScore,
        rateViewAverage,
        rateViewHigh,
        rateViewLow,
        rtsHistoryRateAverage,
        rtsHistoryLoads,
        equipmentType,
      });

      if (!buyRateResult) {
        return;
      }
      console.log(`Setting newBuyRate to: ${buyRateResult}`);

      setNewBuyRate(buyRateResult);
    };
    getBuyRate();
  }, [
    rateAdjustmenResult,
    rateViewAverage,
    rateViewHigh,
    rateViewLow,
    searchQuery,
    SonarScoreData,
    RateViewData,
    loadHistoryData,
    rateViewFuelCostPerTrip,
  ]);

  /**
   * All of this work happening in the same useEffect means we likely
   * have times where we are rerendering too many components instead of the narrow scope that needs to.
   **/
  React.useEffect(() => {
    const getRate = async () => {
      if (
        rateAdjustmenResult &&
        searchQuery?.pickupCity &&
        searchQuery.pickupState &&
        searchQuery.deliveryCity &&
        searchQuery.deliveryState &&
        searchQuery.trailerType
      ) {
        const rate = await getSuggestedBuyRateByRegion(rateAdjustmenResult);
        console.log(`Setting suggestedRateByRegion to: ${rate}`);
        setSuggestedRateByRegion(rate);
      }
    };

    getRate();
  }, [
    rateAdjustmenResult,
    searchQuery,
    SonarScoreData,
    RTSHistoryRateData,
    RateViewData,
    getSuggestedBuyRateByRegion,
  ]);

  const isEmpty = (data: undefined | Rate | Rate[]) => {
    if (!data) {
      return false;
    }

    if (Array.isArray(data)) {
      return data.length === 0;
    }

    return Object.keys(data).length === 0;
  };

  return (
    <Container>
      <GridContainer>
        <GridArea gridArea="1 / 1 / 1 / 13">
          <RateProToolbar
            getRatesOnClick={setSearchQuery}
            mileage={
              RateViewData?.mileage ||
              (isRtsMilesServiceFallbackEnabled
                ? RTSMilesData?.totalMiles
                : undefined)
            }
            isLoading={
              LoadHistoryIsLoading ||
              RateViewIsLoading ||
              RateCastIsLoading ||
              SonarScoreIsLoading ||
              MpactDataIsLoading
            }
          />
        </GridArea>
        <GridArea gridArea="2 / 1 / auto / 5">
          <Card
            contentHeight="215px"
            primaryTitle="DAT"
            secondaryTitle="RATEVIEW"
          >
            {(RateViewIsError && RateViewError.message === 'Rate not found.') ||
            isEmpty(RateViewData) ? (
              <NoDataText text="No DAT Rateview Available" />
            ) : (
              RateViewIsError && <ErrorText text="Failed to return rates" />
            )}
            {RateViewIsLoading && <SpinnerWithWait />}
            {!RateViewIsError && RateViewData && (
              <RateDisplay {...RateViewData} />
            )}
          </Card>
        </GridArea>

        <GridArea gridArea="2 / 5 / auto / 9">
          <Card
            contentHeight="215px"
            primaryTitle="RTS"
            secondaryTitle="HISTORY"
          >
            <RateProErrorBoundary fallbackText={'No RTS History Available'}>
              {loadHistoryData && loadHistoryData.totalResults === 0 ? (
                <NoDataText text="No RTS History Available" />
              ) : (
                <>
                  {LoadHistoryIsLoading ? (
                    <SpinnerWithWait />
                  ) : (
                    <>
                      {!LoadHistoryError && loadHistoryData && searchQuery && (
                        <RtsLoadHistoryRateDisplay
                          loadHistoryData={loadHistoryData}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </RateProErrorBoundary>
          </Card>
        </GridArea>
        <GridArea gridArea="2 / 9 / auto / 13">
          <Card
            contentHeight="215px"
            primaryTitle="DAT"
            secondaryTitle="RATECAST"
          >
            {(RateCastIsError && RateCastError.message === 'Rate not found.') ||
            isEmpty(RateCastData) ? (
              <NoDataText text="No DAT Ratecast Available" />
            ) : (
              RateCastIsError && <ErrorText text="Failed to return rates" />
            )}
            {RateCastIsLoading && <SpinnerWithWait />}
            {!RateCastIsError && RateCastData && !isEmpty(RateCastData) && (
              <>
                <RateDisplay {...RateCastData[0]} />
                <Line />
                <RateDisplay {...RateCastData[1]} />
              </>
            )}
          </Card>
        </GridArea>
        <GridArea gridArea="3 / 1 / auto / 5">
          <Card contentHeight="74px" secondaryTitle="SONAR SCORE">
            {searchQuery?.trailerType?.toUpperCase() ===
              TrailerType.FLATBED && (
              <NoDataText text="Sonar Score Unavailable for Flatbed" />
            )}
            {SonarScoreIsError && (
              <NoDataText text="No Sonar Score Available" />
            )}
            {SonarScoreIsLoading && <SpinnerWithWait />}
            {!SonarScoreIsError && SonarScoreData && (
              <Flex alignItems="center" height="100%">
                <LargeText margin="0px 16px 0px 0px">
                  {SonarScoreData.score}
                </LargeText>
                <MediumText bold={false}>
                  {SonarScoreData.description}
                </MediumText>
              </Flex>
            )}
          </Card>
        </GridArea>
        <GridArea gridArea="3 / 5 / auto / 9">
          <SuggestedbuyRateCard
            buyRate={newBuyRate}
            cardState={getSuggestedBuyRateStatus(
              SonarScoreIsLoading,
              newBuyRate,
            )}
            helpDialogDescription={dialogDescription || ''}
            helpDialogTitle={dialogTitle || ''}
          />
        </GridArea>

        <MpactCard
          mpactData={MpactData}
          mileage={
            RateViewData?.mileage ||
            (isRtsMilesServiceFallbackEnabled
              ? RTSMilesData?.totalMiles
              : undefined)
          }
          isError={MpactDataIsError}
          isLoading={MpactDataIsLoading}
          error={MpactDataError}
        />
        <GridArea gridArea="6 / 1 / auto / 13">
          <Footer
            submitRate={submitRate}
            hasRate={RateCastData !== undefined}
            suggestedBuyRate={suggestedRateByRegion}
            isSellRateFFOn={false}
          />
        </GridArea>
      </GridContainer>
    </Container>
  );
};

export default RatePro;
