import Card from 'components/Card';
import { LargeText, MediumText } from '../rateProStyles';
import React, { useEffect, useState } from 'react';
import { numberToCurrencyString } from '../../../utils/numberUtils';
import { styled, SmrRadioButton, CheckBox } from 'shamrock-clover-ui';
import { PillCounter } from '../../../components/PillCounter/PillCounter';
import {
  HubtekApiResponse,
  TrailerType,
  AccessorialsType,
  HubtekRequestQuery,
  NewRateQuery,
} from '../ratesTypes';
import SpinnerWithWait from '../../../components/LoadingSpinner';
import { Button } from 'shamrock-clover-ui';
import _ from 'lodash';

const CalculatorForm = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 50px;
`;
const RateToleranceSection = styled.div``;
const AccessorialsSection = styled.div``;
const SmallTitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #666666;
  margin-bottom: 20px;
`;
const CalculationText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #333333;
`;
const CalculationAmount = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #333333;
`;
const CheckBoxContainer = styled.div`
  display: flex;
  gap: 20px;
`;
const PillCounterContainer = styled.div`
  margin-top: 20px;
`;
const CheckBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const CalculationContainer = styled.div``;

const CalculationRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TotalRow = styled.div`
  background-color: #eeeeee;
  font-weight: 700;
  margin-top: 14px;
  padding-left: 4px;
`;
const TotalAmount = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledHR = styled.div<{ color?: string }>`
  border-bottom: ${({ color }) => (color ? color : '#eeeeee')} 1px solid;
  margin: 8px 0px;
`;
const StyledButton = styled(Button)`
  margin-left: auto;
  margin-top: 14px;
  margin-bottom: 14px;
`;
const StyledCheckbox = styled(CheckBox)`
  div {
    min-width: 18px;
  }
`;
const Link = styled.div<{ isDisabled: boolean }>`
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.gray[50] : theme.colors.blue[50]};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'inherit')};
`;

const SellRateMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const ErrorText = styled.div`
  weight: 500;
  font-size: 16px;
  color: #ef3824;
`;
const NoHubtekValuesText = styled.div`
  weight: 500;
  font-size: 16px;
  color: #999999;
`;

export type CardProps = {
  equiptmentType?: TrailerType;
  hubtekData: HubtekApiResponse;
  isLoading: boolean;
  isError: boolean;
  sellRateRecalculating: boolean;
  setSellRateRecalculating: (value: boolean) => void;
  mainSearchQuery: NewRateQuery | undefined;
  setSellRateQuery: (query: HubtekRequestQuery) => void;
  accessorialsValue: AccessorialsType;
  setAccessorialsValue: (value: AccessorialsType) => void;
  stops: number;
  setStops: (value: number) => void;
  buyRate?: number;
};

export const SuggestedSellRateCard: React.FC<
  React.PropsWithChildren<CardProps>
> = ({
  equiptmentType,
  hubtekData,
  isLoading,
  isError,
  sellRateRecalculating,
  setSellRateRecalculating,
  mainSearchQuery,
  setSellRateQuery,
  accessorialsValue,
  setAccessorialsValue,
  stops,
  setStops,
  buyRate,
}) => {
  const [initialValues, setInitialValues] = useState<{
    tolerance: string;
    accessorials: AccessorialsType;
    stops: number;
  }>({
    tolerance: 'rateProModerate',
    accessorials: {
      driverAssist: false,
      hazmat: false,
      palletJack: false,
      ramps: false,
      sameDay: false,
      tankerEndorsed: false,
      tarps: false,
      team: false,
    },
    stops: 0,
  });

  const [toleranceValue, setToleranceValue] =
    useState<string>('rateProModerate');

  const formChanged = () => {
    return (
      initialValues.tolerance !== toleranceValue ||
      initialValues.accessorials.driverAssist !==
        accessorialsValue.driverAssist ||
      initialValues.accessorials.hazmat !== accessorialsValue.hazmat ||
      initialValues.accessorials.palletJack !== accessorialsValue.palletJack ||
      initialValues.accessorials.ramps !== accessorialsValue.ramps ||
      initialValues.accessorials.sameDay !== accessorialsValue.sameDay ||
      initialValues.accessorials.tankerEndorsed !==
        accessorialsValue.tankerEndorsed ||
      initialValues.accessorials.tarps !== accessorialsValue.tarps ||
      initialValues.accessorials.team !== accessorialsValue.team ||
      initialValues.stops !== stops
    );
  };

  const handleCalculate = () => {
    setInitialValues({
      tolerance: toleranceValue,
      accessorials: accessorialsValue,
      stops: stops,
    });

    let accessorialsArray = _(accessorialsValue).pickBy().keys().value();
    accessorialsArray = accessorialsArray.map((accessorial) => {
      return accessorial.replace(/(\B[A-Z])/g, ' $1').toUpperCase();
    });

    setSellRateQuery({
      tolerance: toleranceValue,
      accessorials: accessorialsArray,
      stops: stops,
    });
    setSellRateRecalculating(false);
  };

  // We want to show dashes if the form changes at all
  useEffect(() => {
    if (formChanged()) setSellRateRecalculating(true);
    else setSellRateRecalculating(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toleranceValue, accessorialsValue, stops]);

  const handleReset = () => {
    setToleranceValue('rateProModerate');
    setAccessorialsValue({
      driverAssist: false,
      hazmat: false,
      palletJack: false,
      ramps: false,
      sameDay: false,
      tankerEndorsed: false,
      tarps: false,
      team: false,
    });
    setStops(0);

    setInitialValues({
      tolerance: 'rateProModerate',
      accessorials: {
        driverAssist: false,
        hazmat: false,
        palletJack: false,
        ramps: false,
        sameDay: false,
        tankerEndorsed: false,
        tarps: false,
        team: false,
      },
      stops: 0,
    });

    setSellRateQuery({
      tolerance: 'rateProModerate',
      accessorials: [],
      stops: 0,
    });
  };

  // If the user searches from the main toolbar, we want to reset the form
  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainSearchQuery]);

  const handleOnAccessorialsChange = (name: string, value: boolean) => {
    setAccessorialsValue({ ...accessorialsValue, [name]: value });
  };

  const getContent = () => {
    if (isLoading) {
      return <SpinnerWithWait />;
    }

    if (isError) {
      return (
        <SellRateMessageContainer>
          <ErrorText>Error Retrieving Sell Rate</ErrorText>
        </SellRateMessageContainer>
      );
    }

    // Default state when the page loads and we haven't fetched rates yet
    if ((!hubtekData || !equiptmentType) && !sellRateRecalculating) {
      return <></>;
    }

    // If all values returned from hubtek are 0, then we can't calculate a sell rate
    if (
      hubtekData.fsc === 0 &&
      (hubtekData.rate_board_1 === 0 || hubtekData.rate_board_1 === null) &&
      hubtekData.total_rate === 0 &&
      hubtekData.extra_charges_and_restrictions === 0 &&
      hubtekData.stops === 0
    ) {
      return (
        <SellRateMessageContainer>
          <NoHubtekValuesText>No Sell Rate Available</NoHubtekValuesText>
        </SellRateMessageContainer>
      );
    }

    return (
      <>
        <CalculatorForm>
          <RateToleranceSection>
            <SmallTitleText>Rate Tolerance</SmallTitleText>
            <SmrRadioButton
              elements={[
                {
                  label: 'Conservative',
                  value: 'rateProConservative',
                  checked: toleranceValue === 'rateProConservative',
                },
                {
                  label: 'Moderate',
                  value: 'rateProModerate',
                  checked: toleranceValue === 'rateProModerate',
                },
                {
                  label: 'Aggressive',
                  value: 'rateProAggressive',
                  checked: toleranceValue === 'rateProAggressive',
                },
              ]}
              onChange={(value) => {
                console.log('onchange value');
                console.log(value);
                setToleranceValue(value?.toString() ?? 'rateProModerate');
              }}
              marginBottom="32px"
              id="RateToleranceRadioButtons"
            />
          </RateToleranceSection>
          <AccessorialsSection>
            <SmallTitleText>Accessorials</SmallTitleText>
            <CheckBoxContainer>
              <CheckBoxColumn>
                <StyledCheckbox
                  checked={accessorialsValue.driverAssist}
                  onClick={() => {
                    handleOnAccessorialsChange(
                      'driverAssist',
                      !accessorialsValue.driverAssist,
                    );
                  }}
                  text="Driver assist"
                  fontSize="14"
                />
                <StyledCheckbox
                  checked={accessorialsValue.hazmat}
                  onClick={() => {
                    handleOnAccessorialsChange(
                      'hazmat',
                      !accessorialsValue.hazmat,
                    );
                  }}
                  text="Hazmat"
                  fontSize="14"
                />
                <StyledCheckbox
                  checked={accessorialsValue.palletJack}
                  onClick={() => {
                    handleOnAccessorialsChange(
                      'palletJack',
                      !accessorialsValue.palletJack,
                    );
                  }}
                  text="Pallet jack"
                  fontSize="14"
                />
                {equiptmentType?.toUpperCase() === TrailerType.FLATBED && (
                  <StyledCheckbox
                    checked={accessorialsValue.ramps}
                    onClick={() => {
                      handleOnAccessorialsChange(
                        'ramps',
                        !accessorialsValue.ramps,
                      );
                    }}
                    text="Ramps"
                    fontSize="14"
                  />
                )}
              </CheckBoxColumn>
              <CheckBoxColumn>
                <StyledCheckbox
                  checked={accessorialsValue.sameDay}
                  onClick={() => {
                    handleOnAccessorialsChange(
                      'sameDay',
                      !accessorialsValue.sameDay,
                    );
                  }}
                  text="Same day"
                  fontSize="14"
                />
                <StyledCheckbox
                  checked={accessorialsValue.tankerEndorsed}
                  onClick={() => {
                    handleOnAccessorialsChange(
                      'tankerEndorsed',
                      !accessorialsValue.tankerEndorsed,
                    );
                  }}
                  text="Tanker endorsed"
                  fontSize="14"
                />
                {equiptmentType?.toUpperCase() === TrailerType.FLATBED && (
                  <StyledCheckbox
                    checked={accessorialsValue.tarps}
                    onClick={() => {
                      handleOnAccessorialsChange(
                        'tarps',
                        !accessorialsValue.tarps,
                      );
                    }}
                    text="Tarps"
                    fontSize="14"
                  />
                )}
                <StyledCheckbox
                  checked={accessorialsValue.team}
                  onClick={() => {
                    handleOnAccessorialsChange('team', !accessorialsValue.team);
                  }}
                  text="Team"
                  fontSize="14"
                />
              </CheckBoxColumn>
            </CheckBoxContainer>
            <PillCounterContainer>
              <PillCounter value={stops} setValue={setStops} />
            </PillCounterContainer>
          </AccessorialsSection>
        </CalculatorForm>

        <CalculationContainer>
          <StyledButton
            height="36px"
            buttonStyle="outlined"
            onClick={handleCalculate}
            disabled={!formChanged()}
          >
            Calculate
          </StyledButton>

          <StyledHR color="#BBBBBB" />
          <CalculationRow>
            <CalculationText>Linehaul</CalculationText>
            <CalculationAmount>
              {sellRateRecalculating ? (
                <>$–</>
              ) : (
                numberToCurrencyString(hubtekData.rate_board_1 - hubtekData.fsc)
              )}
            </CalculationAmount>
          </CalculationRow>
          <StyledHR />
          <CalculationRow>
            <CalculationText>Ryan Fuel</CalculationText>
            <CalculationAmount>
              {sellRateRecalculating ? (
                <>$–</>
              ) : (
                numberToCurrencyString(hubtekData.fsc)
              )}
            </CalculationAmount>
          </CalculationRow>
          <StyledHR />
          <CalculationRow>
            <CalculationText>Accessorials</CalculationText>
            <CalculationAmount>
              {sellRateRecalculating ? (
                <>$–</>
              ) : (
                numberToCurrencyString(
                  hubtekData.extra_charges_and_restrictions + hubtekData.stops,
                )
              )}
            </CalculationAmount>
          </CalculationRow>

          <StyledHR />
          <CalculationRow>
            <CalculationText>Margin Tolerance</CalculationText>
            <CalculationAmount>
              {/* If the sell rate total is less than the buyrate + 100: add the difference to margin tolerance */}
              {sellRateRecalculating ? (
                <>$–</>
              ) : (
                numberToCurrencyString(
                  buyRate && hubtekData.total_rate <= buyRate + 100
                    ? hubtekData.total_rate -
                        hubtekData.rate_board_1 -
                        hubtekData.extra_charges_and_restrictions -
                        hubtekData.stops +
                        (buyRate + 100 - hubtekData.total_rate)
                    : hubtekData.total_rate -
                        hubtekData.rate_board_1 -
                        hubtekData.extra_charges_and_restrictions -
                        hubtekData.stops,
                )
              )}
            </CalculationAmount>
          </CalculationRow>
        </CalculationContainer>
        <TotalRow>TOTAL</TotalRow>
        <TotalAmount>
          <LargeText margin="0px 0px 0px 0px">
            {/* If the sell rate total is less than the buyrate + 100: show total sell rate as buyrate + 100  */}
            {sellRateRecalculating ? (
              <>$–</>
            ) : (
              numberToCurrencyString(
                buyRate && hubtekData.total_rate <= buyRate + 100
                  ? buyRate + 100
                  : hubtekData.total_rate,
              )
            )}
          </LargeText>
          <MediumText margin="18px 0px 0px 5px" bold={true}>
            CUSTOMER
          </MediumText>
        </TotalAmount>
      </>
    );
  };

  return (
    <Card
      contentHeight="494px"
      secondaryTitle="SUGGESTED SELL RATE CALCULATOR"
      contentPadding="16px 24px"
      rightComponent={
        <Link isDisabled={!hubtekData} onClick={handleReset}>
          Reset
        </Link>
      }
    >
      {getContent()}
    </Card>
  );
};
