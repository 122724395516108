import * as React from 'react';
import { Flex } from 'components/Flex';
import { useQuoteDataByIdUserId } from '../hooks/useQuoteData';
import { Button, Dialog, MultilineTextInput } from 'shamrock-clover-ui';
import * as FirebaseUtils from '../../../utils/firebaseUtils';
import { ButtonsInputsProps } from '../utils/quoteTypes';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import SpinnerWithWait from 'components/LoadingSpinner';
import { getTimerDisplay } from '../utils/quoteUtils';
import {
  QuotingInputContainer,
  HelperText,
  LabelText,
  Input,
} from '../styledComponents/QuotingInputContainer';

const QuotingInput: React.FC<React.PropsWithChildren<ButtonsInputsProps>> = ({
  userId,
  orderId,
  history,
  carrierName,
  mcleodId,
}) => {
  const { isCountering } = useQuoteDataByIdUserId(orderId, userId);
  const { loadingCountered, counteredQuote, getStatusFromQuoteContext } =
    React.useContext(QuotesEnhancementsContext);
  const [value, setValue] = React.useState<string>('');
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [comment, setComment] = React.useState<string>('');
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const onContainerClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    inputRef: any,
  ) => {
    e.stopPropagation();

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    const valueRemovedCommas = value.replace(',', '');
    if (
      valueRemovedCommas.length <= 5 &&
      (valueRemovedCommas === '' || /^[0-9\b]+$/.test(valueRemovedCommas))
    ) {
      setValue(valueRemovedCommas);
    }
  };

  const logAcceptEvent = (amount: string) => {
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.COUNTER,
      FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
      FirebaseUtils.FirebasePages.DETAILS,
      {
        orderNumber: orderId,
        quoteAmount: amount,
        status: getStatusFromQuoteContext(orderId),
        timer: getTimerDisplay(history),
        description: 'Counter Quote',
        carrierName: carrierName ? carrierName : '',
        mcleodId: mcleodId ? mcleodId : '',
      },
    );
  };

  const valueNumber = parseInt(value);

  const onInputKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter' && valueNumber > 99) {
      submitCounter();
    }
    if (event.key === 'Escape') {
      setIsEdit(false);
    }
  };

  const submitCounter = async () => {
    logAcceptEvent(value);
    await counteredQuote({
      userId,
      orderId,
      amount: value,
      comment,
    });
    // Setting to false doesn't really matter since this component is unmounted when an admin has submitted a counter
    setIsEdit(false);
  };

  return (
    <>
      <Button
        width="200px"
        buttonStyle="outlined"
        onClick={() => setIsEdit(true)}
      >
        Counter
      </Button>
      {isEdit && (
        <Dialog
          open={isEdit}
          title="Counter Offer"
          content={
            loadingCountered
              ? ''
              : `The carrier will be able to view these comments once you submit.`
          }
          onClose={() => setIsEdit(false)}
          width="472px"
          actions={
            <>
              {loadingCountered ? (
                <SpinnerWithWait useWait={false} />
              ) : (
                <>
                  <LabelText>Counter amount</LabelText>
                  <QuotingInputContainer
                    amount={valueNumber}
                    showTextCursor={true}
                    onClick={(e) => onContainerClick(e, inputRef)}
                    alignItems="center"
                    justifyContent="center"
                    isEdit={isEdit}
                    isCountering={isCountering}
                    style={{ width: '161px' }}
                  >
                    <>
                      <div>$</div>
                      <Input
                        type="text"
                        ref={inputRef}
                        value={
                          value !== '' ? Number(value).toLocaleString() : value
                        }
                        onChange={onInputChange}
                        onKeyDown={(e) => onInputKeyDown(e)}
                      />
                    </>
                  </QuotingInputContainer>
                  <MultilineTextInput
                    label="Comment"
                    value={comment}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      if (value.length <= 110) {
                        setComment(value);
                      }
                    }}
                  />
                  <HelperText>110 Character Limit</HelperText>
                  <Flex
                    justifyContent="space-between"
                    margin="25px 0px 0px 0px"
                  >
                    <Button
                      width="175px"
                      buttonStyle="outlined"
                      onClick={() => setIsEdit(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={valueNumber < 99 || !valueNumber}
                      width="175px"
                      onClick={submitCounter}
                    >
                      Submit
                    </Button>
                  </Flex>
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default QuotingInput;
