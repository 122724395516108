interface Region {
  name: string;
  states: string[];
}

const regions: Region[] = [
  {
    name: "New England",
    states: ["CT", "RI", "MA", "VT", "NH", "ME"],
  },
  {
    name: "Upper Atlantic",
    states: ["PA", "NY", "NJ"],
  },
  {
    name: "Lower Atlantic",
    states: ["VA", "MD", "DE", "DC"],
  },
  {
    name: "Carolinas",
    states: ["NC", "SC"],
  },
  {
    name: "Florida",
    states: ["FL"],
  },
  {
    name: "Southeast",
    states: ["TN", "MS", "AL", "GA"],
  },
  {
    name: "Ohio River",
    states: ["KY", "WV"],
  },
  {
    name: "Great Lakes",
    states: ["WI", "IL", "IN", "MI", "OH"],
  },
  {
    name: "Upper Midwest",
    states: ["ND", "SD", "MN", "IA"],
  },
  {
    name: "Lower Midwest",
    states: ["NE", "KS", "MO"],
  },
  {
    name: "South Central",
    states: ["OK", "AR", "LA"],
  },
  {
    name: "Texas",
    states: ["TX"],
  },
  {
    name: "Lower Mountain",
    states: ["UT", "CO", "AZ", "NM"],
  },
  {
    name: "Upper Mountain",
    states: ["NV", "ID", "MT", "WY"],
  },
  {
    name: "California",
    states: ["CA"],
  },
  {
    name: "Pacific Northwest",
    states: ["WA", "OR"],
  },
];

const getRegionByState = (state: string) => {
  return regions.find((region) => region.states.includes(state.toUpperCase()))
    ?.name;
};

export { regions, getRegionByState };
