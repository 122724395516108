/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SecondaryNavigation, CloverThemeProvider } from 'shamrock-clover-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAccDialogContext } from 'hooks/useAccessorialFeesDialog';
interface SubHeaderProps {
  prefix?: string;
}

const SubHeader: React.FunctionComponent<
  React.PropsWithChildren<SubHeaderProps>
> = ({ prefix }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [startingIndex, setStartingIndex] = React.useState(0);
  const {
    formHasChange,
    setShowDialog,
    discardClicked,
    setFormHasChange,
    setDiscardClicked,
    urlDestination,
    setUrlDestination,
    resetDialogHook,
  } = useAccDialogContext();

  //event to watch for user changing url and navigating away
  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Set the message to show in the dialog
      // returnValue not supported in latest browsers.
      const message = 'Are you sure you want to leave?';
      event.returnValue = message;
      event.preventDefault();
      return message;
    };

    const handlePopState = (event: PopStateEvent) => {
      //during popState event just clear any changes.
      //browsers dont allow trapping back button for security reasons.
      resetDialogHook();
    };

    if (formHasChange) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [formHasChange]);

  // Sets the correct css for current url path.
  // fixing 'active state' bug on SecondaryNavigation.
  function resetCSS() {
    const parentDiv = document.querySelector('.secondaryNavigation');

    if (parentDiv) {
      const divChildren = parentDiv.querySelectorAll('div');
      for (let i = 1; i < divChildren.length; i++) {
        const element = divChildren[i];
        const temp = localStorage.getItem('subMenuText');
        //we keep track of active state in local storage.
        //set active state to bold.
        if (element.innerText !== temp) {
          element.style.fontWeight = '500';
        } else {
          element.style.fontWeight = '900';
        }
      }
    }
  }

  //when location path changes save prevStartingIndex
  React.useEffect(() => {
    const path = location.pathname.toLowerCase();
    switch (path) {
      case `/ratepro/rateadjustment`: {
        localStorage.setItem('prevStartingIndex', startingIndex.toString());
        localStorage.setItem('subMenuText', 'Rate Adjustment');
        setStartingIndex(1);
        break;
      }
      case `/ratepro/changelog`: {
        localStorage.setItem('prevStartingIndex', startingIndex.toString());
        localStorage.setItem('subMenuText', 'Chnage Log');
        setStartingIndex(2);
        break;
      }
      default: {
        localStorage.setItem('prevStartingIndex', startingIndex.toString());
        localStorage.setItem('subMenuText', 'Rate Pro');
        setStartingIndex(0);
      }
    }

    return () => {};
  }, [location.pathname]);

  React.useEffect(() => {
    if (discardClicked) {
      setShowDialog(false);
      setFormHasChange(false);
      if (urlDestination !== '') {
        navigate(urlDestination);
        setUrlDestination('');
      }
      setDiscardClicked(false);
    }
  }, [discardClicked]);

  const handleRateProClicked = () => {
    if (formHasChange) {
      setUrlDestination('/ratePro');
      setShowDialog(true);
      resetCSS();
    } else {
      navigate(`/ratePro`);
    }
  };

  const handleRateAdjustmentClicked = () => {
    if (formHasChange) {
      setUrlDestination('/ratePro/rateAdjustment');
      setShowDialog(true);
      resetCSS();
    } else {
      navigate(`/ratePro/rateAdjustment`);
    }
  };

  const handleChangeLogClicked = () => {
    if (formHasChange) {
      setUrlDestination('/ratePro/changeLog');
      setShowDialog(true);
      resetCSS();
    } else {
      navigate(`/ratePro/changeLog`);
    }
  };

  const tabs = [
    {
      title: 'Rate Pro',
      onClick: handleRateProClicked,
    },
    {
      title: 'Rate Adjustment',
      onClick: handleRateAdjustmentClicked,
    },
    {
      title: 'Change Log',
      onClick: handleChangeLogClicked,
    },
  ];

  return (
    <CloverThemeProvider>
      <div
        className="secondaryNavigation"
        style={{
          position: 'relative',
          boxShadow: '0 2px 2px 0 rgb(0 0 0 / 22%)',
          overflowX: 'hidden',
        }}
      >
        <SecondaryNavigation
          tabs={tabs}
          startingIndex={startingIndex}
          key={startingIndex}
        />
      </div>
    </CloverThemeProvider>
  );
};

export default SubHeader;
