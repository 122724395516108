import * as React from 'react';
import {
  RegionTitleText,
  StyledCard,
  CardContainer,
  SelectContainer,
  Step1HeaderContainer,
  Step1HeaderDefaultButton,
  SelectDefaultContainer,
  StyledFormControl,
  StyledInputLabel,
  Seperator,
  StyledMenuItem,
} from './rateAdjustmentStyles';
import { useRateAdjustmentQueryParams } from '../../hooks/useQueryParams';
import {
  aggressiveDefaultsParams,
  clearValuesDefaultsParams,
  defaultsParams,
  highDefaultsParams,
  moderateDefaultsParams,
} from './rateAdminTypes';
import { useGetRateAdjustmentData } from './useRateAdminData';
import { regions } from './rateProAdminRegions';
import { Button, Dialog, SelectInput, styled } from 'shamrock-clover-ui';
import { Flex } from 'components/Flex';
import { createRateAdminAdjustment } from './rateAdminHttp';
import SpinnerWithWait from 'components/Spinner';
import useSnackbar from 'hooks/useSnackbar';
import { Select } from '@material-ui/core';

const items = ['HIGH', 'NEUTRAL/MODERATE', 'AGGRESSIVE'].map((optionName) => ({
  optionName,
}));

const HelperText = styled.div`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 15px;
`;

interface Props {
  setDefaultValue: Function;
}

const RateAdjustmentStep1 = ({ setDefaultValue }: Props) => {
  const { showSnackbar } = useSnackbar();

  const { queryParams, setQueryParams } = useRateAdjustmentQueryParams();

  const { rateAdjustmenResult } = useGetRateAdjustmentData();
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [selectedDefault, setSelectedDefault] = React.useState<
    'HIGH' | 'NEUTRAL/MODERATE' | 'AGGRESSIVE' | ''
  >('');
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);

  const getOptions = () => {
    return regions
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((e) => <StyledMenuItem value={e.name}>{e.name}</StyledMenuItem>);
  };

  const clearResults = () => {
    setQueryParams(clearValuesDefaultsParams);
  };

  const saveClick = async () => {
    setShowSpinner(true);
    let allRegionReset = aggressiveDefaultsParams;
    if (selectedDefault === 'HIGH') {
      allRegionReset = highDefaultsParams;
    } else if (selectedDefault === 'NEUTRAL/MODERATE') {
      allRegionReset = moderateDefaultsParams;
    }
    await createRateAdminAdjustment({
      ...allRegionReset,
      region: 'All',
      reason: `Reset all regions ${selectedDefault.toUpperCase()}`,
    });
    setQueryParams({ region: undefined });
    setSelectedDefault('');
    setShowDialog(false);
    clearResults();
    setShowSpinner(false);
    showSnackbar({
      message: `All regions have been reset`,
      type: 'success',
    });
  };

  return (
    <StyledCard height="237px">
      <CardContainer
        onClick={(e) => e.stopPropagation()}
        flexDirection="column"
      >
        <Step1HeaderContainer>
          <RegionTitleText
            fontSize="24px"
            lineHeight="29px"
            width="386px"
            height="29px"
          >
            1. Select region to apply the changes
          </RegionTitleText>
          <Step1HeaderDefaultButton onClick={() => setShowDialog(true)}>
            DEFAULT ALL REGIONS
          </Step1HeaderDefaultButton>
        </Step1HeaderContainer>
        <SelectContainer>
          <StyledFormControl size="small">
            <StyledInputLabel id="region-select-label">Region</StyledInputLabel>
            <Select
              labelId="region-select-label"
              id="region-simple-select"
              MenuProps={{ variant: 'menu' }}
              onChange={(
                event: React.ChangeEvent<{
                  name?: string;
                  value: unknown;
                }>,
              ) => {
                const region = event.target.value as string;

                if (region && region.length > 0) {
                  const rateAdjustmenRegion = rateAdjustmenResult?.filter(
                    (e) =>
                      e?.region === region || e.region.toLowerCase() === 'all',
                  );
                  if (
                    !rateAdjustmenRegion ||
                    rateAdjustmenRegion?.length === 0
                  ) {
                    const params = {
                      ...defaultsParams,
                      region: event.target.value as string,
                    };
                    setDefaultValue(params);
                    setQueryParams(params);
                  } else {
                    const defaultRate = rateAdjustmenRegion[0];
                    const params = {
                      ...defaultRate,
                      region: event.target.value as string,
                    };
                    setDefaultValue(params);
                    setQueryParams(params);
                  }
                }
              }}
              displayEmpty={true}
              defaultValue={queryParams.region || ''}
              value={queryParams.region || ''}
              disableUnderline
            >
              {getOptions()}
            </Select>
          </StyledFormControl>
          <Seperator></Seperator>
        </SelectContainer>
      </CardContainer>
      {showDialog && (
        <Dialog
          open={showDialog}
          title="Reset All Regions to default?"
          content={
            'This action will reset all regions’ rating parameters to default. Would you like to proceed?'
          }
          onClose={() => setShowDialog(false)}
          width="605px"
          actions={
            <>
              {showSpinner && (
                <SpinnerWithWait
                  height="unset"
                  width="unset"
                  size="15"
                  useWait={false}
                />
              )}
              <SelectDefaultContainer onClick={(e) => e.stopPropagation()}>
                <HelperText>
                  Select a type of default to restore all regions.
                </HelperText>
                <SelectInput
                  label="Score to reset regions "
                  onOptionSelected={(option) => {
                    // issue with ddl in dialog hack
                    (
                      document?.querySelector(
                        '.inputContainer',
                      ) as HTMLDivElement
                    ).click();
                    setSelectedDefault(
                      option?.optionName as
                        | 'HIGH'
                        | 'NEUTRAL/MODERATE'
                        | 'AGGRESSIVE',
                    );
                  }}
                  options={items}
                  value={{ optionName: selectedDefault }}
                  width={457}
                />
              </SelectDefaultContainer>

              <Flex justifyContent="space-between" margin="25px 0px 0px 0px">
                <Button
                  width="101px"
                  height="40px"
                  buttonStyle="outlined"
                  onClick={() => setShowDialog(false)}
                >
                  Cancel
                </Button>
                <Button height="40px" width="101px" onClick={saveClick}>
                  RESET ALL
                </Button>
              </Flex>
            </>
          }
        />
      )}
    </StyledCard>
  );
};

export default RateAdjustmentStep1;
